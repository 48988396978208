import React from 'react'
import moment from 'moment-timezone'
import { RadBox } from '../common/RadBox'
import { RadCards } from '../common/RadCards'
import { RadHeader } from '../common/RadHeader'
import { RadLink } from '../common/RadLink'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { formatTimeRange, fullName } from '../common/utilities'
import { CourseSchedule } from '../shared/CourseSchedule'

export function CourseCards ({ courses }) {
  moment.tz.setDefault('America/Chicago')

  return (
    <RadSpaceBetween size='s'>
      <RadHeader
        variant='h2'
        counter={'(' + courses.length + ')'}
      >
        Courses
      </RadHeader>
      {courses.length === 0 && <RadBox color='text-body-secondary'>No courses added</RadBox>}
      {courses.length > 0 &&
        <RadCards
          ariaLabels={{
            itemSelectionLabel: (e, t) => `select ${t.name}`,
            selectionGroupLabel: 'Item selection'
          }}
          cardDefinition={{
            header: item =>
              <RadLink fontSize='heading-m' href={`/course/${item.id}?redirectURL=${window.location.pathname}`}>
                Course # {item.id}
              </RadLink>,
            sections: [
              {
                id: 'info',
                content: item =>
                  <RadSpaceBetween size='xxs'>
                    {item.cohort != null &&
                      <RadSpaceBetween direction='horizontal' size='s'>
                        <RadBox variant='awsui-key-label'>Cohort</RadBox>
                        <RadLink variant='primary' href={`/cohort/${item.cohort.id}`}>
                          {item.cohort.name}
                        </RadLink>
                      </RadSpaceBetween>}
                    {item.program != null &&
                      <RadSpaceBetween direction='horizontal' size='s'>
                        <RadBox variant='awsui-key-label'>Program</RadBox>
                        <RadLink variant='primary' href={`/program/${item.program.id}`}>
                          {item.program?.name}
                        </RadLink>
                      </RadSpaceBetween>}
                    <RadSpaceBetween direction='horizontal' size='s'>
                      <RadBox variant='awsui-key-label'>Instructor(s)</RadBox>
                      {item.instructors.map(x =>
                        <RadLink key={'instructor-' + x.id} variant='primary' href={`/instructor/${x.instructor.id}`}>
                          {fullName(x.instructor)}
                        </RadLink>
                      )}
                    </RadSpaceBetween>
                    <RadSpaceBetween direction='horizontal' size='s'>
                      <RadBox variant='awsui-key-label'>Location</RadBox>
                      <div>{item.campus.name} {item.room != null ? ` - Room ${item.room}` : ''}</div>
                    </RadSpaceBetween>
                    <RadSpaceBetween direction='horizontal' size='s'>
                      <RadBox variant='awsui-key-label'>Enrollments</RadBox>
                      <div>{item.enrollments.length} / {item.capacity}</div>
                    </RadSpaceBetween>
                    <div>
                      <RadBox variant='awsui-key-label' padding={{ bottom: 'xxs' }}>Schedule</RadBox>
                      <CourseSchedule course={item} />
                    </div>
                  </RadSpaceBetween>
              }
            ]
          }}
          cardsPerRow={[
            { cards: 1 },
            { minWidth: 640, cards: 2 },
            { minWidth: 960, cards: 3 },
            { minWidth: 1280, cards: 4 }
          ]}
          items={courses}
          variant='full-page'
        />}
    </RadSpaceBetween>
  )
}
