import React, { useContext, useRef, useState, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { AppContext } from '../App'
import { useGet } from '../hooks/useGet'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { RadAlert } from '../common/RadAlert'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadButton } from '../common/RadButton'
import { RadContainer } from '../common/RadContainer'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { toBase64 } from '../common/utilities'

export function InstructorEdit () {
  const inputRef = useRef(null)
  const [preview, setPreview] = useState()
  const [fileSizeTooLarge, setFileSizeTooLarge] = useState(false)
  const { setError } = useContext(AppContext)
  const navigate = useNavigate()
  const { instructorId } = useParams()
  const [searchParams] = useSearchParams()
  const [formValues, setFormValues] = useState()
  const { data: instructor } = useGet(instructorId ? `/api/instructor/${instructorId}` : null)
  const create = usePost('/api/instructor', formValues, (resp) => { navigate(searchParams.get('redirectURL') ?? `/instructor/${resp.id}`) })
  const update = usePut(`/api/instructor/${instructorId}`, formValues, (resp) => { navigate(searchParams.get('redirectURL') ?? `/instructor/${instructorId}`) })

  useEffect(() => {
    setError(null)
    const defaultFormValues = { }
    setFormValues(instructor ?? defaultFormValues)
  }, [instructor])

  async function cancel () {
    if (instructorId != null) {
      navigate(searchParams.get('redirectURL') ?? `/instructor/${instructorId}`)
    } else {
      navigate(searchParams.get('redirectURL') ?? '/instructor')
    }
  }

  async function onFileChange (event) {
    setFileSizeTooLarge(false)
    const file = event.target.files[0]
    if (file != null && file.size > 10000000) {
      setFileSizeTooLarge(true)
    } else {
      setPreview(URL.createObjectURL(file))
      if (file != null) {
        const data = await toBase64(file)
        setFormValues({ ...formValues, file: file.name, fileSize: file.size, fileData: data })
      } else {
        removeFile()
      }
    }
    event.target.value = ''
  }

  function removeFile () {
    setFormValues({ ...formValues, file: null, fileSize: null, fileData: null, photoUrl: null })
  }

  async function saveChanges () {
    if (instructorId != null) {
      update()
    } else {
      create()
    }
  }

  if (
    formValues != null
  ) {
    return (
      <RadAppLayout
        name={formValues.fullName}
        contentHeader={
          <RadHeader variant='h1'>{instructorId ? 'Edit' : 'New'} Instructor</RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Details
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <input type='file' ref={inputRef} style={{ display: 'none' }} onChange={onFileChange} />
                    {formValues.photoUrl == null && preview == null &&
                      <div className='profile' onClick={() => inputRef.current.click()}><br /><br /><br />click to add image</div>}
                    {formValues.photoUrl != null && preview == null &&
                      <img src={formValues.photoUrl} alt='person profile' className='profile' onClick={() => inputRef.current.click()} />}
                    {preview != null && <img src={preview} alt='preview' className='profile' onClick={() => inputRef.current.click()} />}
                    {(formValues.photoUrl != null || preview != null) &&
                      <RadButton onClick={() => removeFile()}>Remove image</RadButton>}
                    {fileSizeTooLarge &&
                      <RadAlert
                        dismissible
                        statusIconAriaLabel='Error'
                        type='error'
                        header='File size too large'
                        onDismiss={() => setFileSizeTooLarge(false)}
                      >
                        Please select a file that is less than 10MB.
                      </RadAlert>}

                    <RadGrid
                      gridDefinition={[
                        { colspan: { default: 12, s: 3 } },
                        { colspan: { default: 12, s: 3 } },
                        { colspan: { default: 12, s: 3 } },
                        { colspan: { default: 12, s: 3 } }
                      ]}
                    >
                      <RadFormField label='First Name' field='firstName' required>
                        <RadInput
                          ariaRequired
                          placeholder='Enter first name'
                          value={formValues.firstName}
                          onChange={({ detail }) => setFormValues({ ...formValues, firstName: detail.value })}
                        />
                      </RadFormField>
                      <RadFormField label='Middle Name' field='middleName'>
                        <RadInput
                          ariaRequired
                          placeholder='Enter middle name'
                          value={formValues.middleName}
                          onChange={({ detail }) => setFormValues({ ...formValues, middleName: detail.value })}
                        />
                      </RadFormField>
                      <RadFormField label='Last Name' field='lastName' required>
                        <RadInput
                          ariaRequired
                          placeholder='Enter last name'
                          value={formValues.lastName}
                          onChange={({ detail }) => setFormValues({ ...formValues, lastName: detail.value })}
                        />
                      </RadFormField>
                      <RadFormField label='Preferred Name' field='preferredName'>
                        <RadInput
                          placeholder='Enter preferred name'
                          value={formValues.preferredName}
                          onChange={({ detail }) => setFormValues({ ...formValues, preferredName: detail.value })}
                        />
                      </RadFormField>
                    </RadGrid>
                    <RadGrid
                      gridDefinition={[
                        { colspan: { default: 12, s: 3 } },
                        { colspan: { default: 12, s: 3 } }
                      ]}
                    >
                      <RadFormField label='Email' field='email'>
                        <RadInput
                          type='email'
                          inputMode='email'
                          placeholder='Enter email'
                          value={formValues.email}
                          onChange={({ detail }) => setFormValues({ ...formValues, email: detail.value })}
                        />
                      </RadFormField>
                      <RadFormField label='Phone' field='phone'>
                        <RadInput
                          type='tel'
                          inputMode='tel'
                          placeholder='Enter phone'
                          value={formValues.phone}
                          onChange={({ detail }) => setFormValues({ ...formValues, phone: detail.value })}
                        />
                      </RadFormField>
                    </RadGrid>
                  </RadSpaceBetween>
                </RadContainer>
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}
