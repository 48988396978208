import React from 'react'
import { RadBox } from '../common/RadBox'
import { RadPieChart } from '../common/RadPieChart'

export function RacePieChart ({ races }) {
  const total = races.reduce((acc, x) => acc + x.count, 0)

  return (
    <RadPieChart
      ariaDescription='Donut chart showing races'
      ariaLabel='Donut chart'
      data={races.map(x => ({
        title: x.race,
        value: x.count,
        percentage: (x.count / total * 100).toFixed(0) + '%'
      }))}
      empty={
        <RadBox textAlign='center' color='inherit'>
          <b>Races</b>
          <RadBox variant='p' color='inherit'>
            There is no data available
          </RadBox>
        </RadBox>
    }
      innerMetricDescription='Races'
      innerMetricValue={races.length}
      size='medium'
      variant='donut'
      hideFilter
      hideLegend
      hideTitles
      hideDescriptions
    />
  )
}
