import React from 'react'
import { useGet } from '../hooks/useGet'
import { RadCheckbox } from '../common/RadCheckbox'
import { RadFormField } from '../common/RadFormField'
import { RadGrid } from '../common/RadGrid'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function PersonDemographicsFormFields ({ formValues, setFormValues, forCourseEnrollment }) {
  const { data: ethnicityOptions } = useGet('/api/option/ethnicity')
  const { data: genderOptions } = useGet('/api/option/gender')
  const { data: raceOptions } = useGet('/api/option/race')
  const yesNoNAOptions = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
    { value: null, label: 'N/A' }
  ]

  if (
    ethnicityOptions != null &&
    genderOptions != null &&
    raceOptions != null
  ) {
    return (
      <RadSpaceBetween size='l'>
        <RadGrid
          gridDefinition={[
            { colspan: { default: 12, s: 3 } },
            { colspan: { default: 12, s: 3 } },
            { colspan: { default: 12, s: 3 } }
          ]}
        >
          <RadFormField label='Gender' field='genderId' required={forCourseEnrollment}>
            <RadSelect
              selectedOption={formValues.genderId ? genderOptions.find(x => x.value === formValues.genderId?.toString()) : { value: '', label: '-' }}
              onChange={({ detail }) => {
                if (detail.selectedOption.value !== '') {
                  setFormValues({ ...formValues, genderId: parseInt(detail.selectedOption.value) })
                } else {
                  setFormValues({ ...formValues, genderId: null })
                }
              }}
              options={genderOptions}
              selectedAriaLabel='Selected'
              empty='No matches found'
            />
          </RadFormField>
          <RadFormField label='Race' field='raceId' required={forCourseEnrollment}>
            <RadSelect
              selectedOption={formValues.raceId ? raceOptions.find(x => x.value === formValues.raceId?.toString()) : { value: '', label: '-' }}
              onChange={({ detail }) => {
                if (detail.selectedOption.value !== '') {
                  setFormValues({ ...formValues, raceId: parseInt(detail.selectedOption.value) })
                } else {
                  setFormValues({ ...formValues, raceId: null })
                }
              }}
              options={raceOptions}
              selectedAriaLabel='Selected'
              empty='No matches found'
            />
          </RadFormField>
          <RadFormField label='Ethnicity' field='ethnicityId'>
            <RadSelect
              selectedOption={formValues.ethnicityId ? ethnicityOptions.find(x => x.value === formValues.ethnicityId?.toString()) : { value: '', label: '-' }}
              onChange={({ detail }) => {
                if (detail.selectedOption.value !== '') {
                  setFormValues({ ...formValues, ethnicityId: parseInt(detail.selectedOption.value) })
                } else {
                  setFormValues({ ...formValues, ethnicityId: null })
                }
              }}
              options={ethnicityOptions}
              selectedAriaLabel='Selected'
              empty='No matches found'
            />
          </RadFormField>
          <RadFormField label='First Generation College Student' field='firstGenerationCollegeStudent'>
            <RadSelect
              selectedOption={yesNoNAOptions.find(x => x.value === formValues.firstGenerationCollegeStudent) ?? { value: '', label: '-' }}
              onChange={({ detail }) => {
                if (detail.selectedOption.value !== '') {
                  setFormValues({ ...formValues, firstGenerationCollegeStudent: detail.selectedOption.value })
                } else {
                  setFormValues({ ...formValues, firstGenerationCollegeStudent: null })
                }
              }}
              options={yesNoNAOptions}
              selectedAriaLabel='Selected'
              empty='No matches found'
            />
          </RadFormField>
        </RadGrid>
        <RadFormField label='US Citizen or Permanent Resident' field='usCitizenOrPermanentResident'>
          <RadCheckbox
            checked={formValues.usCitizenOrPermanentResident}
            onChange={({ detail }) => {
              setFormValues({ ...formValues, usCitizenOrPermanentResident: detail.checked })
            }}
          />
        </RadFormField>
      </RadSpaceBetween>
    )
  }
}
