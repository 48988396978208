import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadContainer } from '../common/RadContainer'
import { RadHeader } from '../common/RadHeader'
import { RadLink } from '../common/RadLink'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { CourseEnrollmentCards } from '../cards/CourseEnrollmentCards'
import { PhotoUrlHeader } from '../shared/PhotoUrlHeader'
import { formatDate, formatTime } from '../common/utilities'

export function PersonDetail () {
  const navigate = useNavigate()
  const { personId } = useParams()
  const { data: person } = useGet(`/api/person/${personId}`, true)
  const confirmDelete = useConfirm('Delete person?', 'Delete person permanently? This action cannot be undone.', 'Delete', () => { remove() })
  const remove = useDelete(`/api/person/${personId}`, () => { navigate('/person') })

  if (person != null) {
    return (
      <RadAppLayout
        name={`${person.firstName} ${person.preferredName ? '"' + person.preferredName + '" ' : ''} ${person.lastName}`}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('edit')}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={person.isProtected}>Delete</RadButton>
              </RadSpaceBetween>
            }
          >
            {`${person.firstName} ${person.preferredName ? '"' + person.preferredName + '" ' : ''} ${person.lastName}`}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <PhotoUrlHeader photoUrl={person.photoUrl} />
            <Details person={person} />
            <ContactInformation person={person} />
            <Demographics person={person} />
            <HealthInformation person={person} />
            <Contacts person={person} />
            <SchoolEnrollments person={person} />
            <CriticalInteractions person={person} />
            <Reports person={person} />
            <CourseEnrollmentCards courseEnrollments={person.courseEnrollments} />
          </RadSpaceBetween>
        }
      />
    )
  }
}

function Details ({ person }) {
  return (
    <RadContainer
      header={
        <RadHeader variant='h2'>
          Details
        </RadHeader>
    }
    >
      <RadColumnLayout columns={3} borders='vertical'>
        <div>
          <RadBox variant='awsui-key-label'>
            Full Name
          </RadBox>
          <div>{`${person.firstName} ${person.middleName ? person.middleName + ' ' : ''} ${person.lastName}`}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Birth Date
          </RadBox>
          <div>{formatDate(person.birthdate) ?? '-'}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Pronouns
          </RadBox>
          <div>{person.pronouns?.name ?? '-'}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Primary Language
          </RadBox>
          <div>{person.primaryLanguage?.name ?? '-'}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Media Release
          </RadBox>
          <div>{person.mediaRelease ? 'Yes' : 'No'} {person.mediaReleaseExpirationDate != null && ` - Expires ${formatDate(person.mediaReleaseExpirationDate)}`}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Tags
          </RadBox>
          <RadSpaceBetween size='s'>
            {person.tags.length === 0 && '-'}
            {person.tags.map(x => <RadBox key={x.id}>{x.tag.name}</RadBox>)}
          </RadSpaceBetween>
        </div>
      </RadColumnLayout>
    </RadContainer>
  )
}

function ContactInformation ({ person }) {
  return (
    <RadContainer
      header={
        <RadHeader variant='h2'>
          Contact Information
        </RadHeader>
    }
    >
      <RadColumnLayout columns={3} borders='vertical'>
        <div>
          <RadBox variant='awsui-key-label'>
            Address
          </RadBox>
          <div>{person.address?.line1}</div>
          {person.address?.line2 && <div>{person.address?.line2}</div>}
          <div>{person.address?.city} {person.address?.state} {person.address?.zip}</div>
          {!person.addressId && '-'}
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Email
          </RadBox>
          <div>{person.email ? <RadLink href={`mailto:${person.email}`}>{person.email}</RadLink> : '-'}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Phone
          </RadBox>
          <div>{person.phone ?? '-'}</div>
        </div>
      </RadColumnLayout>
    </RadContainer>
  )
}

function Demographics ({ person }) {
  return (
    <RadContainer
      header={
        <RadHeader variant='h2'>
          Demographics
        </RadHeader>
    }
    >
      <RadColumnLayout columns={3} borders='vertical'>
        <div>
          <RadBox variant='awsui-key-label'>
            Gender
          </RadBox>
          <div>{person.gender?.name ?? '-'}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Race
          </RadBox>
          <div>{person.race?.name ?? '-'}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Ethnicity
          </RadBox>
          <div>{person.ethnicity?.name ?? '-'}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            First Generation College Student
          </RadBox>
          <div>
            {person.firstGenerationCollegeStudent === null ? 'N/A' : (person.firstGenerationCollegeStudent ? 'Yes' : 'No')}
          </div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            US Citizen or Permanent Resident
          </RadBox>
          <div>
            {person.usCitizenOrPermanentResident ? 'Yes' : 'No'}
          </div>
        </div>
      </RadColumnLayout>
    </RadContainer>
  )
}

function HealthInformation ({ person }) {
  return (
    <RadContainer
      header={
        <RadHeader variant='h2'>
          Health Information
        </RadHeader>
    }
    >
      <RadColumnLayout columns={3} borders='vertical'>
        <div>
          <RadBox variant='awsui-key-label'>
            Allergies
          </RadBox>
          <div>
            {person.allergies.length === 0 && '-'}
            {person.allergies.map(x => x.allergy.name).join(', ')}
          </div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Dietary Needs
          </RadBox>
          <div>
            {person.dietaryNeeds.length === 0 && '-'}
            {person.dietaryNeeds.map(x => x.dietaryNeed.name).join(', ')}
          </div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            ADA Documented Disabilities
          </RadBox>
          <div>
            {person.disabilities.length === 0 && '-'}
            {person.disabilities.map(x => x.disability.name).join(', ')}
          </div>
        </div>
      </RadColumnLayout>
    </RadContainer>
  )
}

function Contacts ({ person }) {
  return (
    <RadContainer
      header={
        <RadHeader variant='h2' counter={'(' + person.contacts.length + ')'}>
          Contacts
        </RadHeader>
    }
    >
      <RadColumnLayout columns={person.contacts.length} borders='vertical'>
        {person.contacts.map(x => (
          <div key={x.id}>
            <RadBox variant='awsui-key-label'>
              {x.fullName}
            </RadBox>
            <div>{x.relationship.name}</div>
            {x.home && <div>Home: {x.home}</div>}
            {x.work && <div>Work: {x.work}</div>}
            {x.mobile && <div>Mobile: {x.mobile}</div>}
            {x.email && <div><RadLink href={`mailto:${x.email}`}>{x.email}</RadLink></div>}
            {x.emergencyContact && <div>Emergency Contact</div>}
            {x.smsPermission && <div>Permission to Text</div>}
          </div>
        ))}
      </RadColumnLayout>
    </RadContainer>
  )
}

function CriticalInteractions ({ person }) {
  return (
    <RadContainer
      header={
        <RadHeader variant='h2' counter={'(' + person.criticalInteractions.length + ')'}>
          Critical Interactions
        </RadHeader>
    }
    >
      {person.criticalInteractions.length === 0 &&
        <RadBox padding={{ bottom: 'l' }}>No critical interactions have been added to this person</RadBox>}
      <RadSpaceBetween size='xs'>
        {person.criticalInteractions.map(x => (
          <RadSpaceBetween key={x.id} direction='horizontal' size='s'>
            <RadBox variant='awsui-key-label'>
              {formatDate(x.date)} {x.time != null && formatTime(x.time)}
            </RadBox>
            <div>{x.description}</div>
          </RadSpaceBetween>
        ))}
      </RadSpaceBetween>
    </RadContainer>
  )
}

function Reports ({ person }) {
  return (
    <RadContainer
      header={
        <RadHeader
          variant='h2'
          counter={'(' + person.reports.length + ')'}
        >
          Reports
        </RadHeader>
      }
    >
      <RadSpaceBetween size='s'>
        {person.reports.length === 0 &&
          <RadBox color='text-body-secondary'>
            No reports have been added to this person
          </RadBox>}
        {person.reports.map(x =>
          <RadLink key={'report-' + x.id} href={`/student-report/${x.report.id}`}>{formatDate(x.report.date)}</RadLink>
        )}
      </RadSpaceBetween>
    </RadContainer>

  )
}

function SchoolEnrollments ({ person }) {
  return (
    <RadContainer
      header={
        <RadHeader variant='h2' counter={'(' + person.schoolEnrollments.length + ')'}>
          School Enrollments
        </RadHeader>
    }
    >
      {person.schoolEnrollments.length === 0 &&
        <RadBox padding={{ bottom: 'l' }}>No school enrollments have been added to this person</RadBox>}
      <RadColumnLayout columns={person.schoolEnrollments.length} borders='vertical'>
        {person.schoolEnrollments.map(x => (
          <div key={x.id}>
            <RadBox variant='awsui-key-label'>
              {x.school.name}
            </RadBox>
            <div>{formatDate(x.startDate)} - {x.endDate != null ? formatDate(x.endDate) : 'Present'}</div>
            <div>{x.grade.name} Grade</div>
            {x.schoolPersonId && <div>Person ID: {x.schoolPersonId}</div>}
            {x.freeReducedLunch === true && <div>Free / Reduced Lunch</div>}
          </div>
        ))}
      </RadColumnLayout>
    </RadContainer>
  )
}
