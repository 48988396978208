import React, { useState, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Avatar } from '@rmwc/avatar'
import { useGet } from '../hooks/useGet'
import { usePost } from '../hooks/usePost'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadCards } from '../common/RadCards'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadContainer } from '../common/RadContainer'
import { RadForm } from '../common/RadForm'
import { RadHeader } from '../common/RadHeader'
import { RadLink } from '../common/RadLink'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { formatDate, formatTimeRange, fullName } from '../common/utilities'
import './AttendanceEdit.scss'

export function AttendanceEdit () {
  const navigate = useNavigate()
  const { sessionId } = useParams()
  const [searchParams] = useSearchParams()
  const [formValues, setFormValues] = useState()
  const { data: session } = useGet(`/api/session/${sessionId}`, true)
  const update = usePost('/api/attendance', formValues, (resp) => { navigate(searchParams.get('redirectURL') ?? `/session/${sessionId}`) })

  useEffect(() => {
    if (session != null) {
      const attendance = session.attendance.map(x => ({ courseEnrollmentId: x.courseEnrollmentId, absent: x.absent }))
      for (const enrollment of session.course.enrollments) {
        if (!attendance.some(x => x.courseEnrollmentId === enrollment.id)) {
          attendance.push({ courseEnrollmentId: enrollment.id, absent: null })
        }
      }
      setFormValues({ sessionId: parseInt(sessionId), attendance })
    }
  }, [session])

  async function cancel () {
    if (sessionId != null) {
      navigate(searchParams.get('redirectURL') ?? '/session')
    } else {
      navigate(searchParams.get('redirectURL') ?? `/session/${sessionId}`)
    }
  }

  function toggleAttendance (courseEnrollmentId) {
    const attendances = formValues.attendance
    const attendance = attendances.find(y => y.courseEnrollmentId === courseEnrollmentId)
    if (attendance.absent == null) {
      attendance.absent = true
    } else if (attendance.absent === false) {
      attendance.absent = null
    } else {
      attendance.absent = false
    }
    setFormValues({ ...formValues, attendance: attendances })
  }

  async function saveChanges () {
    update()
  }

  if (
    formValues != null
  ) {
    return (
      <RadAppLayout
        name={'Session # ' + session?.id + ' Attendance'}
        contentHeader={
          <RadHeader variant='h1'>Update Attendance</RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Details
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadColumnLayout columns={4} borders='vertical'>
                      <div>
                        <RadBox variant='awsui-key-label'>
                          Program
                        </RadBox>
                        <div>{session.course.program.name}</div>
                      </div>
                      <div>
                        <RadBox variant='awsui-key-label'>
                          Course
                        </RadBox>
                        <RadLink href={`/course/${session.courseId}`}>Course # {session.course.id}</RadLink>
                      </div>
                      <div>
                        <RadBox variant='awsui-key-label'>
                          Date
                        </RadBox>
                        <div>{formatDate(session.date)} </div>
                      </div>
                      <div>
                        <RadBox variant='awsui-key-label'>
                          Time
                        </RadBox>
                        <div>{formatTimeRange(session.startTime, session.endTime)}</div>
                      </div>
                    </RadColumnLayout>
                  </RadSpaceBetween>
                </RadContainer>

                <RadSpaceBetween size='s'>
                  <RadHeader
                    variant='h2'
                    counter={'(' + session.course.enrollments.length + ')'}
                  >
                    Enrollments
                  </RadHeader>
                  {session.course.enrollments.length === 0 && <RadBox color='text-body-secondary'>No enrollments added</RadBox>}
                  {session.course.enrollments.length > 0 &&
                    <RadCards
                      ariaLabels={{
                        itemSelectionLabel: (e, t) => `select ${t.name}`,
                        selectionGroupLabel: 'Item selection'
                      }}
                      cardDefinition={{
                        header: item =>
                          <RadLink fontSize='heading-m' href={`/person/${item.id}`}>
                            <RadSpaceBetween size='xs'>
                              <Avatar
                                src={item.person.photoUrl}
                                size='large'
                                name={`${item.person.firstName} ${item.person.lastName}`}
                                referrerPolicy='no-referrer'
                              />
                              <div>{fullName(item.person)}</div>
                            </RadSpaceBetween>
                          </RadLink>,
                        sections: [
                          {
                            id: 'info',
                            content: item => {
                              const absent = formValues.attendance.find(y => y.courseEnrollmentId === item.id)?.absent
                              return (
                                <div className='attendance-toggle'>
                                  <button
                                    onClick={() => toggleAttendance(item.id)}
                                  >
                                    {absent === true &&
                                      <span className='material-symbols-outlined true'>check</span>}
                                    {absent === false &&
                                      <span className='material-symbols-outlined false'>close</span>}
                                    {absent == null &&
                                      <span className='material-symbols-outlined null'>question_mark</span>}
                                  </button>
                                </div>
                              )
                            }
                          }
                        ]
                      }}
                      cardsPerRow={[
                        { cards: 1 },
                        { minWidth: 640, cards: 2 },
                        { minWidth: 960, cards: 3 },
                        { minWidth: 1280, cards: 4 }
                      ]}
                      items={session.course.enrollments}
                      variant='full-page'
                      empty=''
                    />}
                  {/* <RadButton
                    onClick={() => {
                      navigate(`/session-enrollment/create?sessionId=${sessionId}&redirectURL=${window.location.pathname}`)
                    }}
                  >
                    Add new enrollment
                  </RadButton> */}
                </RadSpaceBetween>

                {/* <RadContainer
                  header={
                    <RadHeader
                      variant='h2'
                    >
                      Attendance
                    </RadHeader>
              }
                >
                  <RadSpaceBetween size='l'>
                    {session.course.enrollments.length === 0 && <RadBox color='text-body-secondary'>No enrollments added</RadBox>}
                    <div>
                      <RadSpaceBetween size='xxs'>
                        {session.course.enrollments.map(x => (
                          <RadSpaceBetween key={'session-' + x.id} direction='horizontal' size='l'>
                            <RadLink href={`/person/${x.person.id}`}>{x.person.fullName}</RadLink>
                            <RadCheckbox
                              checked={formValues.attendance.find(y => y.courseEnrollmentId === x.id).absent === true}
                              onChange={({ detail }) => {
                                const attendance = formValues.attendance
                                attendance.find(y => y.courseEnrollmentId === x.id).absent = detail.checked
                                setFormValues({ ...formValues, attendance })
                              }}
                            >
                              Absentia
                            </RadCheckbox>
                          </RadSpaceBetween>
                        ))}
                      </RadSpaceBetween>
                    </div>
                  </RadSpaceBetween>
                </RadContainer> */}
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}
