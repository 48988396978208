import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { auth } from '../firebase'
import { AppContext } from './../App'
import { RadBox } from '../common/RadBox'
import { RadBadge } from '../common/RadBadge'
import TopNavigation from '@cloudscape-design/components/top-navigation'

// https://cloudscape.design/components/top-navigation
export function RadTopNavigation () {
  const navigate = useNavigate()
  const { setPermissionDenied } = useContext(AppContext)
  const root = window.location.hostname.split('.')[0]
  let search
  switch (root) {
    case 'qa':
    case 'uat':
      search = <RadBox textAlign='center'><RadBadge color='green'>{root.toUpperCase()}</RadBadge></RadBox>
      break
    default:
  }

  const userProfile = [
    {
      type: 'menu-dropdown',
      text: auth.currentUser?.displayName,
      description: auth.currentUser?.email,
      iconName: 'user-profile',
      onItemClick: event => {
        switch (event.detail.id) {
          case 'signout':
            auth.signOut()
            setPermissionDenied(null)
            navigate('/')
            break
          default:
            console.log(`${event.detail.id} not implemented`)
        }
      },
      items: [
        { id: 'signout', text: 'Sign out' }
      ]
    }
  ]

  const utilities = [
    // {
    //   type: 'button',
    //   iconName: 'notification',
    //   ariaLabel: 'Notifications',
    //   badge: true,
    //   disableUtilityCollapse: false
    // }
  ].concat(auth.currentUser != null ? userProfile : [])

  return (
    <TopNavigation
      identity={{
        href: '/',
        title: 'UCG Case Management'
      }}
      utilities={utilities}
      i18nStrings={{
        overflowMenuTriggerText: 'More',
        overflowMenuTitleText: 'All',
        overflowMenuBackIconAriaLabel: 'Back',
        overflowMenuDismissIconAriaLabel: 'Close menu'
      }}
      search={search}
    />
  )
}
