import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useIsMobile } from '../hooks/useIsMobile'
import { v4 as uuidv4 } from 'uuid'
import { useGet } from '../hooks/useGet'
import { usePut } from '../hooks/usePut'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadContainer } from '../common/RadContainer'
import { RadDivider } from '../common/RadDivider'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function StudentRatingEdit () {
  const navigate = useNavigate()
  const [formValues, setFormValues] = useState()
  const { data: studentRatings, count } = useGet('/api/student-rating')
  const { data: studentRatingCategories } = useGet('/api/option/student_rating_category')
  const update = usePut('/api/student-rating', formValues, (resp) => { navigate('/student-rating') })

  useEffect(() => {
    if (studentRatings != null) {
      setFormValues({ studentRatings })
    }
  }, [studentRatings])

  async function cancel () {
    navigate('/school-rating')
  }

  async function saveChanges () {
    update()
  }

  if (
    formValues != null &&
    studentRatingCategories != null
  ) {
    return (
      <RadAppLayout
        contentHeader={
          <RadHeader
            variant='h1'
            counter={'(' + count + ')'}
          >
            Student Ratings
          </RadHeader>
        }
        content={
          <RadForm
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
              </RadSpaceBetween>
          }
          >
            <RadSpaceBetween size='l'>
              {studentRatingCategories
                .filter(x => x.label !== 'Attendance')
                .map((x) =>
                  <Category
                    key={'category-' + x.value}
                    category={x}
                    formValues={formValues}
                    setFormValues={setFormValues}
                  />
                )}
            </RadSpaceBetween>
          </RadForm>
        }
      />
    )
  }
}

function Category ({ category, formValues, setFormValues }) {
  const filteredStudentRatings = formValues.studentRatings
    .filter((x) => x.studentRatingCategoryId.toString() === category.value)
    .sort((a, b) => a.sortOrder - b.sortOrder)

  return (
    <RadContainer
      header={
        <RadHeader
          variant='h2'
          counter={'(' + filteredStudentRatings.length + ')'}
        >
          {category.label}
        </RadHeader>
          }
    >
      <RadSpaceBetween size='l'>
        {filteredStudentRatings
          .map((x) =>
            <StudentRatingEditor
              key={`school-${x.id},${x.uuid}`}
              item={x}
              formValues={formValues}
              setFormValues={setFormValues}
              category={category}
            />)}
        <RadButton
          onClick={() => {
            const studentRatings = formValues.studentRatings
            studentRatings.push({ uuid: uuidv4(), studentRatingCategoryId: parseInt(category.value) })
            setFormValues({ ...formValues, studentRatings })
          }}
        >
          Add new rating
        </RadButton>
      </RadSpaceBetween>
    </RadContainer>
  )
}

function StudentRatingEditor ({ item, formValues, setFormValues, category }) {
  const isMobile = useIsMobile()
  const studentRatings = formValues.studentRatings
  const filteredStudentRatings = formValues.studentRatings
    .filter((x) => x.studentRatingCategoryId.toString() === category.value)
  const actions = (
    <RadSpaceBetween direction='horizontal' size='xs'>
      <RadButton
        onClick={() => {
          const previousStudentRating = filteredStudentRatings
            .find((x) => x.sortOrder === item.sortOrder - 1)
          if (previousStudentRating != null) {
            previousStudentRating.sortOrder = item.sortOrder
            item.sortOrder = item.sortOrder - 1
            setFormValues({ ...formValues, studentRatings })
          }
        }}
        disabled={item.sortOrder === 0}
      >
        Move up
      </RadButton>
      <RadButton
        onClick={() => {
          const nextStudentRating = filteredStudentRatings
            .find((x) => x.sortOrder === item.sortOrder + 1)
          if (nextStudentRating != null) {
            nextStudentRating.sortOrder = item.sortOrder
            item.sortOrder = item.sortOrder + 1
            setFormValues({ ...formValues, studentRatings })
          }
        }}
        disabled={item.sortOrder === filteredStudentRatings.length - 1}
      >
        Move down
      </RadButton>
      <RadButton
        onClick={() => {
          const studentRatings = formValues.studentRatings
            .filter((x) => x.id !== item.id || x.uuid !== item.uuid)
          setFormValues({ ...formValues, studentRatings })
        }}
      >
        Remove rating
      </RadButton>
    </RadSpaceBetween>
  )

  return (
    <RadSpaceBetween size='l'>
      <RadGrid
        gridDefinition={[
          { colspan: { default: 12, s: 3 } },
          { colspan: { default: 12, s: 4 } },
          { colspan: { default: 12, s: 5 } }
        ]}
      >
        <RadFormField label='Name' field={`${item.id ?? item.uuid}.name`} required>
          <RadInput
            placeholder='Enter name'
            value={item.name}
            onChange={({ detail }) => {
              item.name = detail.value
              setFormValues({ ...formValues, studentRatings })
            }}
          />
        </RadFormField>
        <RadFormField label='Description' field={`${item.id ?? item.uuid}.description`} required>
          <RadInput
            placeholder='Enter description'
            value={item.description}
            onChange={({ detail }) => {
              item.description = detail.value
              setFormValues({ ...formValues, studentRatings })
            }}
          />
        </RadFormField>
        {!isMobile && <RadBox padding={{ top: 'xl' }} float='right'>{actions}</RadBox>}
      </RadGrid>
      {isMobile && <>{actions}<RadDivider /></>}
    </RadSpaceBetween>
  )
}
