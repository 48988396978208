import React, { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import moment from 'moment-timezone'
import { Avatar } from '@rmwc/avatar'
import { useGet } from '../hooks/useGet'
import { usePost } from '../hooks/usePost'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { useIsMobile } from '../hooks/useIsMobile'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadCheckbox } from '../common/RadCheckbox'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadContainer } from '../common/RadContainer'
import { RadDivider } from '../common/RadDivider'
import { RadFormField } from '../common/RadFormField'
import { RadHeader } from '../common/RadHeader'
import { RadLink } from '../common/RadLink'
import { RadModal } from '../common/RadModal'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTextarea } from '../common/RadTextarea'
import { AttendancePieChart } from '../charts/AttendancePieChart'
import { CourseEnrollmentCards } from '../cards/CourseEnrollmentCards'
import { EthnicitiesPieChart } from '../charts/EthnicitiesPieChart'
import { GenderPieChart } from '../charts/GenderPieChart'
import { RacePieChart } from '../charts/RacePieChart'
import { SessionAttendanceBarChart } from '../charts/SessionAttendanceBarChart'
import { CourseSchedule } from '../shared/CourseSchedule'
import { formatDate, formatTimeRange, fullName } from '../common/utilities'
import './CourseDetail.scss'

export function CourseDetail () {
  const navigate = useNavigate()
  const { courseId } = useParams()
  const [showModal, setShowModal] = useState(false)
  const { data: course } = useGet(`/api/course/${courseId}?attendance&demographics`, true)
  const { data: settings } = useGet('/api/settings')
  const confirmDelete = useConfirm(
    'Delete course?',
    'Delete course permanently? This action cannot be undone.',
    'Delete', () => { remove() }
  )
  const remove = useDelete(`/api/course/${courseId}`, () => { navigate('/course') })

  if (
    course != null &&
    settings != null
  ) {
    const ended = moment().isAfter(moment(course.cohort.endDate))

    return (
      <RadAppLayout
        name={'Course # ' + course.id}
        contentHeader={
          <RadHeader
            variant='h1'
            description={course.description}
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('edit')} disabled={ended}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={course.isProtected || ended}>Delete</RadButton>
                <RadButton onClick={() => navigate('certificates')}>Certificates</RadButton>
                <RadButton onClick={() => setShowModal(true)}>Send Message</RadButton>
              </RadSpaceBetween>
            }
          >
            {'Course # ' + course.id}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <Details course={course} settings={settings} />
            <Sessions course={course} />
            <CourseEnrollmentCards courseEnrollments={course.enrollments} />
            <RadButton
              onClick={() => {
                navigate(`/course/${course.id}/enrollment/create?courseId=${course.id}&redirectURL=${window.location.pathname}`)
              }}
            >
              Add new enrollment
            </RadButton>
            <SendMessageModal course={course} showModal={showModal} setShowModal={setShowModal} />
          </RadSpaceBetween>
        }
      />
    )
  }
}

function Details ({ course, settings }) {
  return (
    <RadContainer
      header={
        <RadHeader variant='h2'>
          Details
        </RadHeader>
      }
    >
      <RadSpaceBetween size='l'>
        <RadColumnLayout columns={3} borders='vertical'>
          <div>
            <RadBox variant='awsui-key-label' padding={{ bottom: 's' }}>
              Cohort
            </RadBox>
            <RadLink variant='primary' href={`/cohort/${course.cohort.id}`}>{course.cohort.name ?? '-'}</RadLink>
          </div>
          <div>
            <RadBox variant='awsui-key-label' padding={{ bottom: 'xxs' }}>
              Program
            </RadBox>
            <RadSpaceBetween direction='horizontal'>
              <Avatar
                src={course.program.photoUrl ?? '/program_icon.webp'}
                size='large'
                referrerPolicy='no-referrer'
              />
              <RadBox padding={{ left: 's', top: 'xs' }}>
                <RadLink variant='primary' href={`/program/${course.program.id}`}>{course.program.name}</RadLink>
              </RadBox>
            </RadSpaceBetween>
          </div>
          <div>
            <RadBox variant='awsui-key-label' padding={{ bottom: 'xxs' }}>
              Instructor(s)
            </RadBox>
            <RadSpaceBetween size='xxs'>
              {course.instructors.map(x =>
                <RadSpaceBetween key={'instructor-' + x.id} direction='horizontal'>
                  <Avatar
                    src={x.instructor.photoUrl}
                    size='large'
                    name={fullName(x.instructor)}
                    referrerPolicy='no-referrer'
                  />
                  <RadBox padding={{ left: 's', top: 'xs' }}>
                    <RadLink variant='primary' href={`/instructor/${x.instructor.id}`}>{fullName(x.instructor)}</RadLink>
                  </RadBox>
                </RadSpaceBetween>
              )}
            </RadSpaceBetween>
          </div>
        </RadColumnLayout>
        <RadColumnLayout columns={3} borders='vertical'>
          <div>
            <RadBox variant='awsui-key-label'>
              Campus / Room
            </RadBox>
            {course.campus.name} {course.room ? ` / ${course.room}` : ''}
          </div>
          <div>
            <RadBox variant='awsui-key-label'>
              Capacity
            </RadBox>
            {course.capacity}
          </div>
          <div>
            <RadBox variant='awsui-key-label'>
              Schedule
            </RadBox>
            <CourseSchedule course={course} />
          </div>
        </RadColumnLayout>
        <DemographicsAndAttendance course={course} />
      </RadSpaceBetween>
    </RadContainer>
  )
}

function DemographicsAndAttendance ({ course }) {
  return (
    <>
      <RadColumnLayout columns={4}>
        <GenderPieChart genders={course.demographics.genders} />
        <RacePieChart races={course.demographics.races} />
        <EthnicitiesPieChart ethnicities={course.demographics.ethnicities} />
        <AttendancePieChart sessions={course.sessions.map(x => ({ ...x.attendance }))} />
      </RadColumnLayout>
      <RadDivider />
      <SessionAttendanceBarChart
        sessions={course.sessions.map(x => ({ courseId: course.id, date: x.date, ...x.attendance }))}
      />
    </>
  )
}

function Sessions ({ course }) {
  const isMobile = useIsMobile()
  return (
    <>
      <RadHeader
        variant='h2'
        counter={'(' + course.sessions.length + ')'}
      >
        Sessions
      </RadHeader>
      <RadSpaceBetween size='l'>
        {course.sessions.length === 0 && <RadBox color='text-body-secondary'>No sessions added</RadBox>}
        {course.sessions.length > 0 && !isMobile && <Calendar course={course} />}
        {course.sessions.length > 0 && isMobile && <SessionList course={course} />}
        {/* <RadButton
          onClick={() => navigate(`/session/create?courseId=${course.id}&redirectURL=${window.location.pathname}`)}
        >
          Add new session(s)
        </RadButton> */}
      </RadSpaceBetween>
    </>
  )
}

function Calendar ({ course }) {
  moment.timezone = 'America/Chicago'
  const { enrollments, sessions } = course
  const months = Array.from(new Set(sessions.map(x => moment(x.date, 'YYYY-MM-DD').format('YYYY-MM'))))
    .sort((a, b) => a - b)
    .map(x => moment(x + '-01', 'YYYY-MM-DD'))
  const [selectedMonth, setSelectedMonth] = useState(months.find(x => x.isSameOrBefore(moment())) ?? months[0])
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const firstDayOfMonth = moment(selectedMonth).startOf('month')
  const lastDayOfMonth = moment(selectedMonth).endOf('month')
  const startDay = firstDayOfMonth.clone().startOf('week')
  const endDay = lastDayOfMonth.clone().endOf('week')
  const cells = []
  let day = moment(startDay)
  while (day <= endDay) {
    cells.push({
      date: day.clone(),
      previousMonth: day.month() < selectedMonth.month(),
      nextMonth: day.month() > selectedMonth.month()
    })
    day = day.add(1, 'day')
  }

  const getClassName = (date) => {
    const classes = ['day']
    if (date.isSame(firstDayOfMonth, 'day') && date.week() === firstDayOfMonth.week()) {
      classes.push('first-day-first-week')
    }
    if (date.day() === 6 && date.week() === firstDayOfMonth.week()) {
      classes.push('last-day-first-week')
    }
    if (date.day() === 0 && date.week() === lastDayOfMonth.week()) {
      classes.push('first-day-last-week')
    }
    if (date.isSame(lastDayOfMonth, 'day') && date.week() === lastDayOfMonth.week()) {
      classes.push('last-day-last-week')
    }
    return classes.join(' ')
  }

  return (
    <div className='calendar'>
      <div className='top'>
        {!selectedMonth.isSame(months[0]) &&
          <button onClick={() => setSelectedMonth(selectedMonth.clone().subtract(1, 'month'))}>
            <span className='material-symbols-outlined'>arrow_back_ios</span>
          </button>}
        <span className='month-name'>{moment(selectedMonth, 'YYYY-MM').format('MMMM YYYY')}</span>
        {!selectedMonth.isSame(months[months.length - 1]) &&
          <button onClick={() => setSelectedMonth(selectedMonth.clone().add(1, 'month'))}>
            <span className='material-symbols-outlined'>arrow_forward_ios</span>
          </button>}
      </div>
      <div className='content'>
        {days.map(day =>
          <div key={day} className='day-of-week'>
            {day}
          </div>
        )}
        {cells.map(cell => {
          if (cell.previousMonth) {
            return (
              <div
                key={cell.date.toString()}
                className='day prev-month'
              />
            )
          } else if (cell.nextMonth) {
            return (
              <div
                key={cell.date.toString()}
                className='day next-month'
              />
            )
          } else {
            return (
              <div
                key={cell.date.toString()}
                className={getClassName(cell.date)}
              >
                <div className='number'>
                  {cell.date.format('D')}
                </div>
                <div className='content'>
                  {sessions
                    .filter(y => moment(y.date, 'YYYY-MM-DD').isSame(cell.date, 'day'))
                    .map(x => {
                      const activeEnrollmentCount = enrollments
                        .filter(y =>
                          moment(y.startDate, 'YYYY-MM-DD').isSameOrBefore(cell.date, 'day') &&
                          moment(y.endDate, 'YYYY-MM-DD').isSameOrAfter(cell.date, 'day')
                        ).length
                      return (
                        <RadSpaceBetween key={'session-' + x.id} size='xxs'>
                          <RadLink
                            className={
                              x.cancellationReason != null
                                ? 'cancelled '
                                : '' +
                                x.attendance.total < activeEnrollmentCount &&
                                  moment(x.date + ' ' + x.startTime, 'YYYY-MM-DD HH:mm:ss').isBefore(moment())
                                  ? 'red-link'
                                  : ''
                            }
                            variant='primary'
                            href={`/course/${course.id}/session/${x.id}?redirectURL=${window.location.pathname}`}
                          >
                            {formatTimeRange(moment(x.startTime, 'HH:mm'), moment(x.endTime, 'HH:mm'))}
                          </RadLink>
                          {/* {x.cancellationReason != null && <div className='reason'>{x.cancellationReason.name}</div>} */}
                        </RadSpaceBetween>
                      )
                    })}
                </div>
              </div>
            )
          }
        })}
      </div>
    </div>
  )
}

function SessionList ({ course }) {
  const { enrollments, sessions } = course

  return (
    <RadBox padding={{ top: 's' }}>
      <RadSpaceBetween size='xs'>
        {sessions.map(x =>
          <RadLink
            className={
              x.attendance.total < enrollments.length &&
              moment(x.date + ' ' + x.startTime, 'YYYY-MM-DD HH:mm:ss').isBefore(moment())
                ? 'red-link'
                : ''
            }
            key={'session-' + x.id}
            href={`/session/${x.id}?redirectURL=${window.location.pathname}`}
          >
            {formatDate(x.date, 'dddd, MMMM D, YYYY')} | {formatTimeRange(moment(x.startTime, 'HH:mm'), moment(x.endTime, 'HH:mm'))}
          </RadLink>
        )}
      </RadSpaceBetween>
    </RadBox>
  )
}

function SendMessageModal ({ course, showModal, setShowModal }) {
  const defaultFormValues = { contacts: false, students: false, message: null }
  const [formValues, setFormValues] = useState(defaultFormValues)
  const send = usePost(
    `/api/course/${course.id}/send-message`,
    formValues,
    () => { close() }
  )

  function close () {
    setShowModal(false)
    setFormValues(defaultFormValues)
  }

  return (
    <RadModal
      onDismiss={() => setShowModal(false)}
      visible={showModal}
      closeAriaLabel='Close modal'
      footer={
        <RadBox float='right'>
          <RadSpaceBetween direction='horizontal' size='xs'>
            <RadButton variant='link' onClick={() => close()}>Close</RadButton>
            <RadButton
              variant='primary'
              onClick={() => send()}
              disabled={(formValues.contacts === false && formValues.students === false) || formValues.message == null}
            >
              Send
            </RadButton>
          </RadSpaceBetween>
        </RadBox>
      }
      header='Send Message'
    >
      <RadSpaceBetween size='l'>
        <RadFormField
          label='Message'
          description='Because texts are limited to 160 characters, if your message exceeds that character limit, it will be delivered as multiple texts to the recipient.'
        >
          <RadTextarea
            rows={4}
            placeholder='Enter message'
            value={formValues.message}
            onChange={({ detail }) => setFormValues({ ...formValues, message: detail.value })}
          />
          <RadBox
            color='text-status-inactive'
            fontSize='body-s'
            padding={{ top: 'xxs' }}
          >
            {formValues.message?.length ?? 0} characters
          </RadBox>
        </RadFormField>
        <RadFormField label='Send to students'>
          <RadCheckbox
            checked={formValues.students}
            onChange={({ detail }) => setFormValues({ ...formValues, students: detail.checked })}
          >
            Send message to all currently enrolled students
          </RadCheckbox>
        </RadFormField>
        <RadFormField label='Send to student contacts'>
          <RadCheckbox
            checked={formValues.contacts}
            onChange={({ detail }) => setFormValues({ ...formValues, contacts: detail.checked })}
          >
            Send message to the contacts of all currently enrolled students
          </RadCheckbox>
        </RadFormField>
      </RadSpaceBetween>
    </RadModal>
  )
}
