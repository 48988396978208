import React, { useState, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import moment from 'moment-timezone'
import { Avatar } from '@rmwc/avatar'
import { useGet } from '../hooks/useGet'
import { usePost } from '../hooks/usePost'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAlert } from '../common/RadAlert'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadBreadcrumbGroup } from '../common/RadBreadcrumbGroup'
import { RadButton } from '../common/RadButton'
import { RadCards } from '../common/RadCards'
import { RadCheckbox } from '../common/RadCheckbox'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadContainer } from '../common/RadContainer'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadHeader } from '../common/RadHeader'
import { RadLink } from '../common/RadLink'
import { RadModal } from '../common/RadModal'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { CourseSchedule } from '../shared/CourseSchedule'
import { formatDate, formatTimeRange, fullName } from '../common/utilities'
import './SessionDetail.scss'

export function SessionDetail () {
  moment.tz.setDefault('America/Chicago')
  const navigate = useNavigate()
  const { courseId, sessionId } = useParams()
  const [searchParams] = useSearchParams()
  const [formValues, setFormValues] = useState()
  const [showModal, setShowModal] = useState(false)
  const { data: session } = useGet(`/api/session/${sessionId}`, true)
  const confirmDelete = useConfirm(
    'Delete session?',
    'Delete session permanently? This action cannot be undone.',
    'Delete', () => { remove() }
  )
  const remove = useDelete(
    `/api/session/${sessionId}`,
    () => { navigate(searchParams.get('redirectURL') ?? `/session/${sessionId}`) }
  )

  useEffect(() => {
    if (session != null) {
      const attendance = session.attendance.map(x => ({ courseEnrollmentId: x.courseEnrollmentId, absent: x.absent }))
      for (const enrollment of session.course.enrollments) {
        if (!attendance.some(x => x.courseEnrollmentId === enrollment.id)) {
          attendance.push({ courseEnrollmentId: enrollment.id, absent: null })
        }
      }
      setFormValues({ sessionId: parseInt(sessionId), attendance })
    }
  }, [session])

  if (formValues != null) {
    return (
      <RadAppLayout
        breadcrumbs={
          <RadBreadcrumbGroup
            items={[
              { text: 'Dashboard', href: '/' },
              { text: 'Courses', href: '/course' },
              { text: 'Course # ' + courseId, href: '/course/' + courseId },
              {
                text: 'Session # ' + sessionId,
                href: '#'
              }
            ]}
            ariaLabel='Breadcrumbs'
          />
      }
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => setShowModal(true)}>Cancel Session</RadButton>
                <RadButton onClick={confirmDelete}>Delete Session</RadButton>
              </RadSpaceBetween>
            }
          >
            Session # {session?.id}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <Details session={session} />
            <Course session={session} />
            <Attendance
              session={session}
              formValues={formValues}
              setFormValues={setFormValues}
            />
            <CancelModal session={session} showModal={showModal} setShowModal={setShowModal} />
          </RadSpaceBetween>
        }
      />
    )
  }
}

function Details ({ session }) {
  return (
    <RadContainer
      header={
        <RadHeader variant='h2'>
          Details
        </RadHeader>
      }
    >
      <RadSpaceBetween size='l'>
        <RadColumnLayout columns={2} borders='vertical'>
          <div>
            <RadBox variant='awsui-key-label'>
              Date
            </RadBox>
            <div>{formatDate(session.date, 'dddd, MMMM D, YYYY')} </div>
          </div>
          <div>
            <RadBox variant='awsui-key-label'>
              Time
            </RadBox>
            <div>{formatTimeRange(session.startTime, session.endTime)}</div>
          </div>
        </RadColumnLayout>
      </RadSpaceBetween>
    </RadContainer>
  )
}

function Course ({ session }) {
  const { course } = session

  return (
    <RadContainer
      header={
        <RadHeader variant='h2'>
          Course
        </RadHeader>
      }
    >
      <RadSpaceBetween size='l'>
        <RadColumnLayout columns={3} borders='vertical'>
          <div>
            <RadBox variant='awsui-key-label' padding={{ bottom: 's' }}>
              Cohort
            </RadBox>
            <RadLink variant='primary' href={`/cohort/${course.cohort.id}`}>{course.cohort.name ?? '-'}</RadLink>
          </div>
          <div>
            <RadBox variant='awsui-key-label' padding={{ bottom: 'xxs' }}>
              Program
            </RadBox>
            <RadSpaceBetween direction='horizontal'>
              <Avatar
                src={course.program.photoUrl ?? '/program_icon.webp'}
                size='large'
                referrerPolicy='no-referrer'
              />
              <RadBox padding={{ left: 's', top: 'xs' }}>
                <RadLink variant='primary' href={`/program/${course.program.id}`}>{course.program.name}</RadLink>
              </RadBox>
            </RadSpaceBetween>
          </div>
          <div>
            <RadBox variant='awsui-key-label' padding={{ bottom: 'xxs' }}>
              Instructor(s)
            </RadBox>
            <RadSpaceBetween size='xxs'>
              {course.instructors.map(x =>
                <RadSpaceBetween key={'instructor-' + x.id} direction='horizontal'>
                  <Avatar
                    src={x.instructor.photoUrl}
                    size='large'
                    name={fullName(x.instructor)}
                    referrerPolicy='no-referrer'
                  />
                  <RadBox padding={{ left: 's', top: 'xs' }}>
                    <RadLink variant='primary' href={`/instructor/${x.instructor.id}`}>{fullName(x.instructor)}</RadLink>
                  </RadBox>
                </RadSpaceBetween>
              )}
            </RadSpaceBetween>
          </div>
        </RadColumnLayout>
        <RadColumnLayout columns={3} borders='vertical'>
          <div>
            <RadBox variant='awsui-key-label'>
              Campus / Room
            </RadBox>
            {course.campus.name} {course.room ? ` / ${course.room}` : ''}
          </div>
          <div>
            <RadBox variant='awsui-key-label'>
              Capacity
            </RadBox>
            {course.capacity}
          </div>
          <div>
            <RadBox variant='awsui-key-label'>
              Schedule
            </RadBox>
            <CourseSchedule course={course} />
          </div>
        </RadColumnLayout>
      </RadSpaceBetween>
    </RadContainer>
  )
}

function Attendance ({ session, formValues, setFormValues }) {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const canSetAttendance = moment().isAfter(moment(session.date + ' ' + session.startTime, 'YYYY-MM-DD HH:mm:ss'))
  const filteredEnrollments = session.course.enrollments
    .filter(x =>
      moment(x.startDate).isSameOrBefore(moment(session.date)) &&
      moment(x.endDate).isSameOrAfter(moment(session.date))
    )
  const update = usePost(
    '/api/attendance',
    formValues,
    (resp) => { navigate(searchParams.get('redirectURL') ?? `/course/${session.courseId}`) }
  )

  async function cancel () {
    navigate(searchParams.get('redirectURL') ?? `/course/${session.courseId}`)
  }

  async function saveChanges () {
    update()
  }
  function toggleAttendance (courseEnrollmentId) {
    const attendances = formValues.attendance
    const attendance = attendances.find(y => y.courseEnrollmentId === courseEnrollmentId)

    if (attendance.absent == null) {
      attendance.absent = true
    } else if (attendance.absent === false) {
      attendance.absent = null
    } else {
      attendance.absent = false
    }
    setFormValues({ ...formValues, attendance: attendances })
  }

  if (!canSetAttendance) {
    return (
      <RadBox color='text-status-inactive'>Attendance will be available after the session starts.</RadBox>
    )
  }

  return (
    <RadSpaceBetween size='l'>
      <RadHeader
        variant='h2'
        counter={'(' + filteredEnrollments.length + ')'}
      >
        Attendance
      </RadHeader>
      <form onSubmit={e => e.preventDefault()}>
        <RadForm
          actions={
            canSetAttendance &&
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                <RadButton formAction='submit' variant='primary' onClick={saveChanges}>
                  Update attendance
                </RadButton>
              </RadSpaceBetween>
          }
        >
          <RadSpaceBetween size='s'>
            {filteredEnrollments.length === 0 && <RadBox color='text-body-secondary'>No enrollments added</RadBox>}
            {filteredEnrollments.length > 0 &&
              <RadCards
                ariaLabels={{
                  itemSelectionLabel: (e, t) => `select ${t.name}`,
                  selectionGroupLabel: 'Item selection'
                }}
                cardDefinition={{
                  header: item =>
                    <RadLink fontSize='heading-m' href={`/person/${item.id}`}>
                      <RadSpaceBetween size='xs'>
                        <Avatar
                          src={item.person.photoUrl}
                          size='large'
                          name={`${item.person.firstName} ${item.person.lastName}`}
                          referrerPolicy='no-referrer'
                        />
                        <div>{fullName(item.person)}</div>
                      </RadSpaceBetween>
                    </RadLink>,
                  sections: [
                    {
                      id: 'info',
                      content: item => {
                        const absent = formValues.attendance.find(y => y.courseEnrollmentId === item.id)?.absent
                        return (
                          <div className='attendance-toggle'>
                            <button
                              onClick={() => toggleAttendance(item.id)}
                            >
                              {absent === false &&
                                <span className='material-symbols-outlined true'>check</span>}
                              {absent === true &&
                                <span className='material-symbols-outlined false'>close</span>}
                              {absent == null &&
                                <span className='material-symbols-outlined null'>question_mark</span>}
                            </button>
                          </div>
                        )
                      }
                    }
                  ]
                }}
                cardsPerRow={[
                  { cards: 1 },
                  { minWidth: 640, cards: 2 },
                  { minWidth: 960, cards: 3 },
                  { minWidth: 1280, cards: 4 }
                ]}
                items={filteredEnrollments}
                variant='full-page'
                empty=''
              />}
            {/* <RadButton
      onClick={() => {
        navigate(`/session-enrollment/create?sessionId=${sessionId}&redirectURL=${window.location.pathname}`)
      }}
    >
      Add new enrollment
    </RadButton> */}
          </RadSpaceBetween>
        </RadForm>
      </form>
    </RadSpaceBetween>
  )
}

function CancelModal ({ session, showModal, setShowModal }) {
  moment.tz.setDefault('America/Chicago')
  const navigate = useNavigate()
  const [reasonId, setReasonId] = useState()
  const [sendNotifications, setSendNotifications] = useState(true)
  const { data: reasonOptions } = useGet('/api/option/session_cancellation_reason')
  const cancelSession = usePost(
    `/api/session/${session.id}/cancel`,
    { cancellationReasonId: reasonId, sendNotifications },
    () => { navigate(`/course/${session.courseId}`) })
  const hasEnded = moment().isAfter(moment(session.date + ' ' + session.endTime, 'YYYY-MM-DD HH:mm:ss'))

  if (reasonOptions == null) return null

  return (
    <RadModal
      onDismiss={() => setShowModal(false)}
      visible={showModal}
      closeAriaLabel='Close modal'
      footer={
        <RadBox float='right'>
          <RadSpaceBetween direction='horizontal' size='xs'>
            <RadButton variant='link' onClick={() => setShowModal(false)}>Close</RadButton>
            <RadButton variant='primary' onClick={() => cancelSession()} disabled={reasonId == null}>
              Cancel Session
            </RadButton>
          </RadSpaceBetween>
        </RadBox>
  }
      header='Cancel Session'
    >
      <RadSpaceBetween size='l'>
        <RadFormField label='Reason'>
          <RadSelect
            selectedOption={reasonOptions.find(x => x.value === reasonId)}
            onChange={({ detail }) => setReasonId(detail.selectedOption.value)}
            options={reasonOptions}
            selectedAriaLabel='Selected'
            placeholder='Select a reason'
            empty='No matches found'
          />
        </RadFormField>
        <RadFormField label='Send Notifications'>
          <RadCheckbox
            checked={sendNotifications}
            onChange={({ detail }) => setSendNotifications(detail.checked)}
          >
            Send notifications to all students and parents
          </RadCheckbox>
        </RadFormField>
        {hasEnded &&
          <RadAlert
            statusIconAriaLabel='Info'
            header='Notifications'
          >
            This session has already ended. No notifications will be sent.
          </RadAlert>}
      </RadSpaceBetween>
    </RadModal>
  )
}
