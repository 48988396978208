import React from 'react'
import { RadBox } from '../common/RadBox'
import { RadPieChart } from '../common/RadPieChart'

export function UsCitizenOrPermanentResidentPieChart ({ usCitizenOrPermanentResidents, total }) {
  return (
    <RadPieChart
      ariaDescription='Donut chart showing US Citizen or Permanent Resident'
      ariaLabel='Donut chart'
      data={usCitizenOrPermanentResidents.map(x => ({
        title: x.usCitizenOrPermanentResident ? 'Yes' : 'No',
        value: x.count,
        percentage: (x.count / total * 100).toFixed(0) + '%'
      }))}
      empty={
        <RadBox textAlign='center' color='inherit'>
          <b>US Citizen or Permanent Resident</b>
          <RadBox variant='p' color='inherit'>
            There is no data available
          </RadBox>
        </RadBox>
      }
      innerMetricDescription={
        <RadBox fontSize='body-m' fontWeight='bold' color='text-body-secondary'>US Citizen or<br />Permanent Resident</RadBox>
      }
      // innerMetricValue={usCitizenOrPermanentResidents.length}
      size='medium'
      variant='donut'
      hideFilter
      hideLegend
      hideTitles
      hideDescriptions
    />
  )
}
