import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useGet } from '../hooks/useGet'
import { useIsMobile } from '../hooks/useIsMobile'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadCheckbox } from '../common/RadCheckbox'
import { RadDivider } from '../common/RadDivider'
import { RadFormField } from '../common/RadFormField'
import { RadGrid } from '../common/RadGrid'
import { RadInput } from '../common/RadInput'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function PersonContactsFormFields ({ formValues, setFormValues }) {
  const { data: relationshipOptions } = useGet('/api/option/relationship')

  if (
    relationshipOptions != null
  ) {
    return (
      <RadSpaceBetween size='l'>
        {formValues.contacts.map((x) =>
          <ContactEditor
            key={`contact-${x.id},${x.uuid}`}
            item={x}
            formValues={formValues}
            setFormValues={setFormValues}
            relationshipOptions={relationshipOptions}
          />)}
        <RadButton
          onClick={() => {
            const contacts = formValues.contacts
            contacts.push({ uuid: uuidv4(), emergencyContact: false, smsPermission: false })
            setFormValues({ ...formValues, contacts })
          }}
        >
          Add new contact
        </RadButton>
      </RadSpaceBetween>
    )
  }
}

function ContactEditor ({ item, formValues, setFormValues, relationshipOptions }) {
  const isMobile = useIsMobile()
  const contacts = formValues.contacts

  const removeButton = (
    <RadButton
      onClick={() => {
        const contacts = formValues.contacts
          .filter((x) => x.id !== item.id || x.uuid !== item.uuid)
        setFormValues({ ...formValues, contacts })
      }}
    >
      Remove contact
    </RadButton>
  )

  return (
    <RadSpaceBetween size='l'>
      <RadGrid
        gridDefinition={[
          { colspan: { default: 12, xs: 3 } },
          { colspan: { default: 12, xs: 3 } },
          { colspan: { default: 12, xs: 3 } },
          { colspan: { default: 12, xs: 3 } }
        ]}
      >
        <RadFormField label='First Name' field={`contact.${item.id ?? item.uuid}.firstName`} required>
          <RadInput
            placeholder='Enter first name'
            value={item.firstName}
            onChange={({ detail }) => {
              item.firstName = detail.value
              setFormValues({ ...formValues, contacts })
            }}
          />
        </RadFormField>
        <RadFormField label='Last Name' field={`contact.${item.id ?? item.uuid}.lastName`} required>
          <RadInput
            placeholder='Enter last name'
            value={item.lastName}
            onChange={({ detail }) => {
              item.lastName = detail.value
              setFormValues({ ...formValues, contacts })
            }}
          />
        </RadFormField>
        <RadFormField label='Relationship' field={`contact.${item.id ?? item.uuid}.relationshipId`} required>
          <RadSelect
            selectedOption={item.relationshipId ? relationshipOptions.find(x => x.value === item.relationshipId?.toString()) : { value: '', label: '-' }}
            onChange={({ detail }) => {
              if (detail.selectedOption.value !== '') {
                item.relationshipId = parseInt(detail.selectedOption.value)
              } else {
                item.relationshipId = null
              }
              setFormValues({ ...formValues, contacts })
            }}
            options={relationshipOptions}
            selectedAriaLabel='Selected'
            empty='No matches found'
          />
        </RadFormField>
        {!isMobile && <RadBox padding={{ top: 'xl' }} float='right'>{removeButton}</RadBox>}
      </RadGrid>
      {/* <RadFormField field={`contact.${item.id ?? item.uuid}.phoneNumbers`} /> */}
      <RadGrid
        gridDefinition={[
          { colspan: { default: 12, xs: 2 } },
          { colspan: { default: 12, xs: 2 } },
          { colspan: { default: 12, xs: 2 } },
          { colspan: { default: 12, xs: 3 } }
        ]}
      >
        <RadFormField label='Mobile' field={`contact.${item.id ?? item.uuid}.mobile`}>
          <RadInput
            placeholder='###-###-####'
            value={item.mobile}
            onChange={({ detail }) => {
              item.mobile = detail.value
              setFormValues({ ...formValues, contacts })
            }}
          />
        </RadFormField>
        <RadFormField label='Work' field={`contact.${item.id ?? item.uuid}.work`}>
          <RadInput
            placeholder='###-###-####'
            value={item.work}
            onChange={({ detail }) => {
              item.work = detail.value
              setFormValues({ ...formValues, contacts })
            }}
          />
        </RadFormField>
        <RadFormField label='Home' field={`contact.${item.id ?? item.uuid}.home`}>
          <RadInput
            placeholder='###-###-####'
            value={item.home}
            onChange={({ detail }) => {
              item.home = detail.value
              setFormValues({ ...formValues, contacts })
            }}
          />
        </RadFormField>
        <RadFormField label='Email' field={`contact.${item.id ?? item.uuid}.email`} required>
          <RadInput
            placeholder='Enter email'
            value={item.email}
            onChange={({ detail }) => {
              item.email = detail.value
              setFormValues({ ...formValues, contacts })
            }}
          />
        </RadFormField>
      </RadGrid>
      <RadGrid
        gridDefinition={[
          { colspan: { default: 12, s: 2 } },
          { colspan: { default: 12, s: 2 } }
        ]}
      >
        <RadFormField label='Emergency Contact' field={`contact.${item.id ?? item.uuid}.emergencyContact`}>
          <RadCheckbox
            checked={item.emergencyContact}
            onChange={({ detail }) => {
              item.emergencyContact = detail.checked
              setFormValues({ ...formValues, contacts })
            }}
          />
        </RadFormField>
        <RadFormField label='Permission to Text' field={`contact.${item.id ?? item.uuid}.smsPermission`}>
          <RadCheckbox
            checked={item.smsPermission}
            onChange={({ detail }) => {
              item.smsPermission = detail.checked
              setFormValues({ ...formValues, contacts })
            }}
          />
        </RadFormField>
      </RadGrid>
      {isMobile && removeButton}
      {item !== contacts[contacts.length - 1] && <RadDivider />}
    </RadSpaceBetween>
  )
}
