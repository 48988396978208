import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import * as pluralize from 'pluralize'
import { toTitleCase } from 'titlecase'
import { AppContext } from './../App'
import { RadSideNavigation } from './RadSideNavigation'
import { RadBreadcrumbGroup } from './RadBreadcrumbGroup'
import AppLayout from '@cloudscape-design/components/app-layout'
import Flashbar from '@cloudscape-design/components/flashbar'
import { isInteger } from '../common/utilities'

export function RadAppLayout ({ noBreadcrumbs, notifications, ...rest }) {
  const location = useLocation()
  const { navigationOpen, setNavigationOpen } = useContext(AppContext)

  let notificationItems = []
  if (notifications != null && notifications.length > 0) {
    notificationItems = notificationItems.concat(notifications)
  }

  function getName (text) {
    return pluralize(
      toTitleCase(text).replaceAll('-', ' ').replaceAll('_', ' '))
      .replace('Edits', 'Edit')
      .replace('Creates', 'Create')
  }

  function breadcrumbs () {
    const items = []
    let acc = ''

    items.push({ text: 'Dashboard', href: '/' })
    const paths = location.pathname.split('/')
    paths.splice(0, 1)

    const names = rest.names ?? {}

    for (const index in paths) {
      const path = paths[index]
      acc += '/' + path
      const previous = paths[index - 1] ?? ''
      items.push({ text: isInteger(path) ? names[previous] ?? rest.name : getName(path), href: acc })
    }

    return (
      <RadBreadcrumbGroup
        items={items}
        ariaLabel='Breadcrumbs'
      />
    )
  }

  if (rest.breadcrumbs == null && noBreadcrumbs == null) {
    rest.breadcrumbs = breadcrumbs()
  }

  if (rest.navigation == null) {
    rest.navigation = <RadSideNavigation setNavigationOpen={setNavigationOpen} />
  }

  // https://cloudscape.design/components/app-layout
  return (
    <AppLayout
      ariaLabels={{
        navigation: 'Navigation',
        navigationClose: 'Navigation close',
        navigationToggle: 'Navigation toggle',
        notifications: 'Notifications',
        tools: 'Tools',
        toolsClose: 'Tools close',
        toolsToggle: 'Tools toggle'
      }}
      {...rest}
      navigationOpen={navigationOpen}
      onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
      notifications={<Flashbar items={notificationItems} />}
      toolsHide
    />
  )
}
