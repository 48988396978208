import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import moment from 'moment'
import { useGet } from '../hooks/useGet'
import { Avatar } from '@rmwc/avatar'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadCards } from '../common/RadCards'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadLink } from '../common/RadLink'
import { RadPagination } from '../common/RadPagination'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { fullName, formatTimeRange } from '../common/utilities'

export function CourseList () {
  moment.tz.setDefault('America/Chicago')
  const pageLength = 20
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentPageIndex, setCurrentPageIndex] = useState(
    searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1
  )
  const [searchText, setSearchText] = useState(searchParams.get('search') ?? '')
  const [status, setStatus] = useState(searchParams.get('status') ?? '')
  const { data: courses, count, info } = useGet(
    `/api/course?search=${searchText}&status=${status}&limit=${pageLength}&offset=${(currentPageIndex - 1) * pageLength}`
  )

  function escapeRegExp (string) {
    if (string == null) return null
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
  }

  const highlightMatch = (text) => {
    if (text == null || searchText == null) return null
    const escapedSearchText = escapeRegExp(searchText)
    const parts = text.split(new RegExp(`(${escapedSearchText})`, 'gi'))
    return (
      <span>{parts.map((part, i) =>
        part.toLowerCase() === searchText.toLowerCase()
          ? <span key={i} className='highlight'>{part}</span>
          : part
      )}
      </span>
    )
  }

  if (
    courses != null
  ) {
    const statusOptions = [
      { value: '', label: 'Active' },
      { value: 'future', label: 'Upcoming' },
      { value: 'past', label: 'Ended' },
      { value: 'all', label: 'All' }
    ]

    return (
      <RadAppLayout
        contentHeader={
          <RadHeader
            variant='h1'
            counter={'(' + count + ')'}
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('/course/create')}>Create</RadButton>
              </RadSpaceBetween>
            }
          >
            Courses
          </RadHeader>
        }
        content={
          <RadCards
            ariaLabels={{
              itemSelectionLabel: (e, t) => `select ${t.name}`,
              selectionGroupLabel: 'Item selection'
            }}
            cardDefinition={{
              header: item =>
                <RadLink fontSize='heading-m' href={`/course/${item.id}`}>
                  <RadSpaceBetween size='xs'>
                    <Avatar
                      src={item.program.photoUrl ?? '/program_icon.webp'}
                      size='large'
                      referrerPolicy='no-referrer'
                    />
                    {highlightMatch('Course # ' + item.id.toString())}
                  </RadSpaceBetween>
                </RadLink>,
              sections: [
                {
                  id: 'info',
                  content: item =>
                    <RadSpaceBetween size='xxs'>
                      <RadSpaceBetween direction='horizontal' size='xs'>
                        <RadBox variant='awsui-key-label'>Cohort</RadBox>
                        <RadLink href={`/cohort/${item.cohort.id}`}>{item.cohort.name}</RadLink>
                      </RadSpaceBetween>
                      <RadSpaceBetween direction='horizontal' size='xs'>
                        <RadBox variant='awsui-key-label'>Program</RadBox>
                        <RadLink href={`/program/${item.program.id}`}>{item.program.name}</RadLink>
                      </RadSpaceBetween>
                      <RadSpaceBetween direction='horizontal' size='xs'>
                        <RadBox variant='awsui-key-label'>Instructor(s)</RadBox>
                        {item.instructors.map(x =>
                          <RadLink key={item.id + 'instructor-' + x.id} href={`/instructor/${x.id}`}>{fullName(x)}</RadLink>
                        )}
                      </RadSpaceBetween>
                      <RadSpaceBetween direction='horizontal' size='xs'>
                        <RadBox variant='awsui-key-label'>Location</RadBox>
                        <div>{item.campus.name} {item.room != null ? ` - Room ${item.room}` : ''}</div>
                      </RadSpaceBetween>
                      <RadSpaceBetween direction='horizontal' size='xs'>
                        <RadBox variant='awsui-key-label'>Schedule</RadBox>
                        {item.schedules.map(x => (
                          <div key={'session-' + x.id}>
                            {x.weekdays.map(day => moment(day, 'ddd').format('ddd')).join(', ')}
                            &nbsp;at&nbsp;
                            {formatTimeRange(moment(x.startTime, 'HH:mm'), moment(x.endTime, 'HH:mm'))}
                          </div>))}
                      </RadSpaceBetween>
                      {item.notes != null && <div>{highlightMatch(item.notes)}</div>}
                    </RadSpaceBetween>
                }
              ]
            }}
            cardsPerRow={[
              { cards: 1 },
              { minWidth: 640, cards: 2 },
              // { minWidth: 960, cards: 3 },
              { minWidth: 1280, cards: 3 }
            ]}
            items={courses}
            filter={
              <RadGrid
                gridDefinition={[
                  { colspan: { default: 12, xs: 9 } },
                  { colspan: { default: 12, xs: 3 } }
                ]}
              >
                <RadInput
                  value={searchText}
                  placeholder='Search'
                  type='search'
                  onChange={({ detail }) => {
                    setSearchText(detail.value ?? '')
                    if (detail.value) {
                      searchParams.set('search', detail.value)
                    } else {
                      searchParams.delete('search')
                    }
                    searchParams.delete('page')
                    setCurrentPageIndex(1)
                    setSearchParams(searchParams)
                  }}
                />
                <RadSelect
                  selectedOption={statusOptions.find(x => x.value === status)}
                  onChange={({ detail }) => {
                    if (detail.selectedOption.value === '') {
                      setStatus('')
                      searchParams.delete('status')
                    } else {
                      setStatus(detail.selectedOption.value)
                      searchParams.set('status', detail.selectedOption.value)
                    }
                    setSearchParams(searchParams)
                  }}
                  options={statusOptions}
                  enteredTextLabel={value => value}
                  selectedAriaLabel='Selected'
                  placeholder='Choose a status'
                  empty='No matches found'
                />
              </RadGrid>
            }
            pagination={
              <RadPagination
                currentPageIndex={currentPageIndex}
                pagesCount={Math.ceil(count / pageLength)}
                onChange={({ detail }) => {
                  searchParams.set('page', detail.currentPageIndex)
                  setSearchParams(searchParams)
                  setCurrentPageIndex(detail.currentPageIndex)
                }}
                ariaLabels={{
                  nextPageLabel: 'Next page',
                  previousPageLabel: 'Previous page',
                  pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                }}
              />
            }
            variant='full-page'
            empty={
              <RadBox textAlign='center' color='inherit'>
                {info ?? 'No matches found'}
              </RadBox>
            }
          />
        }
      />
    )
  }
}
