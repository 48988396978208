import React from 'react'
import BarChart from '@cloudscape-design/components/bar-chart'
import { RadBox } from './RadBox'

// https://cloudscape.design/components/bar-chart
export function RadBarChart ({ empty, ...rest }) {
  const defaultEmpty = (
    <RadBox textAlign='center' color='inherit'>
      <b>No data available</b>
      <RadBox variant='p' color='inherit'>
        There is no data available
      </RadBox>
    </RadBox>
  )

  return (
    <BarChart
      empty={empty ?? defaultEmpty}
      {...rest}
    />
  )
}
