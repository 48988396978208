import React from 'react'
import { useGet } from '../hooks/useGet'
import { RadBox } from '../common/RadBox'
import { RadCheckbox } from '../common/RadCheckbox'
import { RadFormField } from '../common/RadFormField'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function PersonHealthInformationFormFields ({ formValues, setFormValues }) {
  const { data: allergyOptions } = useGet('/api/option/allergy')
  const { data: dietaryNeedOptions } = useGet('/api/option/dietary_need')
  const { data: disabilityOptions } = useGet('/api/option/disability')

  if (
    allergyOptions != null &&
    dietaryNeedOptions != null &&
    disabilityOptions != null
  ) {
    return (
      <RadSpaceBetween size='l'>
        <RadFormField label='Allergies' field='allergies' stretch>
          <RadSpaceBetween direction='horizontal' size='xxs'>
            {allergyOptions.map((x) =>
              <RadBox key={x.value} padding={{ right: 'l' }}>
                <RadBox padding={{ bottom: 'xs' }}>
                  <RadCheckbox
                    checked={formValues.allergies.some((y) => y.allergyId.toString() === x.value)}
                    onChange={({ detail }) => {
                      if (detail.checked) {
                        setFormValues({ ...formValues, allergies: [...(formValues.allergies ?? []), { allergyId: parseInt(x.value) }] })
                      } else {
                        setFormValues({ ...formValues, allergies: (formValues.allergies ?? []).filter((y) => y.allergyId.toString() !== x.value) })
                      }
                    }}
                  >
                    {x.label}
                  </RadCheckbox>
                </RadBox>
              </RadBox>
            )}
          </RadSpaceBetween>
        </RadFormField>
        <RadFormField label='Food Sensitivities / Dietary Needs' field='dietaryNeeds' stretch>
          <RadSpaceBetween direction='horizontal' size='xxs'>
            {dietaryNeedOptions.map((x) =>
              <RadBox key={x.value} padding={{ right: 'l' }}>
                <RadBox padding={{ bottom: 'xs' }}>
                  <RadCheckbox
                    checked={formValues.dietaryNeeds.some((y) => y.dietaryNeedId.toString() === x.value)}
                    onChange={({ detail }) => {
                      if (detail.checked) {
                        setFormValues({ ...formValues, dietaryNeeds: [...(formValues.dietaryNeeds ?? []), { dietaryNeedId: parseInt(x.value) }] })
                      } else {
                        setFormValues({ ...formValues, dietaryNeeds: (formValues.dietaryNeeds ?? []).filter((y) => y.dietaryNeedId.toString() !== x.value) })
                      }
                    }}
                  >
                    {x.label}
                  </RadCheckbox>
                </RadBox>
              </RadBox>
            )}
          </RadSpaceBetween>
        </RadFormField>
        <RadFormField label='ADA Documented Disabilities' field='disabilities' stretch>
          <RadSpaceBetween direction='horizontal' size='xxs'>
            {disabilityOptions.map((x) =>
              <RadBox key={x.value} padding={{ right: 'l' }}>
                <RadBox padding={{ bottom: 'xs' }}>
                  <RadCheckbox
                    checked={formValues.disabilities.some((y) => y.disabilityId.toString() === x.value)}
                    onChange={({ detail }) => {
                      if (detail.checked) {
                        setFormValues({ ...formValues, disabilities: [...(formValues.disabilities ?? []), { disabilityId: parseInt(x.value) }] })
                      } else {
                        setFormValues({ ...formValues, disabilities: (formValues.disabilities ?? []).filter((y) => y.disabilityId.toString() !== x.value) })
                      }
                    }}
                  >
                    {x.label}
                  </RadCheckbox>
                </RadBox>
              </RadBox>
            )}
          </RadSpaceBetween>
        </RadFormField>

      </RadSpaceBetween>
    )
  }
}
