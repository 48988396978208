import React from 'react'
import Input from '@cloudscape-design/components/input'
import { format } from './utilities'

function formatTel (data) {
  return format(data, '999-999-9999', {
    9: (c) => {
      const code = c.charCodeAt(0)
      return code >= 48 && code <= 57 // 0-9
    }
  })
}

export function RadInput ({ onChange, type, ...rest }) {
  return (
    <>
      <Input
        type={type}
        onChange={({ detail }) => {
          if (detail.value.trim() === '') {
            return onChange({ detail: { value: null } })
          }
          if (type === 'tel') {
            return onChange({ detail: { value: formatTel(detail.value) } })
          } else {
            return onChange({ detail })
          }
        }}
        {...rest}
      />
    </>
  )
}
