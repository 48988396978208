import React, { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import moment from 'moment-timezone'
import { v4 as uuidv4 } from 'uuid'
import { Avatar } from '@rmwc/avatar'
import { useGet } from '../hooks/useGet'
import { usePut } from '../hooks/usePut'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadContainer } from '../common/RadContainer'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadLink } from '../common/RadLink'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { CourseSchedule } from '../shared/CourseSchedule'
import { formatTimeRange, fullName } from '../common/utilities'
import './CourseDetail.scss'

export function CourseCertificates () {
  const { courseId } = useParams()
  const { data: course } = useGet(`/api/course/${courseId}`, true)
  const { data: settings } = useGet('/api/settings')

  if (
    course != null &&
    settings != null
  ) {
    return (
      <RadAppLayout
        name={'Course # ' + course.id}
        contentHeader={
          <RadHeader
            variant='h1'
            description={course.description}
          >
            {'Course # ' + course.id}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <Details course={course} settings={settings} />
            <Enrollments course={course} />
          </RadSpaceBetween>
        }
      />
    )
  }
}

function Details ({ course, settings }) {
  return (
    <RadContainer
      header={
        <RadHeader variant='h2'>
          Details
        </RadHeader>
      }
    >
      <RadSpaceBetween size='l'>
        <RadColumnLayout columns={3} borders='vertical'>
          <div>
            <RadBox variant='awsui-key-label' padding={{ bottom: 's' }}>
              Cohort
            </RadBox>
            <RadLink variant='primary' href={`/cohort/${course.cohort.id}`}>{course.cohort.name ?? '-'}</RadLink>
          </div>
          <div>
            <RadBox variant='awsui-key-label' padding={{ bottom: 'xxs' }}>
              Program
            </RadBox>
            <RadSpaceBetween direction='horizontal'>
              <Avatar
                src={course.program.photoUrl ?? '/program_icon.webp'}
                size='large'
                referrerPolicy='no-referrer'
              />
              <RadBox padding={{ left: 's', top: 'xs' }}>
                <RadLink variant='primary' href={`/program/${course.program.id}`}>{course.program.name}</RadLink>
              </RadBox>
            </RadSpaceBetween>
          </div>
          <div>
            <RadBox variant='awsui-key-label' padding={{ bottom: 'xxs' }}>
              Instructor(s)
            </RadBox>
            <RadSpaceBetween size='xxs'>
              {course.instructors.map(x =>
                <RadSpaceBetween key={'instructor-' + x.id} direction='horizontal'>
                  <Avatar
                    src={x.instructor.photoUrl}
                    size='large'
                    name={fullName(x.instructor)}
                    referrerPolicy='no-referrer'
                  />
                  <RadBox padding={{ left: 's', top: 'xs' }}>
                    <RadLink variant='primary' href={`/instructor/${x.instructor.id}`}>{fullName(x.instructor)}</RadLink>
                  </RadBox>
                </RadSpaceBetween>
              )}
            </RadSpaceBetween>
          </div>
        </RadColumnLayout>
        <RadColumnLayout columns={3} borders='vertical'>
          <div>
            <RadBox variant='awsui-key-label'>
              Campus / Room
            </RadBox>
            {course.campus.name} {course.room ? ` / ${course.room}` : ''}
          </div>
          <div>
            <RadBox variant='awsui-key-label'>
              Capacity
            </RadBox>
            {course.capacity}
          </div>
          <div>
            <RadBox variant='awsui-key-label'>
              Schedule
            </RadBox>
            <CourseSchedule course={course} />
            {course.schedules.map(x => (
              <div key={'session-' + x.id}>
                {x.weekdays.map(day => moment(day, 'ddd').format('dddd')).join(', ')}
                &nbsp;|&nbsp;
                {formatTimeRange(moment(x.startTime, 'HH:mm'), moment(x.endTime, 'HH:mm'))}
              </div>))}
          </div>
        </RadColumnLayout>
      </RadSpaceBetween>
    </RadContainer>
  )
}

function Enrollments ({ course }) {
  const navigate = useNavigate()
  const { courseId } = useParams()
  const [formValues, setFormValues] = useState({
    enrollments: course.enrollments
      .map(x => ({ id: x.id, person: x.person, certificates: x.certificates }))
  })
  const update = usePut(
    `/api/course/${courseId}/certificates`,
    {
      certificates: formValues.enrollments.reduce((acc, x) =>
        acc.concat(x.certificates.map(y => ({ id: y.id, courseEnrollmentId: x.id, certificateId: y.certificateId, date: y.date }))),
      [])
    },
    (resp) => { navigate(`/course/${courseId}`) }
  )

  async function cancel () {
    navigate(`/course/${courseId}`)
  }

  async function saveChanges () {
    update()
  }

  return (
    <RadForm
      actions={
        <RadSpaceBetween direction='horizontal' size='xs'>
          <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
          <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
        </RadSpaceBetween>
      }
    >
      <RadSpaceBetween size='l'>
        {formValues.enrollments.map(x => (
          <RadSpaceBetween size='l' key={'enrollment-' + x.id}>
            <Certificates formValues={formValues} setFormValues={setFormValues} enrollment={x} />
          </RadSpaceBetween>
        ))}
      </RadSpaceBetween>
    </RadForm>
  )
}

function Certificates ({ enrollment, formValues, setFormValues }) {
  const { data: certificateOptions } = useGet('/api/option/certificate')

  if (certificateOptions != null) {
    return (
      <RadContainer
        header={
          <RadHeader
            counter={'(' + enrollment.certificates.length + ')'}
            variant='h2'
          >
            {fullName(enrollment.person)}
          </RadHeader>
        }
      >
        <RadSpaceBetween size='l'>
          {enrollment.certificates.length > 0 &&
            <RadSpaceBetween size='xxs'>
              {enrollment.certificates.map((item) =>
                <CertificateEditor
                  key={`certificate-${item.id ?? item.uuid}`}
                  enrollment={enrollment}
                  item={item}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  certificateOptions={certificateOptions}
                />
              )}
            </RadSpaceBetween>}
          {enrollment.certificates.length === 0 &&
            <RadBox color='text-status-inactive'>No certificates added to enrollment.</RadBox>}
          <RadButton
            onClick={() => {
              const enrollments = formValues.enrollments
              enrollment.certificates.push({ uuid: uuidv4(), certificate: {} })
              setFormValues({ ...formValues, enrollments })
            }}
          >
            Add certificate
          </RadButton>
        </RadSpaceBetween>
      </RadContainer>
    )
  }
}

function CertificateEditor ({ enrollment, item, formValues, setFormValues, certificateOptions }) {
  const enrollments = formValues.enrollments

  return (
    <RadSpaceBetween size='l'>
      <RadGrid
        gridDefinition={[
          { colspan: { default: 12, xs: 4 } },
          { colspan: { default: 12, xs: 2 } },
          { colspan: { default: 12, xs: 6 } }
        ]}
      >
        <RadFormField
          label={item === enrollment.certificates[0] ? 'Name' : null}
          field={`certificate.${item.id ?? item.uuid}.certificateId`}
          required={item === enrollment.certificates[0]}
        >
          <RadSelect
            filteringType='auto'
            selectedOption={certificateOptions.find(x => parseInt(x.value) === item.certificateId)}
            onChange={({ detail }) => {
              item.certificateId = parseInt(detail.selectedOption.value)
              setFormValues({ ...formValues, enrollments })
            }}
            options={certificateOptions}
            enteredTextLabel={value => value}
            selectedAriaLabel='Selected'
            placeholder='Choose a certificate'
            empty='No matches found'
          />
        </RadFormField>
        <RadFormField
          label={item === enrollment.certificates[0] ? 'Date' : null}
          field={`certificate.${item.id ?? item.uuid}.date`}
          required={item === enrollment.certificates[0]}
        >
          <RadInput
            ariaRequired
            type='date'
            value={item.date}
            onChange={({ detail }) => {
              item.date = detail.value
              setFormValues({ ...formValues, enrollments })
            }}
          />
        </RadFormField>
        <RadBox
          padding={{ top: item === enrollment.certificates[0] ? 'xl' : 'xxs' }}
        >
          <RadButton
            wrapText={false}
            onClick={() => {
              const index = enrollment.certificates.indexOf(item)
              enrollment.certificates.splice(index, 1)
              setFormValues({ ...formValues, enrollments })
            }}
          >
            Remove certificate
          </RadButton>
        </RadBox>
      </RadGrid>
    </RadSpaceBetween>
  )
}
