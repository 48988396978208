import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useGet } from '../hooks/useGet'
import { useIsMobile } from '../hooks/useIsMobile'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadDivider } from '../common/RadDivider'
import { RadFormField } from '../common/RadFormField'
import { RadGrid } from '../common/RadGrid'
import { RadInput } from '../common/RadInput'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function PersonSchoolEnrollmentFormFields ({ formValues, setFormValues }) {
  const { data: gradeOptions } = useGet('/api/option/grade')

  if (
    gradeOptions != null
  ) {
    return (
      <RadSpaceBetween size='l'>
        {formValues.schoolEnrollments.map((x) =>
          <SchoolEditor
            key={`school-${x.id},${x.uuid}`}
            item={x}
            formValues={formValues}
            setFormValues={setFormValues}
            gradeOptions={gradeOptions}
          />)}
        <RadButton
          onClick={() => {
            const schoolEnrollments = formValues.schoolEnrollments
            schoolEnrollments.push({ uuid: uuidv4() })
            setFormValues({ ...formValues, schoolEnrollments })
          }}
        >
          Add new school enrollment
        </RadButton>
      </RadSpaceBetween>
    )
  }
}

function SchoolEditor ({ item, formValues, setFormValues, gradeOptions }) {
  const isMobile = useIsMobile()
  const [userFilterText, setUserFilterText] = useState('')
  const { data: schoolOptions } = useGet(`/api/option/school?search=${encodeURIComponent(userFilterText)}`)
  const schoolEnrollments = formValues.schoolEnrollments
  const yesNoNAOptions = [{ value: true, label: 'Yes' }, { value: false, label: 'No' }, { value: null, label: 'N/A' }]

  const removeButton = (
    <RadButton
      onClick={() => {
        const schoolEnrollments = formValues.schoolEnrollments
          .filter((x) => x.id !== item.id || x.uuid !== item.uuid)
        setFormValues({ ...formValues, schoolEnrollments })
      }}
    >
      Remove school enrollment
    </RadButton>
  )

  if (
    schoolOptions != null
  ) {
    return (
      <RadSpaceBetween size='l'>
        <RadGrid
          gridDefinition={[
            { colspan: { default: 12, xs: 3 } },
            { colspan: { default: 12, xs: 2 } },
            { colspan: { default: 12, xs: 2 } },
            { colspan: { default: 12, xs: 2 } },
            { colspan: { default: 12, xs: 3 } }
          ]}
        >
          <RadFormField label='School' field={`schoolEnrollment.${item.id ?? item.uuid}.schoolId`} required>
            <RadSelect
              filteringType='manual'
              selectedOption={
                item.schoolId
                  ? schoolOptions.find(x => x.value === item.schoolId?.toString()) ??
                  { value: item.school?.id ?? '', label: item.school.name ?? '-' }
                  : { value: '', label: '-' }
}
              onChange={({ detail }) => {
                if (detail.selectedOption.value !== '') {
                  item.schoolId = parseInt(detail.selectedOption.value)
                } else {
                  item.schoolId = null
                }
                setFormValues({ ...formValues, schoolEnrollments })
              }}
              options={schoolOptions}
              selectedAriaLabel='Selected'
              onLoadItems={({ detail }) => {
                setUserFilterText(detail.filteringText)
              }}
              empty='No matches found'
            />
          </RadFormField>
          <RadFormField label='Grade' field={`schoolEnrollment.${item.id ?? item.uuid}.gradeId`} required>
            <RadSelect
              selectedOption={item.gradeId ? gradeOptions.find(x => x.value === item.gradeId?.toString()) : { value: '', label: '-' }}
              onChange={({ detail }) => {
                if (detail.selectedOption.value !== '') {
                  item.gradeId = parseInt(detail.selectedOption.value)
                } else {
                  item.gradeId = null
                }
                setFormValues({ ...formValues, schoolEnrollments })
              }}
              options={gradeOptions}
              selectedAriaLabel='Selected'
              empty='No matches found'
            />
          </RadFormField>
          <RadFormField label='Start Date' field={`schoolEnrollment.${item.id ?? item.uuid}.startDate`} required>
            <RadInput
              type='date'
              placeholder='Enter start date'
              value={item.startDate}
              onChange={({ detail }) => {
                item.startDate = detail.value
                setFormValues({ ...formValues, schoolEnrollments })
              }}
            />
          </RadFormField>
          <RadFormField label='End Date' field={`schoolEnrollment.${item.id ?? item.uuid}.endDate`}>
            <RadInput
              type='date'
              placeholder='Enter end date'
              value={item.endDate}
              onChange={({ detail }) => {
                item.endDate = detail.value
                setFormValues({ ...formValues, schoolEnrollments })
              }}
            />
          </RadFormField>
          {!isMobile && <RadBox padding={{ top: 'xl' }} float='right'>{removeButton}</RadBox>}
        </RadGrid>
        <RadGrid
          gridDefinition={[
            { colspan: { default: 12, xs: 3 } },
            { colspan: { default: 12, xs: 3 } }
          ]}
        >
          <RadFormField label='Person ID' field={`schoolEnrollment.${item.id ?? item.uuid}.schoolPersonId`}>
            <RadInput
              placeholder='Enter person ID'
              value={item.schoolPersonId}
              onChange={({ detail }) => {
                item.schoolPersonId = detail.value
                setFormValues({ ...formValues, schoolEnrollments })
              }}
            />
          </RadFormField>
          <RadFormField label='Free / Reduced Lunch' field={`contact.${item.id ?? item.uuid}.freeReducedLunch`}>
            <RadSelect
              selectedOption={yesNoNAOptions.find(x => x.value === item.freeReducedLunch) ?? { value: '', label: '-' }}
              onChange={({ detail }) => {
                item.freeReducedLunch = detail.selectedOption.value
                setFormValues({ ...formValues, schoolEnrollments })
              }}
              options={yesNoNAOptions}
              selectedAriaLabel='Selected'
              empty='No matches found'
            />
          </RadFormField>
        </RadGrid>
        {isMobile && removeButton}
        {item !== schoolEnrollments[schoolEnrollments.length - 1] && <RadDivider />}
      </RadSpaceBetween>
    )
  }
}
