import React, { useContext } from 'react'
import { AppContext } from './../App'
import { useLocation } from 'react-router-dom'
import { useIsMobile } from '../hooks/useIsMobile'
import SideNavigation from '@cloudscape-design/components/side-navigation'

// https://cloudscape.design/components/side-navigation
export function RadSideNavigation ({ setNavigationOpen }) {
  const { setError } = useContext(AppContext)
  const location = useLocation()
  const isMobile = useIsMobile()
  const rootHref = () => {
    let path = location.pathname
    let last = path.split('/').pop()
    while (!isNaN(parseInt(last, 10)) || last === 'edit') {
      path = path.replace(`/${last}`, '')
      last = path.split('/').pop()
    }
    return path
  }
  const [activeHref, setActiveHref] = React.useState(rootHref)

  return (
    <SideNavigation
      activeHref={activeHref}
      header={{ href: '/', text: 'Case Management', logo: { alt: 'logo', src: '/logo.png' } }}
      onFollow={(event) => {
        if (isMobile) setNavigationOpen(false)
        setError(null) // Chipi - I might not need this
        setActiveHref(event.detail.href)
      }}
      items={[
        { type: 'link', text: 'Dashboard', href: '/' },
        { type: 'divider' },
        { type: 'link', text: 'Cohorts', href: '/cohort' },
        { type: 'link', text: 'Courses', href: '/course' },
        { type: 'link', text: 'Instructors', href: '/instructor' },
        { type: 'link', text: 'Programs', href: '/program' },
        { type: 'link', text: 'People', href: '/person' },
        { type: 'divider' },
        { type: 'link', text: 'Consecutive Absences', href: '/consecutive-absence' },
        { type: 'link', text: 'Critical Interactions', href: '/critical-interactions' },
        { type: 'link', text: 'Exits', href: '/exit' },
        { type: 'link', text: 'Student Reports', href: '/student-report' },
        // { type: 'link', text: 'MIO', href: '/mio' },
        { type: 'divider' },
        { type: 'link', text: 'Options', href: '/option' },
        { type: 'link', text: 'Roles', href: '/role' },
        { type: 'link', text: 'Schools', href: '/school' },
        { type: 'link', text: 'Settings', href: '/settings' },
        { type: 'link', text: 'Student Ratings', href: '/student-rating' },
        { type: 'link', text: 'Users', href: '/user' }
      ]}
    />
  )
}
