import React, { useRef, useState } from 'react'
import { useGet } from '../hooks/useGet'
import { RadAlert } from '../common/RadAlert'
import { RadButton } from '../common/RadButton'
import { RadCheckbox } from '../common/RadCheckbox'
import { RadFormField } from '../common/RadFormField'
import { RadGrid } from '../common/RadGrid'
import { RadInput } from '../common/RadInput'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTextarea } from '../common/RadTextarea'
import { toBase64 } from '../common/utilities'

export function PersonDetailFormFields ({ formValues, setFormValues, forCourseEnrollment }) {
  const inputRef = useRef(null)
  const [preview, setPreview] = useState()
  const [fileSizeTooLarge, setFileSizeTooLarge] = useState(false)

  const { data: languageOptions } = useGet('/api/option/language')
  const { data: pronounsOptions } = useGet('/api/option/pronouns')

  async function onFileChange (event) {
    setFileSizeTooLarge(false)
    const file = event.target.files[0]
    if (file != null && file.size > 10000000) {
      setFileSizeTooLarge(true)
    } else {
      setPreview(URL.createObjectURL(file))
      if (file != null) {
        const data = await toBase64(file)
        setFormValues({ ...formValues, file: file.name, fileSize: file.size, fileData: data })
      } else {
        removeFile()
      }
    }
    event.target.value = ''
  }

  function removeFile () {
    setFormValues({ ...formValues, file: null, fileSize: null, fileData: null, photoUrl: null })
    setPreview(null)
  }

  if (
    languageOptions != null &&
    pronounsOptions != null
  ) {
    return (
      <RadSpaceBetween size='l'>
        <input type='file' ref={inputRef} style={{ display: 'none' }} onChange={onFileChange} />
        {formValues.photoUrl == null && preview == null &&
          <div className='profile' onClick={() => inputRef.current.click()}><br /><br /><br />click to add image</div>}
        {formValues.photoUrl != null && preview == null &&
          <img src={formValues.photoUrl} alt='person profile' className='profile' onClick={() => inputRef.current.click()} />}
        {preview != null &&
          <img src={preview} alt='preview' className='profile' onClick={() => inputRef.current.click()} />}
        {(formValues.photoUrl != null || preview != null) &&
          <RadButton onClick={() => removeFile()}>Remove image</RadButton>}
        {fileSizeTooLarge &&
          <RadAlert
            dismissible
            statusIconAriaLabel='Error'
            type='error'
            header='File size too large'
            onDismiss={() => setFileSizeTooLarge(false)}
          >
            Please select a file that is less than 10MB.
          </RadAlert>}

        <RadGrid
          gridDefinition={[
            { colspan: { default: 12, s: 3 } },
            { colspan: { default: 12, s: 3 } },
            { colspan: { default: 12, s: 3 } },
            { colspan: { default: 12, s: 3 } }
          ]}
        >
          <RadFormField label='First Name' field='firstName' required>
            <RadInput
              ariaRequired
              placeholder='Enter first name'
              value={formValues.firstName}
              onChange={({ detail }) => setFormValues({ ...formValues, firstName: detail.value })}
            />
          </RadFormField>
          <RadFormField label='Middle Name' field='middleName'>
            <RadInput
              ariaRequired
              placeholder='Enter middle name'
              value={formValues.middleName}
              onChange={({ detail }) => setFormValues({ ...formValues, middleName: detail.value })}
            />
          </RadFormField>
          <RadFormField label='Last Name' field='lastName' required>
            <RadInput
              ariaRequired
              placeholder='Enter last name'
              value={formValues.lastName}
              onChange={({ detail }) => setFormValues({ ...formValues, lastName: detail.value })}
            />
          </RadFormField>
          <RadFormField label='Preferred Name' field='preferredName'>
            <RadInput
              placeholder='Enter preferred name'
              value={formValues.preferredName}
              onChange={({ detail }) => setFormValues({ ...formValues, preferredName: detail.value })}
            />
          </RadFormField>
        </RadGrid>

        <RadGrid
          gridDefinition={[
            { colspan: { default: 12, s: 3 } },
            { colspan: { default: 12, s: 3 } },
            { colspan: { default: 12, s: 3 } }
          ]}
        >
          <RadFormField label='Birth Date' field='birthdate' required={forCourseEnrollment}>
            <RadInput
              ariaRequired
              type='date'
              placeholder='Enter birth date'
              value={formValues.birthdate}
              onChange={({ detail }) => setFormValues({ ...formValues, birthdate: detail.value })}
            />
          </RadFormField>
          <RadFormField label='Pronouns' field='pronounsId'>
            <RadSelect
              selectedOption={formValues.pronounsId ? pronounsOptions.find(x => x.value === formValues.pronounsId?.toString()) : { value: '', label: '-' }}
              onChange={({ detail }) => {
                if (detail.selectedOption.value !== '') {
                  setFormValues({ ...formValues, pronounsId: parseInt(detail.selectedOption.value) })
                } else {
                  setFormValues({ ...formValues, pronounsId: null })
                }
              }}
              options={pronounsOptions}
              selectedAriaLabel='Selected'
              empty='No matches found'
            />
          </RadFormField>
          <RadFormField label='Primary Language' field='primaryLanguageId'>
            <RadSelect
              selectedOption={formValues.primaryLanguageId ? languageOptions.find(x => x.value === formValues.primaryLanguageId?.toString()) : { value: '', label: '-' }}
              onChange={({ detail }) => {
                if (detail.selectedOption.value !== '') {
                  setFormValues({ ...formValues, primaryLanguageId: parseInt(detail.selectedOption.value) })
                } else {
                  setFormValues({ ...formValues, primaryLanguageId: null })
                }
              }}
              options={languageOptions}
              selectedAriaLabel='Selected'
              empty='No matches found'
            />
          </RadFormField>
        </RadGrid>

        <RadGrid
          gridDefinition={[
            { colspan: { default: 12, s: 2 } },
            { colspan: { default: 12, s: 3 } }
          ]}
        >
          <RadFormField label='Media Release' field='mediaRelease'>
            <RadCheckbox
              checked={formValues.mediaRelease}
              onChange={({ detail }) => {
                if (detail.checked) {
                  setFormValues({ ...formValues, mediaRelease: detail.checked })
                } else {
                  setFormValues({ ...formValues, mediaRelease: detail.checked, mediaReleaseExpirationDate: null })
                }
              }}
            />
          </RadFormField>
          {formValues.mediaRelease === true &&
            <RadFormField label='Media Release Expiration Date' field='mediaReleaseExpirationDate'>
              <RadInput
                type='date'
                value={formValues.mediaReleaseExpirationDate}
                onChange={({ detail }) => setFormValues({ ...formValues, mediaReleaseExpirationDate: detail.value })}
              />
            </RadFormField>}
          {formValues.mediaRelease === false && <div />}
        </RadGrid>
        <RadFormField label='Comments' field='comments' stretch>
          <RadTextarea
            value={formValues.comments}
            onChange={({ detail }) => setFormValues({ ...formValues, comments: detail.value })}
          />
        </RadFormField>
      </RadSpaceBetween>
    )
  }
}
