import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toTitleCase } from '../common/utilities'
import { v4 as uuidv4 } from 'uuid'
import { useGet } from '../hooks/useGet'
import { usePost } from '../hooks/usePost'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadForm } from '../common/RadForm'
import { RadContainer } from '../common/RadContainer'
import { RadHeader } from '../common/RadHeader'
import { RadFormField } from '../common/RadFormField'
import { RadAttributeEditor } from '../common/RadAttributeEditor'
import { RadButton } from '../common/RadButton'
import { RadInput } from '../common/RadInput'

export function OptionEdit () {
  const navigate = useNavigate()
  const { entity } = useParams()
  const { data: options } = useGet('/api/option')
  const [formValues, setFormValues] = useState(options)
  const create = usePost('/api/option', formValues, (resp) => { navigate('/option') })

  useEffect(() => {
    if (options != null && entity != null) {
      const filteredOptions = options
        .filter((x) => x.entity === entity)
        .map((x) => ({ id: x.id, name: x.name }))
      setFormValues({ entity, options: filteredOptions })
    }
  }, [options, entity])

  async function cancel () {
    navigate('/option')
  }

  async function saveChanges () {
    create()
  }

  if (formValues != null) {
    return (
      <RadAppLayout
        contentHeader={
          <RadHeader variant='h1'>Edit {toTitleCase(entity.replaceAll('_', ' '))} Options</RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadContainer>
                <RadSpaceBetween size='l'>
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const temp = formValues.options
                      temp.push({ uuid: uuidv4() })
                      setFormValues({ ...formValues, options: temp })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const temp = [...formValues.options]
                      temp.splice(itemIndex, 1)
                      setFormValues({ ...formValues, options: temp })
                    }}
                    items={formValues.options}
                    addButtonText='Add option'
                    definition={[
                      {
                        label: 'Name *',
                        control: (item) =>
                          <RadFormField field={`option.${item.id ?? item.uuid}.name`}>
                            <RadInput
                              ariaRequired
                              value={item.name}
                              placeholder='Enter name'
                              onChange={({ detail }) => {
                                const temp = formValues.options
                                const type = formValues.options.find((x) => (x.uuid === (item.uuid ?? '')) || (x.id === (item.id ?? '')))
                                type.name = detail.value
                                setFormValues({ ...formValues, options: temp })
                              }}
                            />
                          </RadFormField>
                      }
                    ]}
                    removeButtonText='Remove option'
                  />
                </RadSpaceBetween>
              </RadContainer>
            </RadForm>
          </form>
        }
      />
    )
  }
}
