import React, { useState, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadButton } from '../common/RadButton'
import { RadContainer } from '../common/RadContainer'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTextarea } from '../common/RadTextarea'

export function CohortEdit () {
  const navigate = useNavigate()
  const { cohortId } = useParams()
  const [searchParams] = useSearchParams()
  const [formValues, setFormValues] = useState()
  const { data: cohort } = useGet(cohortId ? `/api/cohort/${cohortId}` : null)
  const create = usePost(
    '/api/cohort',
    formValues,
    (resp) => { navigate(searchParams.get('redirectURL') ?? `/cohort/${resp.id}`) }
  )
  const update = usePut(
    `/api/cohort/${cohortId}`,
    formValues, (resp) => { navigate(searchParams.get('redirectURL') ?? `/cohort/${cohortId}`) }
  )

  useEffect(() => {
    const defaultFormValues = { courses: [], year: new Date().getFullYear().toString() }
    setFormValues(cohort ?? defaultFormValues)
  }, [cohort])

  async function cancel () {
    if (cohortId != null) {
      navigate(searchParams.get('redirectURL') ?? `/cohort/${cohortId}`)
    } else {
      navigate(searchParams.get('redirectURL') ?? '/cohort')
    }
  }

  async function saveChanges () {
    if (cohortId != null) {
      update()
    } else {
      create()
    }
  }

  if (
    formValues != null
  ) {
    return (
      <RadAppLayout
        name={formValues.name}
        contentHeader={
          <RadHeader variant='h1'>{cohortId != null ? 'Edit' : 'New'} Cohort</RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>
                    {cohortId != null ? 'Save Changes' : 'Add cohort'}
                  </RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Details
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='Name' field='name' required>
                      <RadInput
                        ariaRequired
                        placeholder='Enter name'
                        value={formValues.name}
                        onChange={({ detail }) => setFormValues({ ...formValues, name: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Description' field='description' stretch>
                      <RadTextarea
                        rows={4}
                        placeholder='Enter description'
                        value={formValues.description}
                        onChange={({ detail }) => setFormValues({ ...formValues, description: detail.value })}
                      />
                    </RadFormField>
                    <RadGrid
                      gridDefinition={[
                        { colspan: { default: 12, xs: 3, s: 2 } }
                      ]}
                    >
                      <RadFormField label='Year' field='year' required>
                        <RadInput
                          ariaRequired
                          type='number'
                          value={formValues.year}
                          onChange={({ detail }) => setFormValues({ ...formValues, year: detail.value })}
                        />
                      </RadFormField>
                    </RadGrid>
                    <RadGrid
                      gridDefinition={[
                        { colspan: { default: 12, xs: 3, s: 2 } },
                        { colspan: { default: 12, xs: 3, s: 2 } }
                      ]}
                    >
                      <RadFormField label='Start Date' field='startDate' required>
                        <RadInput
                          ariaRequired
                          type='date'
                          value={formValues.startDate}
                          onChange={({ detail }) => setFormValues({ ...formValues, startDate: detail.value })}
                        />
                      </RadFormField>
                      <RadFormField label='End Date' field='endDate' required>
                        <RadInput
                          ariaRequired
                          type='date'
                          value={formValues.endDate}
                          onChange={({ detail }) => setFormValues({ ...formValues, endDate: detail.value })}
                        />
                      </RadFormField>
                    </RadGrid>
                  </RadSpaceBetween>
                </RadContainer>
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}
