import { useState, useEffect } from 'react'

export function useIsMobile () {
  const [matches, setMatches] = useState(false)

  useEffect(() => {
    const media = window.matchMedia('(max-width: 794px)')
    if (media.matches !== matches) {
      setMatches(media.matches)
    }
    const listener = () => {
      setMatches(media.matches)
    }
    media.addListener(listener)
    return () => media.removeListener(listener)
  }, [matches])

  return matches
}
