import React, { useContext, useState, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { AppContext } from '../App'
import { useGet } from '../hooks/useGet'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadButton } from '../common/RadButton'
import { RadContainer } from '../common/RadContainer'
import { RadForm } from '../common/RadForm'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { PersonDetailFormFields } from './PersonDetailFormFields'
import { PersonContactInformationFormFields } from './PersonContactInformationFormFields'
import { PersonDemographicsFormFields } from './PersonDemographicsFormFields'
import { PersonHealthInformationFormFields } from './PersonHealthInformationFormFields'
import { PersonContactsFormFields } from './PersonContactsFormFields'
import { PersonCriticalInteractionFormFields } from './PersonCriticalInteractionFormFields'
import { PersonSchoolEnrollmentFormFields } from './PersonSchoolEnrollmentFormFields'
import { PersonTagsFormFields } from './PersonTagsFormFields'

export function PersonEdit ({ forCourseEnrollment }) {
  const { setError } = useContext(AppContext)
  const navigate = useNavigate()
  const { personId } = useParams()
  const [searchParams] = useSearchParams()
  const [formValues, setFormValues] = useState()
  const { data: person } = useGet(personId ? `/api/person/${personId}` : null)
  const create = usePost(
    '/api/person',
    formValues,
    (resp) => { navigate(searchParams.get('redirectURL') ?? `/person/${resp.id}`) }
  )
  const update = usePut(
    `/api/person/${personId}`,
    formValues,
    (resp) => { navigate(searchParams.get('redirectURL') ?? `/person/${personId}`) }
  )

  useEffect(() => {
    setError(null)
    const defaultFormValues = {
      address: {},
      allergies: [],
      contacts: [],
      criticalInteractions: [],
      dietaryNeeds: [],
      disabilities: [],
      mediaRelease: false,
      schoolEnrollments: [],
      smsPermission: false,
      tags: [],
      usCitizenOrPermanentResident: false
    }
    setFormValues(person ?? defaultFormValues)
  }, [person])

  async function cancel () {
    if (personId != null) {
      navigate(searchParams.get('redirectURL') ?? `/person/${personId}`)
    } else {
      navigate(searchParams.get('redirectURL') ?? '/person')
    }
  }

  async function saveChanges () {
    if (personId != null) {
      update()
    } else {
      create()
    }
  }

  if (
    formValues != null
  ) {
    return (
      <RadAppLayout
        name={formValues.fullName}
        contentHeader={
          <RadHeader variant='h1'>{personId ? 'Edit' : 'New'} Person</RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <Details formValues={formValues} setFormValues={setFormValues} />
                <ContactInformation formValues={formValues} setFormValues={setFormValues} />
                <Contacts formValues={formValues} setFormValues={setFormValues} />
                <CriticalInteractions formValues={formValues} setFormValues={setFormValues} />
                <Demographics formValues={formValues} setFormValues={setFormValues} />
                <HealthInformation formValues={formValues} setFormValues={setFormValues} />
                <SchoolEnrollments formValues={formValues} setFormValues={setFormValues} />
                <Tags formValues={formValues} setFormValues={setFormValues} />
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}

function Details ({ formValues, setFormValues, forCourseEnrollment }) {
  return (
    <RadContainer
      header={
        <RadHeader variant='h2'>
          Details
        </RadHeader>
    }
    >
      <PersonDetailFormFields
        formValues={formValues}
        setFormValues={setFormValues}
        forCourseEnrollment={forCourseEnrollment}
      />
    </RadContainer>
  )
}

function ContactInformation ({ formValues, setFormValues }) {
  return (
    <RadContainer
      header={
        <RadHeader variant='h2'>
          Contact Information
        </RadHeader>
    }
    >
      <PersonContactInformationFormFields
        formValues={formValues}
        setFormValues={setFormValues}
      />
    </RadContainer>
  )
}

function Demographics ({ formValues, setFormValues, forCourseEnrollment }) {
  return (
    <RadContainer
      header={
        <RadHeader variant='h2'>
          Demographics
        </RadHeader>
    }
    >
      <PersonDemographicsFormFields
        formValues={formValues}
        setFormValues={setFormValues}
        forCourseEnrollment={forCourseEnrollment}
      />
    </RadContainer>
  )
}

function HealthInformation ({ formValues, setFormValues }) {
  return (
    <RadContainer
      header={
        <RadHeader variant='h2'>
          Health Information
        </RadHeader>
    }
    >
      <PersonHealthInformationFormFields formValues={formValues} setFormValues={setFormValues} />
    </RadContainer>
  )
}

function Contacts ({ formValues, setFormValues }) {
  return (
    <RadContainer
      header={
        <RadHeader
          variant='h2'
          counter={'(' + formValues.contacts.length + ')'}
        >
          Contacts
        </RadHeader>
    }
    >
      <PersonContactsFormFields formValues={formValues} setFormValues={setFormValues} />
    </RadContainer>
  )
}

function SchoolEnrollments ({ formValues, setFormValues }) {
  return (
    <RadContainer
      header={
        <RadHeader
          variant='h2'
          counter={'(' + formValues.schoolEnrollments.length + ')'}
          description='End Date is required on all but the current enrollment.'
        >
          School Enrollments
        </RadHeader>
    }
    >
      <PersonSchoolEnrollmentFormFields formValues={formValues} setFormValues={setFormValues} />
    </RadContainer>
  )
}

function CriticalInteractions ({ formValues, setFormValues }) {
  return (
    <RadContainer
      header={
        <RadHeader
          variant='h2'
          counter={'(' + formValues.criticalInteractions.length + ')'}
        >
          Critical Interactions
        </RadHeader>
    }
    >
      <PersonCriticalInteractionFormFields formValues={formValues} setFormValues={setFormValues} />
    </RadContainer>
  )
}

function Tags ({ formValues, setFormValues }) {
  return (
    <RadContainer
      header={
        <RadHeader
          variant='h2'
          counter={'(' + formValues.tags.length + ')'}
        >
          Tags
        </RadHeader>
    }
    >
      <PersonTagsFormFields formValues={formValues} setFormValues={setFormValues} />
    </RadContainer>
  )
}
