import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import moment from 'moment'
import { useGet } from '../hooks/useGet'
import { Avatar } from '@rmwc/avatar'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadCards } from '../common/RadCards'
import { RadHeader } from '../common/RadHeader'
import { RadLink } from '../common/RadLink'
import { RadPagination } from '../common/RadPagination'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTextFilter } from '../common/RadTextFilter'
import { CourseSchedule } from '../shared/CourseSchedule'
import { fullName } from '../common/utilities'

export function ConsecutiveAbsenceList () {
  moment.tz.setDefault('America/Chicago')
  const pageLength = 20
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentPageIndex, setCurrentPageIndex] =
    useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const [filteringText, setFilteringText] = useState(searchParams.get('search') ?? '')
  const [searchText, setSearchText] = useState(searchParams.get('search') ?? '')
  const { data: courseEnrollments, count } = useGet(
    '/api/course-enrollment/consecutive-absence' +
    `?search=${searchText}` +
    `&limit=${pageLength}` +
    `&offset=${(currentPageIndex - 1) * pageLength}`
  )
  const { data: settings } = useGet('/api/settings')

  function escapeRegExp (string) {
    if (string == null) return null
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
  }

  const highlightMatch = (text) => {
    if (text == null || searchText == null) return null
    const escapedSearchText = escapeRegExp(searchText)
    const parts = text.split(new RegExp(`(${escapedSearchText})`, 'gi'))
    return (
      <span>{parts.map((part, i) =>
        part.toLowerCase() === searchText.toLowerCase()
          ? <span key={i} className='highlight'>{part}</span>
          : part
      )}
      </span>
    )
  }

  if (
    courseEnrollments != null &&
    settings != null
  ) {
    return (
      <RadAppLayout
        contentHeader={
          <RadHeader
            variant='h1'
            counter={'(' + Number(count).toLocaleString() + ')'}
            description={`List of students with consecutive absences over ${settings.consecutiveAbsenceThreshold} days`}
          >
            Consecutive Absences
          </RadHeader>
        }
        content={
          <RadCards
            ariaLabels={{
              itemSelectionLabel: (e, t) => `select ${t.name}`,
              selectionGroupLabel: 'Item selection'
            }}
            cardDefinition={{
              header: item => (
                <RadLink fontSize='heading-m' href={`/person/${item.person.id}`}>
                  <RadSpaceBetween size='xs'>
                    <Avatar
                      src={item.photoUrl}
                      size='large'
                      name={`${item.firstName} ${item.lastName}`}
                      referrerPolicy='no-referrer'
                    />
                    {highlightMatch(fullName(item.person))}
                  </RadSpaceBetween>
                </RadLink>
              ),
              sections: [
                {
                  id: 'info',
                  content: item =>
                    <RadSpaceBetween size='xs'>
                      <RadSpaceBetween direction='horizontal' size='xs'>
                        <RadBox variant='awsui-key-label'>Cohort</RadBox>
                        <RadLink href={`/cohort/${item.cohort.id}`}>{item.cohort.name}</RadLink>
                      </RadSpaceBetween>
                      <RadSpaceBetween direction='horizontal' size='xs'>
                        <RadBox variant='awsui-key-label'>Program</RadBox>
                        <RadLink href={`/program/${item.program.id}`}>{item.program.name}</RadLink>
                      </RadSpaceBetween>
                      <RadSpaceBetween direction='horizontal' size='xs'>
                        <RadBox variant='awsui-key-label'>Instructor(s)</RadBox>
                        {item.instructors.map(x =>
                          <RadLink key={item.id + 'instructor-' + x.id} href={`/instructor/${x.id}`}>{fullName(x)}</RadLink>
                        )}
                      </RadSpaceBetween>
                      <RadSpaceBetween direction='horizontal' size='xs'>
                        <RadBox variant='awsui-key-label'>Location</RadBox>
                        <div>{item.campus.name} {item.room != null ? ` - Room ${item.room}` : ''}</div>
                      </RadSpaceBetween>
                      <RadSpaceBetween direction='horizontal' size='xs'>
                        <RadBox variant='awsui-key-label'>Schedule</RadBox>
                        <CourseSchedule course={item} />
                      </RadSpaceBetween>
                      <RadSpaceBetween direction='horizontal' size='xs'>
                        <RadBox variant='awsui-key-label'>Consecutive Absences</RadBox>
                        <div>{item.consecutiveAbsences}</div>
                      </RadSpaceBetween>
                    </RadSpaceBetween>
                }
              ]
            }}
            cardsPerRow={[
              { cards: 1 },
              { minWidth: 640, cards: 2 },
              { minWidth: 960, cards: 3 },
              { minWidth: 1280, cards: 4 }
            ]}
            items={courseEnrollments}
            filter={
              <RadTextFilter
                filteringText={filteringText}
                filteringPlaceholder='Search'
                filteringAriaLabel='Filter consecutive absences by name'
                onChange={({ detail }) => setFilteringText(detail.filteringText)}
                onDelayedChange={({ detail }) => {
                  setSearchText(detail.filteringText ?? '')
                  if (detail.filteringText) {
                    searchParams.set('search', detail.filteringText)
                  } else {
                    searchParams.delete('search')
                  }
                  searchParams.delete('page')
                  setCurrentPageIndex(1)
                  setSearchParams(searchParams)
                }}
              />
            }
            pagination={
              <RadPagination
                currentPageIndex={currentPageIndex}
                pagesCount={Math.ceil(count / pageLength)}
                onChange={({ detail }) => {
                  console.log(detail)
                  searchParams.set('page', detail.currentPageIndex)
                  setSearchParams(searchParams)
                  setCurrentPageIndex(detail.currentPageIndex)
                }}
                ariaLabels={{
                  nextPageLabel: 'Next page',
                  previousPageLabel: 'Previous page',
                  pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                }}
              />
            }
            variant='full-page'
            empty={
              <RadBox textAlign='center' color='inherit'>
                No matches found
              </RadBox>
            }
          />
        }
      />
    )
  }
}
