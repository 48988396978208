import React from 'react'
import { useGet } from '../hooks/useGet'
import { RadBox } from '../common/RadBox'
import { RadCheckbox } from '../common/RadCheckbox'
import { RadFormField } from '../common/RadFormField'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function PersonTagsFormFields ({ formValues, setFormValues }) {
  const { data: tagOptions } = useGet('/api/option/tag')

  if (
    tagOptions != null
  ) {
    return (
      <RadSpaceBetween size='l'>
        <RadFormField field='tags' stretch>
          <RadSpaceBetween direction='horizontal' size='xxs'>
            {tagOptions.map((x) =>
              <RadBox key={x.value} padding={{ right: 'l' }}>
                <RadBox padding={{ bottom: 'xs' }}>
                  <RadCheckbox
                    checked={formValues.tags.some((y) => y.tagId.toString() === x.value)}
                    onChange={({ detail }) => {
                      if (detail.checked) {
                        setFormValues({ ...formValues, tags: [...(formValues.tags ?? []), { tagId: parseInt(x.value) }] })
                      } else {
                        setFormValues({ ...formValues, tags: (formValues.tags ?? []).filter((y) => y.tagId.toString() !== x.value) })
                      }
                    }}
                  >
                    {x.label}
                  </RadCheckbox>
                </RadBox>
              </RadBox>
            )}
          </RadSpaceBetween>
        </RadFormField>
      </RadSpaceBetween>
    )
  }
}
