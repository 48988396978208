import React from 'react'
import { RadBox } from '../common/RadBox'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function AttendancePercentage ({ item }) {
  if (item.attendance == null) return '-'
  const percentage = item.attendance.total === 0
    ? null
    : (item.attendance.present / item.attendance.total * 100).toFixed(0)
  const color = percentage == null
    ? 'inherit'
    : percentage < 70
      ? 'text-status-error'
      : 'text-status-success'
  return (
    <RadSpaceBetween direction='horizontal' size='s'>
      <RadBox variant='awsui-key-label'>Attendance</RadBox>
      <RadBox color={color}>{percentage == null ? '-' : `${percentage} %`}</RadBox>
    </RadSpaceBetween>
  )
}
