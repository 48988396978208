import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { Avatar } from '@rmwc/avatar'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadCards } from '../common/RadCards'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadLink } from '../common/RadLink'
import { RadPagination } from '../common/RadPagination'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { fullName } from '../common/utilities'

export function InstructorList () {
  const pageLength = 20
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentPageIndex, setCurrentPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const [searchText, setSearchText] = useState(searchParams.get('search') ?? '')
  const { data: instructors, count, info } = useGet(`/api/instructor?search=${searchText}&limit=${pageLength}&offset=${(currentPageIndex - 1) * pageLength}`)

  function escapeRegExp (string) {
    if (string == null) return null
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
  }

  const highlightMatch = (text) => {
    if (text == null || searchText == null) return null
    const escapedSearchText = escapeRegExp(searchText)
    const parts = text.split(new RegExp(`(${escapedSearchText})`, 'gi'))
    return (
      <span>{parts.map((part, i) =>
        part.toLowerCase() === searchText.toLowerCase()
          ? <span key={i} className='highlight'>{part}</span>
          : part
      )}
      </span>
    )
  }

  if (
    instructors != null
  ) {
    return (
      <RadAppLayout
        contentHeader={
          <RadHeader
            variant='h1'
            counter={'(' + count + ')'}
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('/instructor/create')}>Create</RadButton>
              </RadSpaceBetween>
            }
          >
            Instructors
          </RadHeader>
        }
        content={
          <RadCards
            ariaLabels={{
              itemSelectionLabel: (e, t) => `select ${t.name}`,
              selectionGroupLabel: 'Item selection'
            }}
            cardDefinition={{
              header: item => (
                <RadLink fontSize='heading-m' href={`/instructor/${item.id}`}>
                  <RadSpaceBetween size='xs'>
                    <Avatar
                      src={item.photoUrl}
                      size='large'
                      name={`${item.firstName} ${item.lastName}`}
                      referrerPolicy='no-referrer'
                    />
                    {highlightMatch(fullName(item))}
                  </RadSpaceBetween>
                </RadLink>
              ),
              sections: [
                {
                  id: 'info',
                  content: item =>
                    <RadSpaceBetween size='xs'>
                      {item.email != null && <RadLink href={`mailto:${item.email}`}>{highlightMatch(item.email)}</RadLink>}
                      {item.phone != null && <div>{highlightMatch(item.phone)}</div>}
                    </RadSpaceBetween>
                }
              ]
            }}
            cardsPerRow={[
              { cards: 1 },
              { minWidth: 640, cards: 2 },
              { minWidth: 960, cards: 3 },
              { minWidth: 1280, cards: 4 }
            ]}
            items={instructors}
            filter={
              <RadGrid
                gridDefinition={[
                  { colspan: { default: 12, xs: 9 } },
                  { colspan: { default: 12, xs: 3 } }
                ]}
              >
                <RadInput
                  value={searchText}
                  placeholder='Search'
                  type='search'
                  onChange={({ detail }) => {
                    setSearchText(detail.value ?? '')
                    if (detail.value) {
                      searchParams.set('search', detail.value)
                    } else {
                      searchParams.delete('search')
                    }
                    searchParams.delete('page')
                    setCurrentPageIndex(1)
                    setSearchParams(searchParams)
                  }}
                />
              </RadGrid>
            }
            pagination={
              <RadPagination
                currentPageIndex={currentPageIndex}
                pagesCount={Math.ceil(count / pageLength)}
                onChange={({ detail }) => {
                  searchParams.set('page', detail.currentPageIndex)
                  setSearchParams(searchParams)
                  setCurrentPageIndex(detail.currentPageIndex)
                }}
                ariaLabels={{
                  nextPageLabel: 'Next page',
                  previousPageLabel: 'Previous page',
                  pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                }}
              />
            }
            variant='full-page'
            empty={
              <RadBox textAlign='center' color='inherit'>
                {info ?? 'No matches found'}
              </RadBox>
            }
          />
        }
      />
    )
  }
}
