import React from 'react'
import { RadHeader } from '../common/RadHeader'

export function PhotoUrlHeader ({ photoUrl }) {
  if (photoUrl == null) return null
  return (
    <RadHeader variant='h2'>
      {photoUrl &&
        <img
          className='profile'
          style={{ display: 'block' }}
          src={photoUrl}
          alt='icon'
          referrerPolicy='no-referrer'
        />}
    </RadHeader>
  )
}
