import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import * as firebaseui from 'firebaseui'

const firebaseConfig = {
  apiKey: 'AIzaSyC8nL01lpk4PpgwTTvkuEx4bVUerHD7jM8',
  authDomain: 'ucg-rad.firebaseapp.com',
  projectId: 'ucg-rad'
}

firebase.initializeApp(firebaseConfig)
const auth = firebase.auth()
const ui = new firebaseui.auth.AuthUI(auth)

export { firebase, auth, ui }
