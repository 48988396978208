import React, { useContext } from 'react'
import FormField from '@cloudscape-design/components/form-field'
import { AppContext } from './../App'

// https://cloudscape.design/components/form-field
export function RadFormField ({ field, label, required, screenReader, ...rest }) {
  const { error } = useContext(AppContext)
  const errorText = field != null && error != null && error.validationErrors != null ? error.validationErrors[field] : null

  const classes = []
  if (screenReader) {
    classes.push('screen-reader-only')
  }
  if (required) {
    classes.push('required')
  }

  return (
    <FormField
      errorText={errorText != null ? errorText : null}
      label={classes.length > 0 ? <span className={classes.join(' ')}>{label}</span> : <span>{label}</span>}
      {...{ ...rest }}
    />
  )
}
