import React, { useState, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadButton } from '../common/RadButton'
import { RadContainer } from '../common/RadContainer'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function SchoolEdit () {
  const navigate = useNavigate()
  const { schoolId } = useParams()
  const [searchParams] = useSearchParams()
  const [formValues, setFormValues] = useState()
  const { data: school } = useGet(schoolId ? `/api/school/${schoolId}` : null)
  const create = usePost('/api/school', formValues, (resp) => { navigate(searchParams.get('redirectURL') ?? `/school/${resp.id}`) })
  const update = usePut(`/api/school/${schoolId}`, formValues, (resp) => { navigate(searchParams.get('redirectURL') ?? `/school/${schoolId}`) })

  useEffect(() => {
    const defaultFormValues = { }
    setFormValues(school ?? defaultFormValues)
  }, [school])

  async function cancel () {
    if (schoolId != null) {
      navigate(searchParams.get('redirectURL') ?? `/school/${schoolId}`)
    } else {
      navigate(searchParams.get('redirectURL') ?? '/school')
    }
  }

  async function saveChanges () {
    if (schoolId != null) {
      update()
    } else {
      create()
    }
  }

  if (
    formValues != null
  ) {
    return (
      <RadAppLayout
        name={formValues.fullName}
        contentHeader={
          <RadHeader variant='h1'>{schoolId ? 'Edit' : 'New'} School</RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Details
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='Name' field='name' required>
                      <RadInput
                        ariaRequired
                        placeholder='Enter name'
                        value={formValues.name}
                        onChange={({ detail }) => setFormValues({ ...formValues, name: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='County' field='county' description='This is only required if there are multiple schools with the same name.'>
                      <RadInput
                        placeholder='Enter county'
                        value={formValues.county}
                        onChange={({ detail }) => setFormValues({ ...formValues, county: detail.value })}
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}
