import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useGet } from '../hooks/useGet'
import { useIsMobile } from '../hooks/useIsMobile'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadDivider } from '../common/RadDivider'
import { RadFormField } from '../common/RadFormField'
import { RadGrid } from '../common/RadGrid'
import { RadInput } from '../common/RadInput'
import { RadTextarea } from '../common/RadTextarea'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function PersonCriticalInteractionFormFields ({ formValues, setFormValues }) {
  const { data: relationshipOptions } = useGet('/api/option/relationship')

  if (
    relationshipOptions != null
  ) {
    return (
      <RadSpaceBetween size='l'>
        {formValues.criticalInteractions.map((x) =>
          <CriticalInteractionEditor
            key={`criticalInteraction-${x.id},${x.uuid}`}
            item={x}
            formValues={formValues}
            setFormValues={setFormValues}
            relationshipOptions={relationshipOptions}
          />)}
        <RadButton
          onClick={() => {
            const criticalInteractions = formValues.criticalInteractions
            criticalInteractions.push({ uuid: uuidv4() })
            setFormValues({ ...formValues, criticalInteractions })
          }}
        >
          Add new critical interaction
        </RadButton>
      </RadSpaceBetween>
    )
  }
}

function CriticalInteractionEditor ({ item, formValues, setFormValues, relationshipOptions }) {
  const isMobile = useIsMobile()
  const criticalInteractions = formValues.criticalInteractions

  const removeButton = (
    <RadButton
      onClick={() => {
        const criticalInteractions = formValues.criticalInteractions
          .filter((x) => x.id !== item.id || x.uuid !== item.uuid)
        setFormValues({ ...formValues, criticalInteractions })
      }}
    >
      Remove critical interaction
    </RadButton>
  )

  return (
    <RadSpaceBetween size='l'>
      <RadGrid
        gridDefinition={[
          { colspan: { default: 12, s: 2 } },
          { colspan: { default: 12, s: 7 } },
          { colspan: { default: 12, s: 3 } }
        ]}
      >
        <RadSpaceBetween size='l'>
          <RadFormField label='Date' field={`criticalInteraction.${item.id ?? item.uuid}.date`} required>
            <RadInput
              ariaRequired
              type='date'
              placeholder='Enter date'
              value={item.date}
              onChange={({ detail }) => {
                item.date = detail.value
                setFormValues({ ...formValues, criticalInteractions })
              }}
            />
          </RadFormField>
          <RadFormField label='Time' field={`criticalInteraction.${item.id ?? item.uuid}.time`}>
            <RadInput
              type='time'
              placeholder='Enter time'
              value={item.time}
              onChange={({ detail }) => {
                item.time = detail.value
                setFormValues({ ...formValues, criticalInteractions })
              }}
            />
          </RadFormField>
        </RadSpaceBetween>
        <RadFormField label='Description' field={`criticalInteraction.${item.id ?? item.uuid}.description`} required stretch>
          <RadTextarea
            rows={6}
            placeholder='Enter description'
            value={item.description}
            onChange={({ detail }) => {
              item.description = detail.value
              setFormValues({ ...formValues, criticalInteractions })
            }}
          />
        </RadFormField>
        {!isMobile && <RadBox padding={{ top: 'xl' }} float='right'>{removeButton}</RadBox>}
      </RadGrid>
      <RadDivider />
      {isMobile && removeButton}
    </RadSpaceBetween>
  )
}
