import React, { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Avatar } from '@rmwc/avatar'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadCards } from '../common/RadCards'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadDivider } from '../common/RadDivider'
import { RadFormField } from '../common/RadFormField'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadLink } from '../common/RadLink'
import { RadPagination } from '../common/RadPagination'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { AttendancePieChart } from '../charts/AttendancePieChart'
import { CourseCards } from '../cards/CourseCards'
import { EthnicitiesPieChart } from '../charts/EthnicitiesPieChart'
import { GenderPieChart } from '../charts/GenderPieChart'
import { RacePieChart } from '../charts/RacePieChart'
import { SessionAttendanceBarChart } from '../charts/SessionAttendanceBarChart'
import { AttendancePercentage } from '../shared/AttendancePercentage'
import { formatDateRange, fullName } from '../common/utilities'
import { UsCitizenOrPermanentResidentPieChart } from '../charts/UsCitizenOrPermanentResidentPieChart'
import { FirstGenerationCollegeStudentPieChart } from '../charts/FirstGenerationCollegeStudentPieChart'

export function CohortDetail () {
  const navigate = useNavigate()
  const { cohortId } = useParams()
  const { data: cohort } = useGet(`/api/cohort/${cohortId}?attendance=true&demographics=true`, true)
  const confirmDelete = useConfirm(
    'Delete cohort?',
    'Delete cohort permanently? This action cannot be undone.',
    'Delete', () => { remove() }
  )
  const remove = useDelete(`/api/cohort/${cohortId}`, () => { navigate('/cohort') })

  if (
    cohort != null
  ) {
    return (
      <RadAppLayout
        name={cohort.name}
        contentHeader={
          <RadHeader
            variant='h1'
            description={cohort.description}
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('edit')}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={cohort.isProtected}>Delete</RadButton>
              </RadSpaceBetween>
            }
          >
            {cohort.name} ({formatDateRange(cohort.startDate, cohort.endDate)})
          </RadHeader>
        }
        content={
          <div style={{ paddingTop: '50px' }}>
            <RadSpaceBetween size='l'>
              <DemographicsAndAttendance entity={cohort} />
              <CourseCards courses={cohort.courses} />
              <RadButton
                onClick={() => {
                  navigate(`/course/create?cohortId=${cohort.id}`)
                }}
              >
                Add new course
              </RadButton>
              <People courses={cohort.courses} />
            </RadSpaceBetween>
          </div>
        }
      />
    )
  }
}

function DemographicsAndAttendance ({ entity }) {
  return (
    <RadSpaceBetween size='l'>
      <RadColumnLayout columns={3}>
        <GenderPieChart genders={entity.demographics.genders} />
        <RacePieChart races={entity.demographics.races} />
        <EthnicitiesPieChart ethnicities={entity.demographics.ethnicities} />
        <UsCitizenOrPermanentResidentPieChart
          usCitizenOrPermanentResidents={entity.demographics.usCitizenOrPermanentResidents}
        />
        <FirstGenerationCollegeStudentPieChart
          firstGenerationCollegePeople={entity.demographics.firstGenerationCollegePeople}
        />
        <AttendancePieChart sessions={entity.sessions} />
      </RadColumnLayout>
      <RadDivider />
      <SessionAttendanceBarChart courses={entity.courses} sessions={entity.sessions} />
    </RadSpaceBetween>
  )
}

function People ({ courses }) {
  const pageLength = 20
  const [searchText, setSearchText] = useState('')
  const [currentPageIndex, setCurrentPageIndex] = useState(1)
  const enrollments = courses.reduce((acc, x) => {
    acc = acc.concat(x.enrollments.map(y => ({ ...y, course: x })))
    return acc
  }, [])
  const people = enrollments.reduce((acc, x) => {
    const existing = acc.find(y => y.id === x.person.id)
    if (existing == null) {
      const attendance = enrollments
        .filter(y => y.person.id === x.person.id)
        .reduce((acc, y) => {
          acc.present += y.attendance.present
          acc.total += y.attendance.total
          return acc
        }, { present: 0, total: 0 })
      acc.push({ ...x.person, attendance })
    }
    return acc
  }, [])
    .sort((a, b) => a.lastName.localeCompare(b.lastName))
    .sort((a, b) => a.firstName.localeCompare(b.firstName))
  const filteredPeople = people.filter(x =>
    x.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
    x.lastName.toLowerCase().includes(searchText.toLowerCase())
  )
  const counter = filteredPeople.length < people.length
    ? `(${filteredPeople.length}/${people.length})`
    : `(${people.length})`

  return (
    <RadSpaceBetween size='s'>
      <RadHeader
        variant='h2'
        counter={counter}
      >
        People
      </RadHeader>
      <RadGrid
        gridDefinition={[
          { colspan: { default: 12, xs: 6 } },
          { colspan: { default: 12, xs: 6 } }
        ]}
      >
        <RadFormField stretch>
          <RadInput
            value={searchText}
            placeholder='Search'
            type='search'
            onChange={({ detail }) => {
              setSearchText(detail.value ?? '')
              setCurrentPageIndex(1)
            }}
          />
        </RadFormField>
        <RadBox float='right'>
          <RadPagination
            currentPageIndex={currentPageIndex}
            pagesCount={Math.ceil(filteredPeople.length / pageLength)}
            onChange={({ detail }) => {
              setCurrentPageIndex(detail.currentPageIndex)
            }}
            ariaLabels={{
              nextPageLabel: 'Next page',
              previousPageLabel: 'Previous page',
              pageLabel: pageNumber => `Page ${pageNumber} of all pages`
            }}
          />
        </RadBox>
      </RadGrid>

      {enrollments.length === 0 && <RadBox color='text-body-secondary'>No people added</RadBox>}
      {enrollments.length > 0 &&
        <RadCards
          ariaLabels={{
            itemSelectionLabel: (e, t) => `select ${t.name}`,
            selectionGroupLabel: 'Item selection'
          }}
          cardDefinition={{
            header: item =>
              <RadLink fontSize='heading-m' href={`/person/${item.id}`}>
                <RadSpaceBetween size='xs'>
                  <Avatar
                    src={item.photoUrl}
                    size='large'
                    name={`${item.firstName} ${item.lastName}`}
                    referrerPolicy='no-referrer'
                  />
                  <div>{fullName(item)}</div>
                </RadSpaceBetween>
              </RadLink>,
            sections: [
              {
                id: 'info',
                content: item => {
                  return (
                    <RadSpaceBetween size='xxs'>
                      <AttendancePercentage item={item} />
                    </RadSpaceBetween>
                  )
                }
                // />
              }
            ]
          }}
          cardsPerRow={[
            { cards: 1 },
            { minWidth: 640, cards: 2 },
            { minWidth: 960, cards: 3 },
            { minWidth: 1280, cards: 4 }
          ]}
          items={filteredPeople.splice((currentPageIndex - 1) * pageLength, pageLength)}
          variant='full-page'
        />}
    </RadSpaceBetween>
  )
}
