import React from 'react'
import { RadBox } from '../common/RadBox'
import { RadPieChart } from '../common/RadPieChart'

export function FirstGenerationCollegeStudentPieChart ({ firstGenerationCollegePeople, total }) {
  return (
    <RadPieChart
      ariaDescription='Donut chart showing First Generation College Student'
      ariaLabel='Donut chart'
      data={firstGenerationCollegePeople.map(x => ({
        title: x.firstGenerationCollegeStudent ? 'Yes' : 'No',
        value: x.count,
        percentage: (x.count / total * 100).toFixed(0) + '%'
      }))}
      empty={
        <RadBox textAlign='center' color='inherit'>
          <b>First Generation College People</b>
          <RadBox variant='p' color='inherit'>
            There is no data available
          </RadBox>
        </RadBox>
    }
      innerMetricDescription={
        <RadBox fontSize='body-m' fontWeight='bold' color='text-body-secondary'>1st Generation<br />College Student</RadBox>
    }
      // innerMetricValue={firstGenerationCollegePeople.length}
      size='medium'
      variant='donut'
      hideFilter
      hideLegend
      hideTitles
      hideDescriptions
    />
  )
}
