import React, { useContext, useState, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import moment from 'moment-timezone'
import { v4 as uuidv4 } from 'uuid'
import { Avatar } from '@rmwc/avatar'
import { AppContext } from './../App'
import { useGet } from '../hooks/useGet'
import { usePost } from '../hooks/usePost'
import { RadAlert } from '../common/RadAlert'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadBreadcrumbGroup } from '../common/RadBreadcrumbGroup'
import { RadContainer } from '../common/RadContainer'
import { RadFormField } from '../common/RadFormField'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadPagination } from '../common/RadPagination'
import { RadRadioGroup } from '../common/RadRadioGroup'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTable } from '../common/RadTable'
import { RadTextFilter } from '../common/RadTextFilter'
import { RadWizard } from '../common/RadWizard'
import { PersonDetailFormFields } from '../person/PersonDetailFormFields'
import { PersonContactInformationFormFields } from '../person/PersonContactInformationFormFields'
import { PersonDemographicsFormFields } from '../person/PersonDemographicsFormFields'
import { PersonHealthInformationFormFields } from '../person/PersonHealthInformationFormFields'
import { PersonContactsFormFields } from '../person/PersonContactsFormFields'
import { PersonSchoolEnrollmentFormFields } from '../person/PersonSchoolEnrollmentFormFields'
import { formatDate, formatTimeRange, fullName } from '../common/utilities'

export function CourseEnrollmentCreate () {
  const { error } = useContext(AppContext)
  const navigate = useNavigate()
  const { courseId, enrollmentId } = useParams()
  const [searchParams] = useSearchParams()
  const personId = searchParams.get('personId')
  const [formValues, setFormValues] = useState()
  const [personFormValues, setPersonFormValues] = useState()
  const [activeStepIndex, setActiveStepIndex] = useState(0)
  const { data: person } = useGet(formValues?.personId ? `/api/person/${formValues.personId}` : null)
  const { data: courses } = useGet('/api/course?status=enrollable&personIds=true&limit=20')
  const create = usePost(
    '/api/course-enrollment',
    { ...formValues, person: personFormValues },
    (resp) => { navigate(searchParams.get('redirectURL') ?? `course-enrollment/${resp.id}`) }
  )

  useEffect(() => {
    if (courses != null) {
      const defaultPersonFormValues = {
        address: {},
        allergies: [],
        contacts: [{ uuid: uuidv4(), emergencyContact: false, smsPermission: false }],
        criticalInteractions: [],
        dietaryNeeds: [],
        disabilities: [],
        mediaRelease: false,
        schoolEnrollments: [{ uuid: uuidv4() }],
        usCitizenOrPermanentResident: false
      }
      const defaultFormValues = {}

      if (personId != null) {
        defaultFormValues.personId = parseInt(personId)
      }

      if (courseId != null) {
        const course = courses.find(x => x.id.toString() === courseId)
        if (course != null) {
          defaultFormValues.courseId = course.id
          defaultFormValues.startDate = course.schedules.reduce((acc, x) =>
            acc == null || moment(x.startDate).isBefore(moment(acc)) ? x.startDate : acc, null
          )
          if (moment(defaultFormValues.startDate).isBefore(moment())) {
            defaultFormValues.startDate = moment().format('YYYY-MM-DD')
          }
          defaultFormValues.endDate = course.schedules.reduce((acc, x) =>
            acc == null || moment(x.endDate).isBefore(moment(acc)) ? x.endDate : acc, null
          )
        }
      }

      setFormValues(defaultFormValues)
      setPersonFormValues(defaultPersonFormValues)
    }
  }, [courses])

  useEffect(() => {
    if (person != null) {
      if (person.contacts.length === 0) {
        person.contacts = [{ uuid: uuidv4(), emergencyContact: false, smsPermission: false }]
      }
      if (person.schoolEnrollments.length === 0) {
        person.schoolEnrollments = [{ uuid: uuidv4() }]
      }
      setPersonFormValues(person)
    }
  }, [person])

  async function cancel () {
    navigate(searchParams.get('redirectURL') ?? `/course/${enrollmentId}`)
  }

  function onNavigate (detail) {
    // console.log(detail)
    setActiveStepIndex(detail.requestedStepIndex)
  }

  if (
    formValues != null &&
    courses != null
  ) {
    const steps = [
      {
        title: 'Choose a course',
        content: (
          <RadContainer>
            <RadSpaceBetween direction='vertical' size='l'>
              <RadFormField
                label='Course'
                field='courseId'
                description='Choose a course from the list of active and upcoming courses.'
                required
              >
                <br />
                <RadRadioGroup
                  onChange={({ detail }) => {
                    const course = courses.find(x => x.id === detail.value)
                    if (course != null) {
                      const courseId = course.id
                      const startDate = course.schedules.reduce((acc, x) =>
                        acc == null || moment(x.startDate).isBefore(moment(acc)) ? x.startDate : acc, null
                      )
                      setFormValues({ ...formValues, courseId, startDate })
                    } else {
                      console.log('course not found')
                    }
                  }}
                  value={formValues.courseId}
                  items={courses
                    // .filter(x => x.personIds.length < x.capacity)
                    .filter(x => !x.personIds.includes(formValues.personId))
                    .map(x => ({
                      value: x.id,
                      label: x.name,
                      description: (
                        <RadSpaceBetween size='xxs'>
                          <div>{x.program.name}</div>
                          <div>{x.cohort.name}</div>
                          {x.instructors.map((y, i) =>
                            <div key={'course-' + y.id + '-instructor-' + i}>
                              {fullName(y)}
                            </div>)}
                          <div>{x.campus.name} {x.room != null ? ' | room ' + x.room : ''}</div>
                          {x.schedules.map((s, i) =>
                            <div key={'course-' + x.id + '-schedule-' + i}>
                              {s.weekdays.map(day => moment(day, 'ddd').format('dddd')).join(', ')}
                              &nbsp;|&nbsp;
                              {formatTimeRange(moment(s.startTime, 'HH:mm'), moment(s.endTime, 'HH:mm'))}
                            </div>)}
                        </RadSpaceBetween>
                      )
                    }))}
                />
              </RadFormField>
            </RadSpaceBetween>
          </RadContainer>
        )
      },
      {
        title: 'Choose a person',
        content: (
          <RadContainer>
            <RadSpaceBetween direction='vertical' size='l'>
              <PeopleSearch formValues={formValues} setFormValues={setFormValues} courseId={courseId} />
            </RadSpaceBetween>
          </RadContainer>
        )
      },
      {
        title: 'Enter or review person details',
        content: (
          <RadContainer>
            <PersonDetailFormFields formValues={personFormValues} setFormValues={setPersonFormValues} forCourseEnrollment />
          </RadContainer>
        )
      },
      {
        title: 'Enter or review person contact information',
        content: (
          <RadContainer>
            <PersonContactInformationFormFields formValues={personFormValues} setFormValues={setPersonFormValues} />
          </RadContainer>
        )
      },
      {
        title: 'Enter or review person demographic information',
        content: (
          <RadContainer>
            <PersonDemographicsFormFields formValues={personFormValues} setFormValues={setPersonFormValues} forCourseEnrollment />
          </RadContainer>
        )
      },
      {
        title: 'Enter or review person health information',
        content: (
          <RadContainer>
            <PersonHealthInformationFormFields formValues={personFormValues} setFormValues={setPersonFormValues} />
          </RadContainer>
        )
      },
      {
        title: 'Enter or review person contacts',
        content: (
          <RadContainer>
            <PersonContactsFormFields formValues={personFormValues} setFormValues={setPersonFormValues} />
          </RadContainer>
        )
      },
      {
        title: 'Enter or review person school enrollments',
        content: (
          <RadContainer>
            <PersonSchoolEnrollmentFormFields formValues={personFormValues} setFormValues={setPersonFormValues} />
          </RadContainer>
        )
      },
      {
        title: 'Choose a start date',
        content: (
          <RadContainer>
            <RadFormField
              label='Start Date'
              field='startDate'
            >
              <RadInput
                ariaRequired
                type='date'
                value={formValues.startDate}
                onChange={({ detail }) => setFormValues({ ...formValues, startDate: detail.value })}
              />
            </RadFormField>
          </RadContainer>
        )
        // isOptional: !cohortStarted
      }
      // {
      //   title: 'Review and add enrollment',
      //   content: (
      //     <RadContainer>
      //       TBD
      //     </RadContainer>
      //   )
      // }
    ]

    return (
      <RadAppLayout
        breadcrumbs={
          <RadBreadcrumbGroup
            items={[
              { text: 'Dashboard', href: '/' },
              { text: 'Courses', href: '/course' },
              { text: 'Course # ' + courseId, href: '/course/' + courseId },
              { text: 'New Enrollment', href: '#' }
            ]}
            ariaLabel='Breadcrumbs'
          />
        }
        contentHeader={
          <RadHeader variant='h1'>New Enrollment</RadHeader>
        }
        content={
          <>
            <RadWizard
              onCancel={() => cancel()}
              onSubmit={() => create()}
              onNavigate={({ detail }) => onNavigate(detail)}
              i18nStrings={{
                stepNumberLabel: stepNumber =>
                  `Step ${stepNumber}`,
                collapsedStepsLabel: (stepNumber, stepsCount) =>
                  `Step ${stepNumber} of ${stepsCount}`,
                navigationAriaLabel: 'Steps',
                cancelButton: 'Cancel',
                previousButton: 'Previous',
                nextButton: 'Next',
                submitButton: 'Add course enrollment',
                optional: 'optional'
              }}
              activeStepIndex={activeStepIndex}
              steps={steps}
            />
            {error != null &&
              <RadBox padding={{ top: 'xxl' }}>
                <RadAlert
                  statusIconAriaLabel='Error'
                  type='error'
                  header='Validation error'
                >
                  Please go back and review the information you entered.
                </RadAlert>
              </RadBox>}
          </>
        }
      />
    )
  }
}

function PeopleSearch ({ formValues, setFormValues, courseId }) {
  const pageLength = 5
  const [currentPageIndex, setCurrentPageIndex] = useState(1)
  const [searchText, setSearchText] = useState('')
  const [filteringText, setFilteringText] = useState('')
  const [selectedItems, setSelectedItems] = useState([])
  const { data: people, count } = useGet(
    searchText !== ''
      ? `/api/person?search=${searchText}&unenrolled=true&courseId=${courseId}&limit=${pageLength}&offset=${(currentPageIndex - 1) * pageLength}`
      : null
  )

  function escapeRegExp (string) {
    if (string == null) return null
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
  }

  const highlightMatch = (text) => {
    if (text == null || searchText == null) return null
    const escapedSearchText = escapeRegExp(searchText)
    const parts = text.split(new RegExp(`(${escapedSearchText})`, 'gi'))
    return (
      <span>{parts.map((part, i) =>
        part.toLowerCase() === searchText.toLowerCase()
          ? <span key={i} className='highlight'>{part}</span>
          : part
      )}
      </span>
    )
  }

  return (
    <RadTable
      header={
        <RadHeader
          variant='h2'
          counter={'(' + Number(count).toLocaleString() + ')'}
          description={
            <RadBox padding={{ bottom: 'xl' }}>
              Choose a person to enroll in the course. Click next if the person has never been enrolled before.
            </RadBox>
          }
        >
          People
        </RadHeader>
      }
      onSelectionChange={({ detail }) => {
        setSelectedItems(detail.selectedItems)
        setFormValues({ ...formValues, personId: detail.selectedItems[0]?.id })
      }}
      selectedItems={selectedItems}
      columnDefinitions={[
        {
          id: 'avatar',
          cell: item =>
            <Avatar
              src={item.photoUrl}
              size='large'
              name={`${item.firstName} ${item.lastName}`}
              referrerPolicy='no-referrer'
            />
        },
        {
          id: 'name',
          header: 'Name',
          cell: item => highlightMatch(fullName(item))
        },
        {
          id: 'address',
          header: 'Address',
          cell: item =>
            <div>
              {highlightMatch(item.address?.line1)}
              {highlightMatch(item.address?.line2)}
              {highlightMatch(` ${item.address?.city}, ${item.address?.state} ${item.address?.zip}`)}
            </div>
        },
        {
          id: 'school',
          header: 'School',
          cell: item => item.schoolEnrollment != null ? `${item.schoolEnrollment?.school} - ${item.schoolEnrollment?.grade}` : '-'
        },
        {
          id: 'birthdate',
          header: 'Birthdate',
          cell: item => formatDate(item.birthdate) ?? '-'
        }
      ]}
      items={people ?? []}
      variant='embedded'
      filter={
        <RadTextFilter
          filteringText={filteringText}
          filteringPlaceholder='Search people'
          filteringAriaLabel='Filter people'
          onChange={({ detail }) => setFilteringText(detail.filteringText)}
          onDelayedChange={({ detail }) => setSearchText(detail.filteringText ?? '')}
        />
      }
      pagination={
        <RadPagination
          currentPageIndex={currentPageIndex}
          pagesCount={Math.ceil(count / pageLength)}
          onChange={({ detail }) => {
            setCurrentPageIndex(detail.currentPageIndex)
          }}
          ariaLabels={{
            nextPageLabel: 'Next page',
            previousPageLabel: 'Previous page',
            pageLabel: pageNumber => `Page ${pageNumber} of all pages`
          }}
        />
      }
      selectionType='single'
      empty={
        <RadBox textAlign='center' color='inherit'>
          Enter a search term to find people to enroll in the course.
        </RadBox>
      }
    />
  )
}
