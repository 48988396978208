import React from 'react'
import moment from 'moment-timezone'
import { formatDate, formatDateRange, formatTimeRange } from '../common/utilities'

export function CourseSchedule ({ course }) {
  moment.tz.setDefault('America/Chicago')

  if (!course.schedules || course.schedules.length === 0) {
    return <div>-</div>
  }
  return (
    <div>
      {course.schedules.map(x => <CourseScheduleItem key={'session-' + x.id} schedule={x} />)}
    </div>
  )
}

function CourseScheduleItem ({ schedule }) {
  switch (schedule.recurrence) {
    case 'weekly':
      return (
        <div>
          {schedule.weekdays.map(day => moment(day, 'ddd').format('dddd')).join(', ')}
          &nbsp;|&nbsp;
          {formatTimeRange(moment(schedule.startTime, 'HH:mm'), moment(schedule.endTime, 'HH:mm'))}
        </div>
      )
    case 'daily':
      return (
        <div>
          {formatDateRange(moment(schedule.startDate), moment(schedule.endDate))}
          &nbsp;|&nbsp;
          {formatTimeRange(moment(schedule.startTime, 'HH:mm'), moment(schedule.endTime, 'HH:mm'))}
        </div>
      )
    case 'none':
      return (
        <div>
          {formatDate(schedule.startDate)}
          &nbsp;|&nbsp;
          {formatTimeRange(moment(schedule.startTime, 'HH:mm'), moment(schedule.endTime, 'HH:mm'))}
        </div>
      )
    default: return <div>{schedule.recurrence} NOT IMPLEMENTED</div>
  }
}
