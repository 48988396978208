import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import moment from 'moment-timezone'
import { Avatar } from '@rmwc/avatar'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadBreadcrumbGroup } from '../common/RadBreadcrumbGroup'
import { RadButton } from '../common/RadButton'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadContainer } from '../common/RadContainer'
import { RadHeader } from '../common/RadHeader'
import { RadLink } from '../common/RadLink'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { CourseSchedule } from '../shared/CourseSchedule'
import { formatDate, formatDateTime, fullName } from '../common/utilities'

export function CourseEnrollmentDetail () {
  moment.tz.setDefault('America/Chicago')
  const navigate = useNavigate()
  const { courseEnrollmentId } = useParams()
  const { data: enrollment } = useGet(`/api/course-enrollment/${courseEnrollmentId}`, true)
  const confirmDelete = useConfirm('Delete enrollment?', 'Delete enrollment permanently? This action cannot be undone.', 'Delete', () => { remove() })
  const remove = useDelete(`/api/course-enrollment/${courseEnrollmentId}`, () => { navigate('/course') })

  if (enrollment != null) {
    return (
      <RadAppLayout
        breadcrumbs={
          <RadBreadcrumbGroup
            items={[
              { text: 'Dashboard', href: '/' },
              { text: 'Courses', href: '/course' },
              { text: 'Course # ' + enrollment.courseId, href: '/course/' + enrollment.courseId },
              {
                text: 'Enrollment # ' + enrollment.id,
                href: '#'
              }
            ]}
            ariaLabel='Breadcrumbs'
          />
        }
        name={'Course Enrollment # ' + enrollment.id}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('edit')}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={enrollment.isProtected}>Delete</RadButton>
              </RadSpaceBetween>
            }
          >
            {'Course Enrollment # ' + enrollment.id}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <Course course={enrollment.course} />
            <Person person={enrollment.person} />
            <Details enrollment={enrollment} />
            <RadColumnLayout columns={2}>
              <Certificates enrollment={enrollment} />
              <Absences enrollment={enrollment} />
            </RadColumnLayout>
          </RadSpaceBetween>
        }
      />
    )
  }
}

function Course ({ course, settings }) {
  return (
    <RadContainer
      header={
        <RadHeader variant='h2'>
          Course # {course.id}
        </RadHeader>
      }
    >
      <RadSpaceBetween size='l'>
        <RadColumnLayout columns={3} borders='vertical'>
          <div>
            <RadBox variant='awsui-key-label' padding={{ bottom: 's' }}>
              Cohort
            </RadBox>
            <RadLink variant='primary' href={`/cohort/${course.cohort.id}`}>{course.cohort.name ?? '-'}</RadLink>
          </div>
          <div>
            <RadBox variant='awsui-key-label' padding={{ bottom: 'xxs' }}>
              Program
            </RadBox>
            <RadSpaceBetween direction='horizontal'>
              <Avatar
                src={course.program.photoUrl ?? '/program_icon.webp'}
                size='large'
                referrerPolicy='no-referrer'
              />
              <RadBox padding={{ left: 's', top: 'xs' }}>
                <RadLink variant='primary' href={`/program/${course.program.id}`}>{course.program.name}</RadLink>
              </RadBox>
            </RadSpaceBetween>
          </div>
          <div>
            <RadBox variant='awsui-key-label' padding={{ bottom: 'xxs' }}>
              Instructor(s)
            </RadBox>
            <RadSpaceBetween size='xxs'>
              {course.instructors.map(x =>
                <RadSpaceBetween key={'instructor-' + x.id} direction='horizontal'>
                  <Avatar
                    src={x.instructor.photoUrl}
                    size='large'
                    name={fullName(x.instructor)}
                    referrerPolicy='no-referrer'
                  />
                  <RadBox padding={{ left: 's', top: 'xs' }}>
                    <RadLink variant='primary' href={`/instructor/${x.instructor.id}`}>{fullName(x.instructor)}</RadLink>
                  </RadBox>
                </RadSpaceBetween>
              )}
            </RadSpaceBetween>
          </div>
        </RadColumnLayout>
        <RadColumnLayout columns={3} borders='vertical'>
          <div>
            <RadBox variant='awsui-key-label'>
              Campus / Room
            </RadBox>
            {course.campus.name} {course.room ? ` / ${course.room}` : ''}
          </div>
          <div>
            <RadBox variant='awsui-key-label'>
              Capacity
            </RadBox>
            {course.capacity}
          </div>
          <div>
            <RadBox variant='awsui-key-label'>
              Schedule
            </RadBox>
            <CourseSchedule course={course} />
          </div>
        </RadColumnLayout>
      </RadSpaceBetween>
    </RadContainer>
  )
}

function Person ({ person }) {
  return (
    <RadContainer
      header={
        <RadHeader variant='h2'>
          Student
        </RadHeader>
    }
    >
      <RadSpaceBetween size='l'>
        {person.photoUrl &&
          <img
            className='profile'
            style={{ display: 'block' }}
            src={person.photoUrl}
            alt='icon'
            referrerPolicy='no-referrer'
          />}
        <RadLink href={`/person/${person.id}`}>{person.fullName}</RadLink>
      </RadSpaceBetween>
    </RadContainer>
  )
}

function Details ({ enrollment }) {
  return (
    <RadContainer
      header={
        <RadHeader variant='h2'>
          Details
        </RadHeader>
    }
    >
      <RadColumnLayout columns={3} borders='vertical'>
        <div>
          <RadBox variant='awsui-key-label'>
            Start Date
          </RadBox>
          <div>{formatDate(enrollment.startDate ?? enrollment.course.cohort.startDate)}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            End Date
          </RadBox>
          <div>{formatDate(enrollment.endDate ?? enrollment.course.cohort.endDate)}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Exit Reason
          </RadBox>
          <div>{enrollment.exitReason?.name ?? '-'}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Created
          </RadBox>
          <div>{formatDateTime(enrollment.createdAt)}</div>
        </div>
        {enrollment.createdAt !== enrollment.updatedAt &&
          <div>
            <RadBox variant='awsui-key-label'>
              Updated
            </RadBox>
            <div>{formatDateTime(enrollment.createdAt)}</div>
          </div>}
      </RadColumnLayout>
    </RadContainer>
  )
}

function Certificates ({ enrollment }) {
  return (
    <RadContainer
      header={
        <RadHeader
          counter={'(' + enrollment.certificates.length + ')'}
          variant='h2'
        >
          Certificates
        </RadHeader>
    }
    >
      {enrollment.certificates.length === 0 &&
        <RadBox color='text-status-inactive'>No certificates added to enrollment.</RadBox>}
      <RadSpaceBetween size='xxs'>
        {enrollment.certificates.map(x =>
          <div key={'certificate-' + x.id}>{x.certificate.name} | {formatDate(x.date)}</div>
        )}
      </RadSpaceBetween>
    </RadContainer>
  )
}

function Absences ({ enrollment }) {
  const absences = enrollment.attendances
    .filter(x => x.absent === true)

  console.log(absences)
  return (
    <RadContainer
      header={
        <RadHeader
          counter={'(' + absences.length + ')'}
          variant='h2'
        >
          Absences
        </RadHeader>
    }
    >
      {absences.length === 0 && <RadBox color='text-status-inactive'>No absences added to enrollment.</RadBox>}
      <RadSpaceBetween size='xxs'>
        {absences.map(x =>
          <div key={'absence-' + x.id}>{formatDate(x.session.date)}</div>
        )}
      </RadSpaceBetween>
    </RadContainer>
  )
}
