import React, { useState, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import moment from 'moment-timezone'
import { Avatar } from '@rmwc/avatar'
import { useGet } from '../hooks/useGet'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadContainer } from '../common/RadContainer'
import { RadDivider } from '../common/RadDivider'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadLink } from '../common/RadLink'
import { RadRadioGroup } from '../common/RadRadioGroup'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTextarea } from '../common/RadTextarea'
import { formatTimeRange, fullName } from '../common/utilities'
import { CourseSchedule } from '../shared/CourseSchedule'

export function StudentReportEdit () {
  moment.tz.setDefault('America/Chicago')
  const navigate = useNavigate()
  const { studentReportId } = useParams()
  const [searchParams] = useSearchParams()
  const [formValues, setFormValues] = useState()
  const { data: studentReport } = useGet(
    studentReportId ? `/api/student-report/${studentReportId}` : null)
  const { data: courses } = useGet('/api/course?enrollments=true&limit=20')
  const { data: studentRatings } = useGet('/api/student-rating')
  const create = usePost(
    '/api/student-report',
    formValues,
    (resp) => { navigate(searchParams.get('redirectURL') ?? `/student-report/${resp.id}`) }
  )
  const update = usePut(
    `/api/student-report/${studentReportId}`,
    formValues,
    (resp) => { navigate(searchParams.get('redirectURL') ?? `/student-report/${studentReportId}`) }
  )

  useEffect(() => {
    const defaultFormValues = { date: moment().format('YYYY-MM-DD'), people: [] }
    setFormValues(studentReport ?? defaultFormValues)
  }, [studentReport])

  async function cancel () {
    if (studentReportId != null) {
      navigate(searchParams.get('redirectURL') ?? `/student-report/${studentReportId}`)
    } else {
      navigate(searchParams.get('redirectURL') ?? '/student-report')
    }
  }

  async function saveChanges () {
    if (studentReportId != null) {
      update()
    } else {
      create()
    }
  }

  if (
    formValues != null &&
    courses != null &&
    studentRatings != null
  ) {
    const studentRatingOptions = studentRatings.map(x => ({
      value: x.id.toString(),
      label: `${x.name} (${x.description})`,
      category: x.studentRatingCategoryName
    }))
    return (
      <RadAppLayout
        name={`Student Report # ${studentReportId}`}
        contentHeader={
          <RadHeader variant='h1'>
            {studentReportId != null ? 'Edit' : 'New'} Student Report
          </RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>
                    {studentReportId != null ? 'Save Changes' : 'Add Student Report'}
                  </RadButton>
                  {/* <RadButton
                    onClick={() => {
                      const people = formValues.people.map(x => ({
                        personId: x.personId,
                        behaviorRatingId: 5,
                        performanceRatingId: 13,
                        participationRatingId: 9,
                        student: x.student
                      }))
                      setFormValues({ ...formValues, people })
                    }}
                  >
                    Test
                  </RadButton> */}
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <Details
                  studentReport={studentReport}
                  formValues={formValues} setFormValues={setFormValues}
                  courses={courses}
                />
                {studentReport != null &&
                  <Course
                    studentReport={studentReport}
                    formValues={formValues} setFormValues={setFormValues}
                    courses={courses}
                  />}
                <People
                  formValues={formValues}
                  setFormValues={setFormValues}
                  studentRatingOptions={studentRatingOptions}
                />
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}

function Details ({ studentReport, formValues, setFormValues, courses }) {
  return (
    <RadContainer
      header={
        <RadHeader variant='h2'>
          Details
        </RadHeader>
    }
    >
      <RadSpaceBetween size='l'>
        <RadGrid
          gridDefinition={[
            { colspan: { default: 12, xs: 3 } }
          ]}
        >
          <RadFormField label='Date' field='date' required>
            <RadInput
              ariaRequired
              type='date'
              value={formValues.date}
              onChange={({ detail }) => setFormValues({ ...formValues, date: detail.value })}
            />
          </RadFormField>
        </RadGrid>
        {studentReport == null &&
          <RadFormField label='Course' field='courseId' required>
            <RadRadioGroup
              onChange={({ detail }) => {
                const course = courses.find(x => x.id === detail.value)
                const people = course.enrollments.map(x => ({
                  personId: x.personId,
                  person: {
                    firstName: x.firstName,
                    middleName: x.middleName,
                    lastName: x.lastName,
                    preferredName: x.preferredName
                  }
                }))
                setFormValues({ ...formValues, courseId: course.id, people })
              }}
              value={formValues.courseId}
              items={courses
                .map(x => ({
                  value: x.id,
                  label: x.name,
                  description: (
                    <RadSpaceBetween size='xxs'>
                      <div>{x.cohort.name}</div>
                      <div>{x.program.name}</div>
                      {x.instructors.map((y, i) =>
                        <div key={'course-' + y.id + '-instructor-' + i}>
                          {fullName(y)}
                        </div>)}
                      <div>{x.campus.name} {x.room != null ? ' | room ' + x.room : ''}</div>
                      {x.schedules.map((s, i) =>
                        <div key={'course-' + x.id + '-schedule-' + i}>
                          {s.weekdays.map(day => moment(day, 'ddd').format('dddd')).join(', ')}
                      &nbsp;|&nbsp;
                          {formatTimeRange(moment(s.startTime, 'HH:mm'), moment(s.endTime, 'HH:mm'))}
                        </div>)}
                    </RadSpaceBetween>
                  )
                }))}
            />
          </RadFormField>}
      </RadSpaceBetween>
    </RadContainer>
  )
}

function Course ({ studentReport, formValues, setFormValues, courses }) {
  return (
    <RadContainer
      header={
        <RadHeader
          // counter='(not editable)'
          variant='h2'
        >
          Course
        </RadHeader>
    }
    >
      <RadSpaceBetween size='l'>
        <RadColumnLayout columns={4} borders='vertical'>
          <div>
            <RadBox variant='awsui-key-label' padding={{ bottom: 's' }}>Cohort</RadBox>
            <RadLink variant='primary' href={`/cohort/${studentReport.course.cohort.id}`}>
              {studentReport.course.cohort.name ?? '-'}
            </RadLink>
          </div>
          <div>
            <RadBox variant='awsui-key-label' padding={{ bottom: 'xxs' }}>Program</RadBox>
            <RadSpaceBetween direction='horizontal'>
              <Avatar
                src={studentReport.course.program.photoUrl ?? '/program_icon.webp'}
                size='large'
                referrerPolicy='no-referrer'
              />
              <RadBox padding={{ left: 's', top: 'xs' }}>
                <RadLink variant='primary' href={`/program/${studentReport.course.program.id}`}>
                  {studentReport.course.program.name}
                </RadLink>
              </RadBox>
            </RadSpaceBetween>
          </div>
          <div>
            <RadBox variant='awsui-key-label' padding={{ bottom: 'xxs' }}>Instructor(s)</RadBox>
            <RadSpaceBetween size='xxs'>
              {studentReport.course.instructors.map(x =>
                <RadSpaceBetween key={'instructor-' + x.id} direction='horizontal'>
                  <Avatar
                    src={x.instructor.photoUrl}
                    size='large'
                    name={fullName(x.instructor)}
                    referrerPolicy='no-referrer'
                  />
                  <RadBox padding={{ left: 's', top: 'xs' }}>
                    <RadLink variant='primary' href={`/instructor/${x.instructor.id}`}>
                      {fullName(x.instructor)}
                    </RadLink>
                  </RadBox>
                </RadSpaceBetween>
              )}
            </RadSpaceBetween>
          </div>
          <div>
            <RadBox variant='awsui-key-label' padding={{ bottom: 's' }}>Schedule</RadBox>
            <CourseSchedule course={studentReport.course} />
          </div>
        </RadColumnLayout>
      </RadSpaceBetween>
    </RadContainer>
  )
}

function People ({ formValues, setFormValues, studentRatingOptions }) {
  return (
    <RadContainer
      header={
        <RadHeader
          counter={'(' + formValues.people.length + ')'}
          variant='h2'
        >
          Students
        </RadHeader>
    }
    >
      <>
        {formValues.people.map(x =>
          <Person
            key={'person-' + x.id}
            item={x}
            formValues={formValues}
            setFormValues={setFormValues}
            studentRatingOptions={studentRatingOptions}
          />
        )}
        {formValues.people.length === 0 &&
          <RadBox color='text-status-inactive'>Select a course to see student list</RadBox>}
      </>
    </RadContainer>
  )
}

function Person ({ item, formValues, setFormValues, studentRatingOptions }) {
  const people = formValues.people

  return (
    <RadSpaceBetween size='l'>
      <RadBox variant='h3'>
        <RadLink variant='primary' fontSize='heading-m' href={`/person/${item.id}`}>
          {fullName(item.person)}
        </RadLink>
      </RadBox>
      <RadGrid
        gridDefinition={[
          { colspan: { default: 12, xs: 4 } },
          { colspan: { default: 12, xs: 4 } },
          { colspan: { default: 12, xs: 4 } }
        ]}
      >
        <RadFormField label='Behavior' field={`person.${item.id}.behaviorRatingId`} required>
          <RadSelect
            selectedOption={
              studentRatingOptions.find(x => parseInt(x.value) === item.behaviorRatingId) ?? { value: '', label: '-' }
            }
            onChange={({ detail }) => {
              item.behaviorRatingId = parseInt(detail.selectedOption.value)
              setFormValues({ ...formValues, people })
            }}
            options={studentRatingOptions.filter(x => x.category === 'Behavior')}
            selectedAriaLabel='Selected'
            empty='No matches found'
          />
        </RadFormField>
        <RadFormField label='Performance' field={`person.${item.id}.performanceRatingId`} required>
          <RadSelect
            selectedOption={
              studentRatingOptions.find(x => parseInt(x.value) === item.performanceRatingId) ?? { value: '', label: '-' }
            }
            onChange={({ detail }) => {
              item.performanceRatingId = parseInt(detail.selectedOption.value)
              setFormValues({ ...formValues, people })
            }}
            options={studentRatingOptions.filter(x => x.category === 'Performance')}
            selectedAriaLabel='Selected'
            empty='No matches found'
          />
        </RadFormField>
        <RadFormField label='Participation' field={`person.${item.id}.participationRatingId`} required>
          <RadSelect
            selectedOption={
              studentRatingOptions.find(x => parseInt(x.value) === item.participationRatingId) ?? { value: '', label: '-' }
            }
            onChange={({ detail }) => {
              item.participationRatingId = parseInt(detail.selectedOption.value)
              setFormValues({ ...formValues, people })
            }}
            options={studentRatingOptions.filter(x => x.category === 'Participation')}
            selectedAriaLabel='Selected'
            empty='No matches found'
          />
        </RadFormField>
      </RadGrid>
      <RadFormField label='Comments' field={`person.${item.id}.comments`} stretch>
        <RadTextarea
          rows={4}
          placeholder='Enter comments'
          value={item.comments}
          onChange={({ detail }) => {
            item.comments = detail.value
            setFormValues({ ...formValues, people })
          }}
        />
      </RadFormField>
      {item !== people[people.length - 1] && <RadDivider />}
    </RadSpaceBetween>
  )
}
