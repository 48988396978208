import React, { createContext, useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { firebase, auth, ui } from './firebase'
import { SignIn } from './SignIn'
import { RadTopNavigation } from './common/RadTopNavigation'
import { IsLoading } from './common/IsLoading'
import { RadNotFound } from './common/RadNotFound'
import { Confirmation } from './Confirmation'
import { RadPermissionDenied } from './common/RadPermissionDenied'
import { RadConfirmationModal } from './common/RadConfirmationModal'
import { AttendanceEdit } from './attendance/AttendanceEdit'
import { CohortDetail } from './cohort/CohortDetail'
import { CohortEdit } from './cohort/CohortEdit'
import { CohortList } from './cohort/CohortList'
import { ConsecutiveAbsenceList } from './consecutiveAbsence/ConsecutiveAbsenceList'
import { CourseCertificates } from './course/CourseCertificates'
import { CourseDetail } from './course/CourseDetail'
import { CourseEdit } from './course/CourseEdit'
import { CourseEnrollmentCreate } from './courseEnrollment/CourseEnrollmentCreate'
import { CourseEnrollmentDetail } from './courseEnrollment/CourseEnrollmentDetail'
import { CourseEnrollmentEdit } from './courseEnrollment/CourseEnrollmentEdit'
import { CourseList } from './course/CourseList'
import { CriticalInteractionList } from './criticalInteractions/CriticalInteractionList'
import { Dashboard } from './dashboard/Dashboard'
import { ExitList } from './exit/ExitList'
import { InstructorDetail } from './instructor/InstructorDetail'
import { InstructorEdit } from './instructor/InstructorEdit'
import { InstructorList } from './instructor/InstructorList'
import { OptionEdit } from './option/OptionEdit'
import { OptionList } from './option/OptionList'
import { PersonDetail } from './person/PersonDetail'
import { PersonEdit } from './person/PersonEdit'
import { PersonList } from './person/PersonList'
import { ProgramDetail } from './program/ProgramDetail'
import { ProgramEdit } from './program/ProgramEdit'
import { ProgramList } from './program/ProgramList'
import { RoleDetail } from './role/RoleDetail'
import { RoleEdit } from './role/RoleEdit'
import { RoleList } from './role/RoleList'
import { SchoolDetail } from './school/SchoolDetail'
import { SchoolEdit } from './school/SchoolEdit'
import { SchoolList } from './school/SchoolList'
import { SessionDetail } from './session/SessionDetail'
import { SettingsDetail } from './settings/SettingsDetail'
import { SettingsEdit } from './settings/SettingsEdit'
import { StudentRatingEdit } from './studentRating/StudentRatingEdit'
import { StudentRatingList } from './studentRating/StudentRatingList'
import { StudentReportDetail } from './studentReport/StudentReportDetail'
import { StudentReportEdit } from './studentReport/StudentReportEdit'
import { StudentReportList } from './studentReport/StudentReportList'
import { UserDetail } from './user/UserDetail'
import { UserEdit } from './user/UserEdit'
import { UserList } from './user/UserList'

import '@cloudscape-design/global-styles/index.css'
import './App.scss'

export const AppContext = createContext(null)

export function App () {
  const [language, setLanguage] = useState('English')
  const [user, setUser] = useState(undefined)
  const [loadingCount, setLoadingCount] = useState(1)
  const [notFound, setNotFound] = useState(false)
  const [permissionDenied, setPermissionDenied] = useState(null)
  const [error, setError] = useState()
  const [navigationOpen, setNavigationOpen] = useState(window.localStorage.getItem('navigationOpen') === 'true')
  const [reloadCounter, setReloadCounter] = useState(0)
  const [confirmModal, setConfirmModal] = useState({ visible: false })

  useEffect(() => {
    window.localStorage.setItem('navigationOpen', navigationOpen)
  }, [navigationOpen])

  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      setUser(user)
      setLoadingCount(count => count - 1)
    })
    return () => unregisterAuthObserver()
  }, [])

  if (user === undefined) {
    return <IsLoading loadingCount={loadingCount} />
  }
  // link to docs for options
  // https://firebase.google.com/docs/auth/web/firebaseui
  if (user == null) {
    ui.start('#firebaseui-auth-container', {
      signInFlow: 'popup',
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID
      ],
      callbacks: {
        signInFailure: function (error) {
          console.log(error)
        },
        signInSuccessWithAuthResult: function (result) {
          ui.reset()
          return false
        }
      }
    })
    ui.disableAutoSignIn()
    return <SignIn />
  }

  return (
    <BrowserRouter>
      <AppContext.Provider value={{
        language,
        setLanguage,
        user,
        loadingCount,
        setLoadingCount,
        notFound,
        setNotFound,
        permissionDenied,
        setPermissionDenied,
        reloadCounter,
        setReloadCounter,
        error,
        setError,
        navigationOpen,
        setNavigationOpen,
        confirmModal,
        setConfirmModal
      }}
      >
        <RadTopNavigation />
        <Routes>
          <Route path='/' element={<Dashboard />} />

          {/* <Route path='/mio' element={<Mio />} />
          <Route path='/mio/settings' element={<Settings />} /> */}

          <Route path='/attendance/:sessionId' element={<AttendanceEdit />} />

          <Route path='/cohort' element={<CohortList />} />
          <Route path='/cohort/:cohortId' element={<CohortDetail />} />
          <Route path='/cohort/create' element={<CohortEdit />} />
          <Route path='/cohort/:cohortId/edit' element={<CohortEdit />} />

          <Route path='/confirmation' element={<Confirmation />} />

          <Route path='/consecutive-absence' element={<ConsecutiveAbsenceList />} />

          <Route path='/course' element={<CourseList />} />
          <Route path='/course/:courseId' element={<CourseDetail />} />
          <Route path='/course/:courseId/enrollment/create' element={<CourseEnrollmentCreate />} />
          <Route path='/course/:courseId/enrollment/:courseEnrollmentId' element={<CourseEnrollmentDetail />} />
          <Route path='/course/:courseId/enrollment/:courseEnrollmentId/edit' element={<CourseEnrollmentEdit />} />
          <Route path='/course/:courseId/session/:sessionId' element={<SessionDetail />} />
          <Route path='/course/create' element={<CourseEdit />} />
          <Route path='/course/:courseId/edit' element={<CourseEdit />} />
          <Route path='/course/:courseId/certificates' element={<CourseCertificates />} />

          <Route path='/critical-interactions' element={<CriticalInteractionList />} />

          <Route path='/exit' element={<ExitList />} />

          <Route path='/instructor' element={<InstructorList />} />
          <Route path='/instructor/:instructorId' element={<InstructorDetail />} />
          <Route path='/instructor/create' element={<InstructorEdit />} />
          <Route path='/instructor/:instructorId/edit' element={<InstructorEdit />} />

          <Route path='/program' element={<ProgramList />} />
          <Route path='/program/:programId' element={<ProgramDetail />} />
          <Route path='/program/create' element={<ProgramEdit />} />
          <Route path='/program/:programId/edit' element={<ProgramEdit />} />

          <Route path='/role' element={<RoleList />} />
          <Route path='/role/create' element={<RoleEdit />} />
          <Route path='/role/:roleId' element={<RoleDetail />} />
          <Route path='/role/:roleId/edit' element={<RoleEdit />} />

          <Route path='/settings' element={<SettingsDetail />} />
          <Route path='/settings/edit' element={<SettingsEdit />} />

          <Route path='/school' element={<SchoolList />} />
          <Route path='/school/:schoolId' element={<SchoolDetail />} />
          <Route path='/school/create' element={<SchoolEdit />} />
          <Route path='/school/:schoolId/edit' element={<SchoolEdit />} />

          <Route path='/person' element={<PersonList />} />
          <Route path='/person/:personId' element={<PersonDetail />} />
          <Route path='/person/create' element={<PersonEdit />} />
          <Route path='/person/:personId/edit' element={<PersonEdit />} />

          <Route path='/student-report' element={<StudentReportList />} />
          <Route path='/student-report/:studentReportId' element={<StudentReportDetail />} />
          <Route path='/student-report/create' element={<StudentReportEdit />} />
          <Route path='/student-report/:studentReportId/edit' element={<StudentReportEdit />} />

          <Route path='/student-rating' element={<StudentRatingList />} />
          <Route path='/student-rating/edit' element={<StudentRatingEdit />} />

          <Route path='/option' element={<OptionList />} />
          <Route path='/option/:entity' element={<OptionEdit />} />

          <Route path='/user' element={<UserList />} />
          <Route path='/user/create' element={<UserEdit />} />
          <Route path='/user/:userId' element={<UserDetail />} />
          <Route path='/user/:userId/edit' element={<UserEdit />} />

          <Route path='*' element={<RadNotFound home='/' />} />
        </Routes>
        <IsLoading loadingCount={loadingCount} />
        {loadingCount === 0 && notFound && <RadNotFound home='/' />}
        {permissionDenied != null && <RadPermissionDenied message={permissionDenied} />}
        <RadConfirmationModal />
      </AppContext.Provider>
    </BrowserRouter>
  )
}
