import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { usePut } from '../hooks/usePut'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadButton } from '../common/RadButton'
import { RadContainer } from '../common/RadContainer'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function SettingsEdit () {
  const navigate = useNavigate()
  const [formValues, setFormValues] = useState()
  const { data: settings } = useGet('/api/settings/')
  const update = usePut('/api/settings/', formValues, (resp) => { cancel() })

  useEffect(() => {
    setFormValues(settings)
  }, [settings])

  async function cancel () {
    navigate('/settings')
  }

  async function saveChanges () {
    update()
  }

  if (
    formValues != null
  ) {
    return (
      <RadAppLayout
        name={formValues.fullName}
        contentHeader={
          <RadHeader variant='h1'>Edit Settings</RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Details
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadGrid
                      gridDefinition={[
                        { colspan: { default: 12, s: 3 } },
                        { colspan: { default: 12, s: 3 } },
                        { colspan: { default: 12, s: 3 } }
                      ]}
                    >
                      <RadFormField label='Attendance Threshold' field='attendanceThreshold'>
                        <RadInput
                          type='number'
                          inputMode='number'
                          placeholder='Enter attendance threshold percentage'
                          value={formValues.attendanceThreshold}
                          onChange={({ detail }) =>
                            setFormValues({ ...formValues, attendanceThreshold: detail.value })}
                        />
                      </RadFormField>
                      <RadFormField
                        label='Consecutive Absence Threshold'
                        field='consecutiveAbsenceThreshold'
                      >
                        <RadInput
                          type='number'
                          inputMode='number'
                          placeholder='Enter consecutive absence threshold'
                          value={formValues.consecutiveAbsenceThreshold}
                          onChange={({ detail }) =>
                            setFormValues({ ...formValues, consecutiveAbsenceThreshold: detail.value })}
                        />
                      </RadFormField>
                      <RadFormField
                        label='Critical Interaction Notification Days'
                        field='criticalInteractionNotificationDays'
                      >
                        <RadInput
                          type='number'
                          inputMode='number'
                          placeholder='Enter critical interaction notification days'
                          value={formValues.criticalInteractionNotificationDays}
                          onChange={({ detail }) =>
                            setFormValues({ ...formValues, criticalInteractionNotificationDays: detail.value })}
                        />
                      </RadFormField>
                    </RadGrid>
                  </RadSpaceBetween>
                </RadContainer>
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}
