import React, { useRef, useState, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { RadAlert } from '../common/RadAlert'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadButton } from '../common/RadButton'
import { RadContainer } from '../common/RadContainer'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadTextarea } from '../common/RadTextarea'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { toBase64 } from '../common/utilities'

export function ProgramEdit () {
  const inputRef = useRef(null)
  const [preview, setPreview] = useState()
  const [fileSizeTooLarge, setFileSizeTooLarge] = useState(false)
  const navigate = useNavigate()
  const { programId } = useParams()
  const [searchParams] = useSearchParams()
  const [formValues, setFormValues] = useState()
  const { data: program } = useGet(programId ? `/api/program/${programId}` : null)
  const create = usePost('/api/program', formValues, (resp) => { navigate(searchParams.get('redirectURL') ?? `/program/${resp.id}`) })
  const update = usePut(`/api/program/${programId}`, formValues, (resp) => { navigate(searchParams.get('redirectURL') ?? `/program/${programId}`) })

  useEffect(() => {
    const defaultFormValues = { }
    setFormValues(program ?? defaultFormValues)
  }, [program])

  async function cancel () {
    if (programId != null) {
      navigate(searchParams.get('redirectURL') ?? `/program/${programId}`)
    } else {
      navigate(searchParams.get('redirectURL') ?? '/program')
    }
  }

  async function onFileChange (event) {
    setFileSizeTooLarge(false)
    const file = event.target.files[0]
    if (file != null && file.size > 10000000) {
      setFileSizeTooLarge(true)
    } else {
      setPreview(URL.createObjectURL(file))
      if (file != null) {
        const data = await toBase64(file)
        setFormValues({ ...formValues, file: file.name, fileSize: file.size, fileData: data })
      } else {
        removeFile()
      }
    }
    event.target.value = ''
  }

  function removeFile () {
    setFormValues({ ...formValues, file: null, fileSize: null, fileData: null, photoUrl: null })
  }

  async function saveChanges () {
    if (programId != null) {
      update()
    } else {
      create()
    }
  }

  if (
    formValues != null
  ) {
    return (
      <RadAppLayout
        name={formValues.fullName}
        contentHeader={
          <RadHeader variant='h1'>{programId ? 'Edit' : 'New'} Program</RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Details
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <input type='file' ref={inputRef} style={{ display: 'none' }} onChange={onFileChange} />
                    {formValues.photoUrl == null && preview == null && <div className='profile' onClick={() => inputRef.current.click()}><br /><br /><br />click to add image</div>}
                    {formValues.photoUrl != null && preview == null && <img src={formValues.photoUrl} alt='person profile' className='profile' onClick={() => inputRef.current.click()} />}
                    {preview != null && <img src={preview} alt='preview' className='profile' onClick={() => inputRef.current.click()} />}
                    {(formValues.photoUrl != null || preview != null) &&
                      <RadButton onClick={() => removeFile()}>Remove image</RadButton>}
                    {fileSizeTooLarge &&
                      <RadAlert
                        dismissible
                        statusIconAriaLabel='Error'
                        type='error'
                        header='File size too large'
                        onDismiss={() => setFileSizeTooLarge(false)}
                      >
                        Please select a file that is less than 10MB.
                      </RadAlert>}

                    <RadFormField label='Name' field='name' required>
                      <RadInput
                        ariaRequired
                        placeholder='Enter name'
                        value={formValues.name}
                        onChange={({ detail }) => setFormValues({ ...formValues, name: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Description' field='description' stretch>
                      <RadTextarea
                        rows={4}
                        placeholder='Enter description'
                        value={formValues.description}
                        onChange={({ detail }) => setFormValues({ ...formValues, description: detail.value })}
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}
