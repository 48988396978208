import React, { useState, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { Avatar } from '@rmwc/avatar'
import { useGet } from '../hooks/useGet'
import { usePut } from '../hooks/usePut'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadBreadcrumbGroup } from '../common/RadBreadcrumbGroup'
import { RadButton } from '../common/RadButton'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadContainer } from '../common/RadContainer'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadLink } from '../common/RadLink'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { CourseSchedule } from '../shared/CourseSchedule'
import { fullName } from '../common/utilities'

export function CourseEnrollmentEdit () {
  const navigate = useNavigate()
  const { courseId, courseEnrollmentId } = useParams()
  const [searchParams] = useSearchParams()
  const [formValues, setFormValues] = useState()
  const { data: enrollment } = useGet(courseEnrollmentId ? `/api/course-enrollment/${courseEnrollmentId}` : null)
  const { data: exitReasonOptions } = useGet('/api/option/exit_reason')
  const update = usePut(
    `/api/course-enrollment/${courseEnrollmentId}`,
    formValues,
    (resp) => { navigate(searchParams.get('redirectURL') ?? `/course/${courseId}/enrollment/${courseEnrollmentId}`) }
  )

  useEffect(() => {
    if (enrollment != null) {
      setFormValues(enrollment)
    }
  }, [enrollment])

  async function cancel () {
    navigate(searchParams.get('redirectURL') ?? `/course/${courseId}`)
  }

  async function saveChanges () {
    update()
  }

  if (
    formValues != null &&
    exitReasonOptions != null
  ) {
    return (
      <RadAppLayout
        breadcrumbs={
          <RadBreadcrumbGroup
            items={[
              { text: 'Dashboard', href: '/' },
              { text: 'Courses', href: '/course' },
              { text: 'Course # ' + enrollment.courseId, href: '/course/' + enrollment.courseId },
              { text: 'Enrollment # ' + enrollment.id, href: `/course/${enrollment.courseId}/enrollment/${enrollment.id}` },
              { text: 'Edit', href: '#' }
            ]}
            ariaLabel='Breadcrumbs'
          />
        }
        contentHeader={
          <RadHeader variant='h1'>{courseEnrollmentId ? 'Edit' : 'New'} Enrollment</RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <Course course={enrollment.course} />
            <form onSubmit={e => e.preventDefault()}>
              <RadForm
                actions={
                  <RadSpaceBetween direction='horizontal' size='xs'>
                    <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                    <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                  </RadSpaceBetween>
                }
              >
                <RadSpaceBetween size='l'>
                  <Details
                    formValues={formValues}
                    setFormValues={setFormValues}
                    enrollment={enrollment}
                    exitReasonOptions={exitReasonOptions}
                  />
                  <Certificates
                    formValues={formValues}
                    setFormValues={setFormValues}
                    enrollment={enrollment}
                  />
                </RadSpaceBetween>
              </RadForm>
            </form>
          </RadSpaceBetween>
        }
      />
    )
  }
}

function Course ({ course, settings }) {
  return (
    <RadContainer
      header={
        <RadHeader variant='h2'>
          Course # {course.id}
        </RadHeader>
      }
    >
      <RadSpaceBetween size='l'>
        <RadColumnLayout columns={3} borders='vertical'>
          <div>
            <RadBox variant='awsui-key-label' padding={{ bottom: 's' }}>
              Cohort
            </RadBox>
            <RadLink variant='primary' href={`/cohort/${course.cohort.id}`}>{course.cohort.name ?? '-'}</RadLink>
          </div>
          <div>
            <RadBox variant='awsui-key-label' padding={{ bottom: 'xxs' }}>
              Program
            </RadBox>
            <RadSpaceBetween direction='horizontal'>
              <Avatar
                src={course.program.photoUrl ?? '/program_icon.webp'}
                size='large'
                referrerPolicy='no-referrer'
              />
              <RadBox padding={{ left: 's', top: 'xs' }}>
                <RadLink variant='primary' href={`/program/${course.program.id}`}>{course.program.name}</RadLink>
              </RadBox>
            </RadSpaceBetween>
          </div>
          <div>
            <RadBox variant='awsui-key-label' padding={{ bottom: 'xxs' }}>
              Instructor(s)
            </RadBox>
            <RadSpaceBetween size='xxs'>
              {course.instructors.map(x =>
                <RadSpaceBetween key={'instructor-' + x.id} direction='horizontal'>
                  <Avatar
                    src={x.instructor.photoUrl}
                    size='large'
                    name={fullName(x.instructor)}
                    referrerPolicy='no-referrer'
                  />
                  <RadBox padding={{ left: 's', top: 'xs' }}>
                    <RadLink variant='primary' href={`/instructor/${x.instructor.id}`}>{fullName(x.instructor)}</RadLink>
                  </RadBox>
                </RadSpaceBetween>
              )}
            </RadSpaceBetween>
          </div>
        </RadColumnLayout>
        <RadColumnLayout columns={3} borders='vertical'>
          <div>
            <RadBox variant='awsui-key-label'>
              Campus / Room
            </RadBox>
            {course.campus.name} {course.room ? ` / ${course.room}` : ''}
          </div>
          <div>
            <RadBox variant='awsui-key-label'>
              Capacity
            </RadBox>
            {course.capacity}
          </div>
          <div>
            <RadBox variant='awsui-key-label'>
              Schedule
            </RadBox>
            <CourseSchedule course={course} />
          </div>
        </RadColumnLayout>
      </RadSpaceBetween>
    </RadContainer>
  )
}

function Details ({ formValues, setFormValues, enrollment, exitReasonOptions }) {
  return (
    <RadContainer
      header={
        <RadHeader variant='h2'>
          Details
        </RadHeader>
      }
    >
      <RadSpaceBetween size='l'>
        <div>
          <RadBox variant='awsui-key-label'>
            Person
          </RadBox>
          <RadLink href={`/person/${enrollment.person.id}`}>{fullName(enrollment.person)}</RadLink>
        </div>
        <RadGrid
          gridDefinition={[
            { colspan: { default: 12, xs: 3 } },
            { colspan: { default: 12, xs: 3 } }
          ]}
        >
          <RadFormField label='Start Date' field='startDate'>
            <RadInput
              ariaRequired
              type='date'
              value={formValues.startDate}
              onChange={({ detail }) => setFormValues({ ...formValues, startDate: detail.value })}
            />
          </RadFormField>
          <RadFormField label='End Date' field='endDate'>
            <RadInput
              ariaRequired
              type='date'
              value={formValues.endDate}
              onChange={({ detail }) => {
                if (detail.value !== enrollment.course.cohort.endDate) {
                  console.log(parseInt(exitReasonOptions[0].value))
                  setFormValues({
                    ...formValues,
                    endDate: detail.value,
                    exitReasonId: formValues.exitReasonId ?? parseInt(exitReasonOptions[0].value)
                  })
                } else {
                  setFormValues({ ...formValues, endDate: detail.value, exitReasonId: null })
                }
              }}
            />
          </RadFormField>
        </RadGrid>
        {formValues.endDate !== enrollment.course.cohort.endDate &&
          <RadFormField label='Exit Reason' field='exitReasonId' required>
            <RadSelect
              selectedOption={
              formValues.exitReasonId
                ? exitReasonOptions.find(x => x.value === formValues.exitReasonId?.toString())
                : { value: '', label: '-' }
            }
              onChange={({ detail }) => {
                if (detail.selectedOption.value !== '') {
                  setFormValues({ ...formValues, exitReasonId: parseInt(detail.selectedOption.value) })
                } else {
                  setFormValues({ ...formValues, exitReasonId: null })
                }
              }}
              options={exitReasonOptions}
              selectedAriaLabel='Selected'
              empty='No matches found'
            />
          </RadFormField>}
      </RadSpaceBetween>
    </RadContainer>
  )
}

function Certificates ({ formValues, setFormValues, enrollment }) {
  const { data: certificateOptions } = useGet('/api/option/certificate')

  if (certificateOptions != null) {
    return (
      <RadContainer
        header={
          <RadHeader
            counter={'(' + enrollment.certificates.length + ')'}
            variant='h2'
          >
            Certificates
          </RadHeader>
        }
      >
        <RadSpaceBetween size='l'>
          {formValues.certificates.length > 0 &&
            <RadSpaceBetween size='xxs'>
              {formValues.certificates.map((item) =>
                <CertificateEditor
                  key={`certificate-${item.id ?? item.uuid}`}
                  item={item}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  certificateOptions={certificateOptions}
                />
              )}
            </RadSpaceBetween>}
          {formValues.certificates.length === 0 &&
            <RadBox color='text-status-inactive'>No certificates added to enrollment.</RadBox>}
          <RadButton
            onClick={() => {
              const certificates = formValues.certificates
              certificates.push({ uuid: uuidv4(), certificate: {} })
              setFormValues({ ...formValues, certificates })
            }}
          >
            Add certificate
          </RadButton>
        </RadSpaceBetween>
      </RadContainer>
    )
  }
}

function CertificateEditor ({ item, formValues, setFormValues, certificateOptions }) {
  const certificates = formValues.certificates

  return (
    <RadSpaceBetween size='l'>
      <RadGrid
        gridDefinition={[
          { colspan: { default: 12, xs: 4 } },
          { colspan: { default: 12, xs: 2 } },
          { colspan: { default: 12, xs: 6 } }
        ]}
      >
        <RadFormField
          label={item === formValues.certificates[0] ? 'Name' : null}
          field={`certificate.${item.id ?? item.uuid}.certificateId`}
          required={item === formValues.certificates[0]}
        >
          <RadSelect
            filteringType='auto'
            selectedOption={certificateOptions.find(x => parseInt(x.value) === item.certificateId)}
            onChange={({ detail }) => {
              item.certificateId = parseInt(detail.selectedOption.value)
              setFormValues({ ...formValues, certificates })
            }}
            options={certificateOptions}
            enteredTextLabel={value => value}
            selectedAriaLabel='Selected'
            placeholder='Choose a certificate'
            empty='No matches found'
          />
        </RadFormField>
        <RadFormField
          label={item === formValues.certificates[0] ? 'Date' : null}
          field={`certificate.${item.id ?? item.uuid}.certificateId`}
          required={item === formValues.certificates[0]}
        >
          <RadInput
            ariaRequired
            type='date'
            value={item.date}
            onChange={({ detail }) => {
              item.date = detail.value
              setFormValues({ ...formValues, certificates })
            }}
          />
        </RadFormField>
        <RadBox
          padding={{ top: item === formValues.certificates[0] ? 'xl' : 'xxs' }}
        >
          <RadButton
            wrapText={false}
            onClick={() => {
              const certificates = formValues.certificates.filter((x) => x.id !== item.id || x.uuid !== item.uuid)
              setFormValues({ ...formValues, certificates })
            }}
          >
            Remove certificate
          </RadButton>
        </RadBox>
      </RadGrid>
    </RadSpaceBetween>
  )
}
