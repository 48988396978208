import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadContainer } from '../common/RadContainer'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { formatDateTime } from '../common/utilities'

export function SchoolDetail () {
  const navigate = useNavigate()
  const { schoolId } = useParams()
  const { data: school } = useGet(`/api/school/${schoolId}`, true)
  const confirmDelete = useConfirm('Delete school?', 'Delete school permanently? This action cannot be undone.', 'Delete', () => { remove() })
  const remove = useDelete(`/api/school/${schoolId}`, () => { navigate('/school') })

  if (school != null) {
    return (
      <RadAppLayout
        name={school.name}
        contentHeader={
          <RadHeader
            variant='h1'
            description={school.description}
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('edit')}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={school.isProtected}>Delete</RadButton>
              </RadSpaceBetween>
            }
          >
            {school.name}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Details
                </RadHeader>
              }
            >
              <RadColumnLayout columns={1} borders='vertical'>
                <div>
                  <RadBox variant='awsui-key-label'>
                    County
                  </RadBox>
                  <div>{school.county ?? '-'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Created
                  </RadBox>
                  <div>{formatDateTime(school.createdAt)}</div>
                </div>
                {school.createdAt !== school.updatedAt &&
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Updated
                    </RadBox>
                    <div>{formatDateTime(school.updatedAt)}</div>
                  </div>}
              </RadColumnLayout>
            </RadContainer>
          </RadSpaceBetween>
        }
      />
    )
  }
}
