import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { Avatar } from '@rmwc/avatar'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadCards } from '../common/RadCards'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadLink } from '../common/RadLink'
import { RadMultiselect } from '../common/RadMultiselect'
import { RadPagination } from '../common/RadPagination'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTextFilter } from '../common/RadTextFilter'
import { AttendancePercentage } from '../shared/AttendancePercentage'
import { formatDate, fullName } from '../common/utilities'

export function PersonList () {
  const pageLength = 20
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentPageIndex, setCurrentPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const [filteringText, setFilteringText] = useState(searchParams.get('search') ?? '')
  const [searchText, setSearchText] = useState(searchParams.get('search') ?? '')
  const [status, setStatus] = useState(searchParams.get('status') ?? '')
  const [tagIds, setTagIds] = useState(searchParams.get('tags') ?? '')
  const { data: people, count, info } = useGet(
    '/api/person' +
    `?search=${searchText}` +
    `&status=${status}` +
    `&tags=${tagIds ?? ''}` +
    `&limit=${pageLength}` +
    `&offset=${(currentPageIndex - 1) * pageLength}`
  )
  const { data: tagOptions } = useGet('/api/option/tag')
  const statusOptions = [
    { value: '', label: 'All People' },
    { value: 'enrolled', label: 'Currently Enrolled' },
    { value: 'completed', label: 'Completed A Course' },
    { value: 'exited', label: 'Exited A Course' }
  ]

  function escapeRegExp (string) {
    if (string == null) return null
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
  }

  const highlightMatch = (text) => {
    if (text == null || searchText == null) return null
    const escapedSearchText = escapeRegExp(searchText)
    const parts = text.split(new RegExp(`(${escapedSearchText})`, 'gi'))
    return (
      <span>{parts.map((part, i) =>
        part.toLowerCase() === searchText.toLowerCase()
          ? <span key={i} className='highlight'>{part}</span>
          : part
      )}
      </span>
    )
  }

  if (
    people != null &&
    tagOptions != null
  ) {
    return (
      <RadAppLayout
        contentHeader={
          <RadHeader
            variant='h1'
            counter={'(' + Number(count).toLocaleString() + ')'}
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('/person/create')}>Create</RadButton>
              </RadSpaceBetween>
            }
          >
            People
          </RadHeader>
        }
        content={
          <RadCards
            ariaLabels={{
              itemSelectionLabel: (e, t) => `select ${t.name}`,
              selectionGroupLabel: 'Item selection'
            }}
            cardDefinition={{
              header: item => (
                <RadLink fontSize='heading-m' href={`/person/${item.id}`}>
                  <RadSpaceBetween size='xs'>
                    <Avatar
                      src={item.photoUrl}
                      size='large'
                      name={`${item.firstName} ${item.lastName}`}
                      referrerPolicy='no-referrer'
                    />
                    {highlightMatch(fullName(item))}
                  </RadSpaceBetween>
                </RadLink>
              ),
              sections: [
                {
                  id: 'info',
                  content: item =>
                    <RadSpaceBetween size='xs'>
                      <div>
                        {highlightMatch(item.address?.line1)}
                        {item.address?.line2 && <br />}
                        {highlightMatch(item.address?.line2)}
                        <br />
                        {highlightMatch(`${item.address?.city}, ${item.address?.state} ${item.address?.zip}`)}
                      </div>
                      <div>
                        {item.email != null && <RadLink href={`mailto:${item.email}`}>{highlightMatch(item.email)}</RadLink>}
                        {item.phone != null && <div>{highlightMatch(item.phone)}</div>}
                        {/* {item.birthdate != null && <div>{highlightMatch(formatDate(item.birthdate))}</div>} */}
                      </div>
                      <RadSpaceBetween direction='horizontal' size='xs'>
                        <RadBox variant='awsui-key-label'>Birthdate</RadBox>
                        <div>{formatDate(item.birthdate)}</div>
                      </RadSpaceBetween>
                      {item.attendance != null && <AttendancePercentage item={{ attendance: { present: item.attendance, total: 100 } }} />}
                    </RadSpaceBetween>
                }
              ]
            }}
            cardsPerRow={[
              { cards: 1 },
              { minWidth: 640, cards: 2 },
              { minWidth: 960, cards: 3 },
              { minWidth: 1280, cards: 4 }
            ]}
            items={people}
            filter={
              <RadGrid
                gridDefinition={[
                  { colspan: { default: 12, xs: 6 } },
                  { colspan: { default: 12, xs: 3 } },
                  { colspan: { default: 12, xs: 3 } }
                ]}
              >
                <RadTextFilter
                  filteringText={filteringText}
                  filteringPlaceholder='Search people'
                  filteringAriaLabel='Filter people'
                  onChange={({ detail }) => setFilteringText(detail.filteringText)}
                  onDelayedChange={({ detail }) => {
                    setSearchText(detail.filteringText ?? '')
                    if (detail.filteringText) {
                      searchParams.set('search', detail.filteringText)
                    } else {
                      searchParams.delete('search')
                    }
                    searchParams.delete('page')
                    setCurrentPageIndex(1)
                    setSearchParams(searchParams)
                  }}
                />
                <RadSelect
                  selectedOption={statusOptions.find(x => x.value === status) ?? { value: '', label: '-' }}
                  onChange={({ detail }) => {
                    searchParams.set('status', detail.selectedOption.value)
                    setSearchParams(searchParams)
                    setStatus(detail.selectedOption.value)
                    setCurrentPageIndex(1)
                  }}
                  options={statusOptions}
                  selectedAriaLabel='Selected'
                  deselectAriaLabel={e => `Remove ${e.label}`}
                  placeholder='Choose status'
                  keepOpen={false}
                />
                <RadMultiselect
                  filteringType='auto'
                  selectedOptions={tagOptions.filter((x) => tagIds.split('-').includes(x.value))}
                  onChange={({ detail }) => {
                    const ids = detail.selectedOptions.map((x) => x.value).join('-')
                    if (ids === '') {
                      searchParams.delete('tags')
                    } else {
                      searchParams.set('tags', ids)
                    }
                    setTagIds(ids)
                    searchParams.delete('page')
                    setCurrentPageIndex(1)
                    setSearchParams(searchParams)
                  }}
                  options={tagOptions}
                  selectedAriaLabel='Selected'
                  deselectAriaLabel={e => `Remove ${e.label}`}
                  placeholder='Choose tags'
                  keepOpen={false}
                />
              </RadGrid>
            }
            pagination={
              <RadPagination
                currentPageIndex={currentPageIndex}
                pagesCount={Math.ceil(count / pageLength)}
                onChange={({ detail }) => {
                  searchParams.set('page', detail.currentPageIndex)
                  setSearchParams(searchParams)
                  setCurrentPageIndex(detail.currentPageIndex)
                }}
                ariaLabels={{
                  nextPageLabel: 'Next page',
                  previousPageLabel: 'Previous page',
                  pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                }}
              />
            }
            variant='full-page'
            empty={
              <RadBox textAlign='center' color='inherit'>
                {info ?? 'No matches found'}
              </RadBox>
            }
          />
        }
      />
    )
  }
}
