import React from 'react'
import { RadBox } from '../common/RadBox'
import { RadPieChart } from '../common/RadPieChart'

export function AttendancePieChart ({ sessions }) {
  const totalSessions = sessions.reduce((acc, x) => acc + x.total, 0)
  const totalPresent = sessions.reduce((acc, x) => acc + x.present, 0)
  return (
    <RadPieChart
      ariaDescription='Donut chart showing absences.'
      ariaLabel='Donut chart'
      data={[
        {
          title: 'Present',
          value: totalPresent,
          color: '#67a353'
        },
        {
          title: 'Absent',
          value: totalSessions - totalPresent,
          color: '#d63f38'
        }
      ]}
      empty={
        <RadBox textAlign='center' color='inherit'>
          <b>Attendance</b>
          <RadBox variant='p' color='inherit'>
            There is no data available
          </RadBox>
        </RadBox>
    }
      innerMetricDescription='Attendance'
      innerMetricValue={(totalPresent / totalSessions * 100).toFixed(0) + ' %'}
      size='medium'
      variant='donut'
      hideFilter
      // hideLegend={totalSessions === 0}
      hideLegend
      hideTitles
      hideDescriptions
    />
  )
}
