import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadButton } from '../common/RadButton'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadContainer } from '../common/RadContainer'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { AttendancePieChart } from '../charts/AttendancePieChart'
import { EthnicitiesPieChart } from '../charts/EthnicitiesPieChart'
import { GenderPieChart } from '../charts/GenderPieChart'
import { RacePieChart } from '../charts/RacePieChart'
import { SessionAttendanceBarChart } from '../charts/SessionAttendanceBarChart'
import { PhotoUrlHeader } from '../shared/PhotoUrlHeader'
import { CourseCards } from '../cards/CourseCards'

export function ProgramDetail () {
  const navigate = useNavigate()
  const { programId } = useParams()
  const { data: program } = useGet(`/api/program/${programId}?attendance&demographics`, true)
  const confirmDelete = useConfirm(
    'Delete program?',
    'Delete program permanently? This action cannot be undone.',
    'Delete',
    () => { remove() }
  )
  const remove = useDelete(`/api/program/${programId}`, () => { navigate('/program') })

  if (
    program != null
  ) {
    return (
      <RadAppLayout
        name={program.name}
        contentHeader={
          <RadHeader
            variant='h1'
            description={program.description}
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('edit')}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={program.isProtected}>Delete</RadButton>
              </RadSpaceBetween>
            }
          >
            {program.name}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <PhotoUrlHeader photoUrl={program.photoUrl} />
            <DemographicsAndAttendance program={program} />
            <CourseCards courses={program.courses} />
          </RadSpaceBetween>
        }
      />
    )
  }
}

function DemographicsAndAttendance ({ program }) {
  return (
    <RadContainer
      header={
        <RadHeader variant='h2'>
          Demographics and Attendance
        </RadHeader>
      }
    >
      <RadSpaceBetween size='l'>
        <RadColumnLayout columns={4}>
          <GenderPieChart genders={program.demographics.genders} />
          <RacePieChart races={program.demographics.races} />
          <EthnicitiesPieChart ethnicities={program.demographics.ethnicities} />
          <AttendancePieChart sessions={program.sessions} />
        </RadColumnLayout>
        <SessionAttendanceBarChart courses={program.courses} sessions={program.sessions} />
      </RadSpaceBetween>
    </RadContainer>
  )
}
