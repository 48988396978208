import React from 'react'
import { RadCheckbox } from '../common/RadCheckbox'
import { RadFormField } from '../common/RadFormField'
import { RadGrid } from '../common/RadGrid'
import { RadInput } from '../common/RadInput'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { stateList } from '../common/stateList'

export function PersonContactInformationFormFields ({ formValues, setFormValues }) {
  const stateListOptions = [...stateList]

  return (
    <RadSpaceBetween size='l'>
      <RadGrid
        gridDefinition={[
          { colspan: { default: 12, s: 9 } },
          { colspan: { default: 12, s: 3 } }
        ]}
      >
        <div>
          <RadFormField label='Street' field='address.line1'>
            <RadInput
              placeholder='Enter line 1'
              value={formValues.address?.line1}
              onChange={(evt) => { setFormValues({ ...formValues, address: { ...formValues.address, line1: evt.detail.value } }) }}
            />
          </RadFormField>
          <RadFormField field='address.line2'>
            <RadInput
              placeholder='Enter line 2'
              value={formValues.address?.line2}
              onChange={(evt) => { setFormValues({ ...formValues, address: { ...formValues.address, line2: evt.detail.value } }) }}
            />
          </RadFormField>
        </div>
        <div />
      </RadGrid>

      <RadGrid
        gridDefinition={[
          { colspan: { default: 12, s: 3 } },
          { colspan: { default: 12, s: 3 } },
          { colspan: { default: 12, s: 2 } }
        ]}
      >
        <RadFormField label='City' field='address.city'>
          <RadInput
            placeholder='Enter city'
            value={formValues.address?.city}
            onChange={(evt) => { setFormValues({ ...formValues, address: { ...formValues.address, city: evt.detail.value } }) }}
          />
        </RadFormField>
        <RadFormField label='State' field='address.state'>
          <RadSelect
            filteringType='auto'
            placeholder='Choose a state'
            options={stateListOptions}
            selectedOption={formValues.address?.state != null ? stateListOptions.find(x => x.value === formValues.address.state) : null}
            onChange={({ detail }) => { setFormValues({ ...formValues, address: { ...formValues.address, state: detail.selectedOption.value } }) }}
          />
        </RadFormField>
        <RadFormField label='ZIP' field='address.zip'>
          <RadInput
            placeholder='Enter ZIP code'
            inputMode='numeric'
            value={formValues.address?.zip}
            onChange={(evt) => { setFormValues({ ...formValues, address: { ...formValues.address, zip: evt.detail.value } }) }}
          />
        </RadFormField>
      </RadGrid>
      <RadGrid
        gridDefinition={[
          { colspan: { default: 12, s: 3 } },
          { colspan: { default: 12, s: 3 } },
          { colspan: { default: 12, s: 3 } }
        ]}
      >
        <RadFormField label='Email' field='email' required>
          <RadInput
            ariaRequired
            type='email'
            inputMode='email'
            placeholder='Enter email'
            value={formValues.email}
            onChange={({ detail }) => setFormValues({ ...formValues, email: detail.value })}
          />
        </RadFormField>
        <RadFormField label='Phone' field='phone' required>
          <RadInput
            ariaRequired
            type='tel'
            inputMode='tel'
            placeholder='###-###-####'
            value={formValues.phone}
            onChange={({ detail }) => setFormValues({ ...formValues, phone: detail.value })}
          />
        </RadFormField>
        <RadFormField label='Permission to Text' field='smsPermission'>
          <RadCheckbox
            checked={formValues.smsPermission}
            onChange={({ detail }) => setFormValues({ ...formValues, smsPermission: detail.checked })}
          />
        </RadFormField>
      </RadGrid>
    </RadSpaceBetween>
  )
}
