import { useContext } from 'react'
import { AppContext } from './../App'
import { trimStringsAndRemoveEmpty } from '../common/utilities'

export function usePut (uri, data, onSuccess) {
  const { user, setLoadingCount, reloadCounter, setReloadCounter, setError, setNotFound, setPermissionDenied } = useContext(AppContext)

  async function put () {
    setLoadingCount(count => count + 1)
    const headers = { 'Content-Type': 'application/json' }
    if (user != null) {
      const token = await user.getIdToken()
      headers.Authorization = `Bearer ${token}`
    }
    const response = await fetch(uri, {
      method: 'PUT',
      headers,
      body: JSON.stringify(trimStringsAndRemoveEmpty(data))
    })
    let json = {}
    let text = ''

    switch (response.status) {
      case 200:
        try { json = await response.json() } catch { }
        setReloadCounter(reloadCounter + 1)
        // Chipi: show snackbar
        setError(null)
        setNotFound(false)
        setPermissionDenied(null)
        if (onSuccess != null) onSuccess(json)
        break
      case 400:
        try { json = await response.json() } catch { }
        setError({ ...json, code: 'Validation Error' })
        break
      case 403:
        try { text = await response.text() } catch { }
        setError({ code: 'Permission Denied', message: text })
        break
      case 404:
        setError({ code: 'Not Found' }) // Chipi could be a bad uri, could be the think your trying to update is missing
        break
      case 500:
        try { json = await response.json() } catch { }
        if (json.name === 'SequelizeForeignKeyConstraintError') {
          setError({ code: 'Unprocessable', message: 'Unable to delete. Resource is in use.' })
          // Chipi - id rather get a 422 from the server
        } else if (json.name === 'SequelizeUniqueConstraintError') {
          setError({ code: 'Unprocessable', message: json.message })
        } else {
          setError({ code: 'Error', message: 'Please contact administrator.' })
        }
        break
      default:
        try { text = await response.text() } catch { }
        setError({ code: response.statusText, message: text })
    }
    setLoadingCount(count => count - 1)
  }

  return put
}
