import React, { useState, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import moment from 'moment-timezone'
import { v4 as uuidv4 } from 'uuid'
import { useIsMobile } from '../hooks/useIsMobile'
import { useGet } from '../hooks/useGet'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadCheckbox } from '../common/RadCheckbox'
import { RadContainer } from '../common/RadContainer'
import { RadDivider } from '../common/RadDivider'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadSelect } from '../common/RadSelect'
import { RadTextarea } from '../common/RadTextarea'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function CourseEdit () {
  moment.tz.setDefault('America/Chicago')
  const navigate = useNavigate()
  const { courseId } = useParams()
  const [searchParams] = useSearchParams()
  const [formValues, setFormValues] = useState()
  const programId = searchParams.get('programId')
  const { data: course } = useGet(courseId != null ? `/api/course/${courseId}` : null)
  const { data: cohortOptions } = useGet('/api/option/cohort?enrollable=true')
  const create = usePost(
    '/api/course',
    formValues,
    (resp) => { navigate(searchParams.get('redirectURL') ?? `/course/${resp.id}`) }
  )
  const update = usePut(
    `/api/course/${courseId}`,
    formValues,
    (resp) => { navigate(searchParams.get('redirectURL') ?? `/course/${courseId}`) }
  )

  useEffect(() => {
    const cohortId = searchParams.get('cohortId') != null ? parseInt(searchParams.get('cohortId')) : null
    const defaultFormValues = {
      cohortId,
      programId,
      instructors: [],
      schedules: []
    }
    setFormValues(course ?? defaultFormValues)
  }, [cohortOptions, course, programId])

  async function cancel () {
    if (courseId != null) {
      navigate(searchParams.get('redirectURL') ?? '/course')
    } else {
      navigate(searchParams.get('redirectURL') ?? `/course/${courseId}`)
    }
  }

  async function saveChanges () {
    if (courseId != null) {
      update()
    } else {
      create()
    }
  }

  if (
    formValues != null &&
    cohortOptions != null
  ) {
    return (
      <RadAppLayout
        name={'Course # ' + course?.id}
        contentHeader={
          <RadHeader variant='h1'>{courseId ? 'Edit' : 'New'} Course</RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>
                    Save Changes
                  </RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <Details formValues={formValues} setFormValues={setFormValues} cohortOptions={cohortOptions} />
                <Instructors formValues={formValues} setFormValues={setFormValues} />
                <Schedules formValues={formValues} setFormValues={setFormValues} cohortOptions={cohortOptions} />
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}

function Details ({ formValues, setFormValues, cohortOptions }) {
  const { data: campusOptions } = useGet('/api/option/campus')
  const { data: programOptions } = useGet('/api/option/program')

  if (
    campusOptions != null &&
    programOptions != null
  ) {
    return (
      <RadContainer
        header={
          <RadHeader variant='h2'>
            Details
          </RadHeader>
      }
      >
        <RadSpaceBetween size='l'>
          <RadFormField label='Cohort' field='cohortId' required>
            <RadSelect
              selectedOption={
              formValues.cohortId
                ? cohortOptions.find(x => x.value === formValues.cohortId?.toString())
                : { value: '', label: '-' }
            }
              onChange={({ detail }) => {
                if (detail.selectedOption.value !== '') {
                  const cohort = cohortOptions.find(x => x.value === detail.selectedOption.value)
                  const schedules = formValues.schedules.map(x => ({
                    ...x,
                    startDate: x.startDate ?? cohort.startDate,
                    endDate: x.endDate ?? cohort.endDate
                  }))
                  setFormValues({ ...formValues, cohortId: parseInt(detail.selectedOption.value), schedules })
                } else {
                  setFormValues({ ...formValues, cohortId: null })
                }
              }}
              options={cohortOptions}
              selectedAriaLabel='Selected'
              empty='No matches found'
            />
          </RadFormField>
          <RadFormField label='Program' field='programId' required>
            <RadSelect
              selectedOption={formValues.programId ? programOptions.find(x => x.value === formValues.programId?.toString()) : { value: '', label: '-' }}
              onChange={({ detail }) => {
                if (detail.selectedOption.value !== '') {
                  setFormValues({ ...formValues, programId: parseInt(detail.selectedOption.value) })
                } else {
                  setFormValues({ ...formValues, programId: null })
                }
              }}
              options={programOptions}
              selectedAriaLabel='Selected'
              empty='No matches found'
            />
          </RadFormField>
          <RadFormField label='Description' field='description' stretch>
            <RadTextarea
              rows={4}
              placeholder='Enter description'
              value={formValues.description}
              onChange={({ detail }) => setFormValues({ ...formValues, description: detail.value })}
            />
          </RadFormField>
          <RadGrid
            gridDefinition={[
              { colspan: { default: 12, xs: 4 } },
              { colspan: { default: 12, xs: 3 } }
            ]}
          >
            <RadFormField label='Campus' field='campusId' required>
              <RadSelect
                selectedOption={formValues.campusId ? campusOptions.find(x => x.value === formValues.campusId?.toString()) : { value: '', label: '-' }}
                onChange={({ detail }) => {
                  if (detail.selectedOption.value !== '') {
                    setFormValues({ ...formValues, campusId: parseInt(detail.selectedOption.value) })
                  } else {
                    setFormValues({ ...formValues, campusId: null })
                  }
                }}
                options={campusOptions}
                selectedAriaLabel='Selected'
                empty='No matches found'
              />
            </RadFormField>
            <RadFormField label='Room' field='room'>
              <RadInput
                ariaRequired
                type='number'
                placeholder='Enter room'
                value={formValues.room}
                onChange={({ detail }) => setFormValues({ ...formValues, room: detail.value })}
              />
            </RadFormField>

          </RadGrid>
          <RadGrid
            gridDefinition={[
              { colspan: { default: 12, xs: 2 } }
            ]}
          >
            <RadFormField label='Capacity' field='capacity' required>
              <RadInput
                ariaRequired
                type='number'
                placeholder='Enter capacity'
                value={formValues.capacity}
                onChange={({ detail }) => setFormValues({ ...formValues, capacity: detail.value })}
              />
            </RadFormField>
          </RadGrid>
        </RadSpaceBetween>
      </RadContainer>
    )
  }
}

function Instructors ({ formValues, setFormValues, cohortOptions }) {
  const { data: instructorOptions } = useGet('/api/option/instructor')

  if (instructorOptions != null) {
    return (
      <RadContainer
        header={
          <RadHeader
            variant='h2'
            counter={'(' + formValues.instructors.length + ')'}
          >
            Instructors
          </RadHeader>
      }
      >
        <RadSpaceBetween size='l'>
          {formValues.instructors.map((x) =>
            <InstructorEditor
              key={`instructor-${x.id},${x.uuid}`}
              item={x}
              formValues={formValues}
              setFormValues={setFormValues}
              instructorOptions={instructorOptions}
            />)}
          <RadButton
            onClick={() => {
              const instructors = formValues.instructors
              instructors.push({ uuid: uuidv4(), instructor: {} })
              setFormValues({ ...formValues, instructors })
            }}
          >
            Add new instructor
          </RadButton>
        </RadSpaceBetween>
      </RadContainer>
    )
  }
}

function InstructorEditor ({ item, formValues, setFormValues, instructorOptions }) {
  const isMobile = useIsMobile()
  const instructors = formValues.instructors
  const removeButton = (
    <RadButton
      onClick={() => {
        const instructors = formValues.instructors
          .filter((x) => x.id !== item.id || x.uuid !== item.uuid)
        setFormValues({ ...formValues, instructors })
      }}
    >
      Remove instructor
    </RadButton>
  )
  const filteredInstructorOptions = instructorOptions
    .filter(x => !formValues.instructors.some(y => y.instructor.id === parseInt(x.value)))

  return (
    <RadSpaceBetween size='l'>
      <RadGrid
        gridDefinition={[
          { colspan: { default: 12, xs: 8 } },
          { colspan: { default: 12, xs: 4 } }
        ]}
      >
        <RadFormField label='Instructor' field={`instructor.${item.id ?? item.uuid}.instructorId`} required>
          <RadSelect
            selectedOption={
              item.instructor.id != null
                ? instructorOptions.find(x => x.value === item.instructor.id?.toString())
                : { value: '', label: '-' }
}
            onChange={({ detail }) => {
              item.instructor = {
                id: parseInt(detail.selectedOption.value),
                name: detail.selectedOption.label
              }
              setFormValues({ ...formValues, instructors })
            }}
            options={filteredInstructorOptions}
            selectedAriaLabel='Selected'
            empty='No matches found'
          />
        </RadFormField>
        {!isMobile && <RadBox padding={{ top: 'xl' }} float='right'>{removeButton}</RadBox>}
      </RadGrid>
      {isMobile && removeButton}
      {isMobile && <RadDivider />}
    </RadSpaceBetween>
  )
}

function Schedules ({ formValues, setFormValues, cohortOptions }) {
  const selectedCohort = cohortOptions.find(x => x.value === formValues.cohortId?.toString())

  return (
    <RadContainer
      header={
        <RadHeader
          variant='h2'
          counter={'(' + formValues.schedules.length + ')'}
        >
          Schedules
        </RadHeader>
      }
    >
      <RadSpaceBetween size='l'>
        {formValues.schedules.map((x) =>
          <ScheduleEditor
            key={`schedule-${x.id},${x.uuid}`}
            item={x}
            formValues={formValues}
            setFormValues={setFormValues}
          />)}
        <RadButton
          onClick={() => {
            const schedules = formValues.schedules
            schedules.push({
              uuid: uuidv4(),
              recurrence: 'weekly',
              startDate: moment.max(moment(selectedCohort?.startDate ?? moment()), moment()).format('YYYY-MM-DD'),
              endDate: selectedCohort?.endDate,
              weekdays: []
            })
            setFormValues({ ...formValues, schedules })
          }}
        >
          Add new schedule
        </RadButton>
      </RadSpaceBetween>
    </RadContainer>

  )
}

function ScheduleEditor ({ item, formValues, setFormValues }) {
  const isMobile = useIsMobile()
  const schedules = formValues.schedules
  const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const recurrenceOptions = [
    { value: 'daily', label: 'Daily' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'none', label: 'None' }
  ]
  const removeButton = (
    <RadButton
      onClick={() => {
        const schedules = formValues.schedules
          .filter((x) => x.id !== item.id || x.uuid !== item.uuid)
        setFormValues({ ...formValues, schedules })
      }}
    >
      Remove schedule
    </RadButton>
  )

  return (
    <RadSpaceBetween size='l'>
      <RadGrid
        gridDefinition={[
          { colspan: { default: 12, s: 2 } },
          { colspan: { default: 12, s: 2 } },
          { colspan: { default: 12, s: 2 } },
          { colspan: { default: 12, s: 6 } }
        ]}
      >
        <RadFormField label='Recurrence' field={`schedule.${item.id ?? item.uuid}.recurrence`} required>
          <RadSelect
            selectedOption={recurrenceOptions.find(x => x.value === item.recurrence)}
            onChange={({ detail }) => {
              item.recurrence = detail.selectedOption.value
              if (item.recurrence === 'none') { item.endDate = item.startDate }
              setFormValues({ ...formValues, schedules })
            }}
            options={recurrenceOptions}
            selectedAriaLabel='Selected'
            empty='No matches found'
          />
        </RadFormField>
        <RadFormField label='Start Date' field={`schedule.${item.id ?? item.uuid}.startDate`} required>
          <RadInput
            type='date'
            placeholder='Enter start date'
            value={item.startDate}
            onChange={({ detail }) => {
              item.startDate = detail.value
              if (item.recurrence === 'none') { item.endDate = detail.value }
              setFormValues({ ...formValues, schedules })
            }}
          />
        </RadFormField>
        {item.recurrence !== 'none' &&
          <RadFormField label='End Date' field={`schedule.${item.id ?? item.uuid}.endDate`} required>
            <RadInput
              type='date'
              placeholder='Enter end date'
              value={item.endDate}
              onChange={({ detail }) => {
                item.endDate = detail.value
                setFormValues({ ...formValues, schedules })
              }}
            />
          </RadFormField>}
        {!isMobile && <RadBox padding={{ top: 'xl' }} float='right'>{removeButton}</RadBox>}
      </RadGrid>
      {item.recurrence === 'weekly' &&
        <RadFormField label='Days' field={`schedule.${item.id ?? item.uuid}.weekdays`} stretch>
          <RadSpaceBetween direction='horizontal' size='xxs'>
            {weekdays.map((x) =>
              <RadBox key={x} padding={{ right: 'l', bottom: 'xxs' }}>
                <RadCheckbox
                  checked={item.weekdays.includes(x.substring(0, 3).toLowerCase())}
                  onChange={({ detail }) => {
                    if (detail.checked) {
                      item.weekdays.push(x.substring(0, 3).toLowerCase())
                    } else {
                      item.weekdays = item.weekdays.filter((y) => y !== x.substring(0, 3).toLowerCase())
                    }
                    setFormValues({ ...formValues, schedules })
                  }}
                >
                  {x}
                </RadCheckbox>
              </RadBox>
            )}
          </RadSpaceBetween>
        </RadFormField>}
      <RadGrid
        gridDefinition={[
          { colspan: { default: 12, s: 2 } },
          { colspan: { default: 12, s: 2 } }
        ]}
      >
        <RadFormField label='Start Time' field={`schedule.${item.id ?? item.uuid}.startTime`} required>
          <RadInput
            type='time'
            placeholder='Enter start time'
            value={item.startTime}
            onChange={({ detail }) => {
              item.startTime = detail.value
              setFormValues({ ...formValues, schedules })
            }}
          />
        </RadFormField>
        <RadFormField label='End Time' field={`schedule.${item.id ?? item.uuid}.endTime`} required>
          <RadInput
            type='time'
            placeholder='Enter end time'
            value={item.endTime}
            onChange={({ detail }) => {
              item.endTime = detail.value
              setFormValues({ ...formValues, schedules })
            }}
          />
        </RadFormField>
      </RadGrid>
      {isMobile && removeButton}
      {item !== schedules[schedules.length - 1] && <RadDivider />}
    </RadSpaceBetween>
  )
}
