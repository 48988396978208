import React from 'react'
import PieChart from '@cloudscape-design/components/pie-chart'
import { RadBox } from '../common/RadBox'

// https://cloudscape.design/components/pie-chart
export function RadPieChart ({ empty, emptyText, ...rest }) {
  const defaultEmpty = (
    <RadBox textAlign='center' color='inherit'>
      <b>No data available</b>
      <RadBox variant='p' color='inherit'>
        {emptyText ?? 'There is no data available'}
      </RadBox>
    </RadBox>
  )

  return (
    <PieChart
      empty={empty ?? defaultEmpty}
      {...rest}
    />
  )
}
