import moment from 'moment-timezone'
import * as pluralizer from 'pluralize'

export function format (data, format, classes) {
  let pos = 0
  const acc = []
  const done = () => `${data.slice(0, pos)}${acc.join('')}`

  // Step 1: Take correctly-formatted prefix
  const matches = () => classes.hasOwnProperty(format[pos])
    ? classes[format[pos]](data[pos])
    : format[pos] === data[pos]
  const maxLen = Math.min(format.length, data.length)
  while (pos < maxLen && matches()) { pos++ }
  if (pos === maxLen) { return done() }

  // Step 2: Consume the rest according to the format
  let posF = pos
  let posD = pos
  const findNext = (f) => {
    while (posD < data.length) {
      const c = data[posD]
      posD++
      if (f(c)) { return c }
    }
    return null
  }
  while (posF < format.length && posD < data.length) {
    if (classes.hasOwnProperty(format[posF])) {
      const c = findNext(classes[format[posF]])
      if (c == null) { return done() }
      acc.push(c)
    } else {
      acc.push(format[posF])
    }
    posF++
  }
  return done()
}

export function isInteger (value) {
  if (value == null) return false
  return !isNaN(value) && value.toString().indexOf('.') === -1
}

export function trimStringsAndRemoveEmpty (obj) {
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'string') {
      const trimmed = obj[key].trim()
      if (trimmed === '') {
        obj[key] = null
      } else {
        obj[key] = trimmed
      }
    } else if (Array.isArray(obj[key])) {
      obj[key] = obj[key].map(item => {
        if (typeof item === 'string') {
          return item.trim()
        } else if (typeof item === 'object' && item !== null) {
          return trimStringsAndRemoveEmpty(item)
        }
        return item
      }).filter(item => item !== '') // Filter out empty strings
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      obj[key] = trimStringsAndRemoveEmpty(obj[key])
    }
  })
  return obj
}

export function formatCurrency (inputString) {
  if (inputString == null) {
    return null
  }
  const number = parseFloat(inputString)
  return `$${number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
}

export function formatDate (inputString, format = 'MMMM Do YYYY') {
  if (inputString == null) {
    return null
  }
  return moment(inputString).format(format)
}

export function formatDateRange (start, end) {
  moment.tz.setDefault('America/Chicago')
  const startMonth = moment(start).format('MMMM')
  const endDate = formatDate(end).replace(startMonth, '')
  const endYear = moment(end).year()
  const startDate = formatDate(start).replace(endYear, '')
  return `${startDate} - ${endDate}`
}

export function formatDateTime (inputString) {
  if (inputString == null) {
    return null
  }
  return moment(inputString).format('MM/DD/YYYY h:mm A')
}

export function formatTime (inputString) {
  if (inputString == null) {
    return null
  }
  return moment(inputString, 'HH:mm:ss').format('h:mm A')
}

export function formatTimeRange (start, end, compact = false) {
  moment.tz.setDefault('America/Chicago')
  const timeFormatIn = 'HH:mm:ss'
  const timeFormatOut = 'h:mm A'
  const endTime = moment(end, timeFormatIn).format(timeFormatOut).replace(':00', '')
  const endAmPm = moment(end, timeFormatIn).format(' A')
  const startTime = moment(start, timeFormatIn).format(timeFormatOut).replace(':00', '').replace(endAmPm, '')
  if (compact) {
    return `${startTime}-${endTime}`
      .replace(' AM', 'A').replace(' PM', 'P')
      .toLowerCase()
  } else {
    return `${startTime} - ${endTime}`
  }
}

export function fullName (person) {
  const results = []
  if (person.firstName) results.push(person.firstName)
  if (person.middleName) results.push(person.middleName)
  if (person.lastName) results.push(person.lastName)
  if (person.preferredName) results.push(`(${person.preferredName})`)
  return results.join(' ')
}

export function getBreadcrumbName (value) {
  return pluralizer(titleCase(value))
    .replaceAll('-', ' ')
    .replaceAll('Apis', 'APIs')
}

export function getOrdinal (number) {
  switch (number) {
    case undefined:
      return ''
    case 1:
      return '1st'
    case 2:
      return '2nd'
    case 3:
      return '3rd'
    default:
      return `${number}th`
  }
}

export function getShortText (text, length = 240) {
  if (text == null) return text
  let results = ''
  const words = text.split(' ')
  while (results.length < length && words.length > 0) {
    results += words.splice(0, 1) + ' '
  }
  if (results.length < text.length) results += ' ...'
  return results
}

export function isNullOrWhitespace (value) {
  return !value || !value.trim()
}

export function numberWithCommas (x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
  reader.readAsDataURL(file)
})

export function titleCase (str) {
  return str
    .split('_')
    .filter(x => x.length > 0)
    .map((x) => (x.charAt(0).toUpperCase() + x.slice(1)))
    .join(' ')
}

export function toTitleCase (str) {
  return str
    .replaceAll('_', ' ')
    .replace(/\b\w/g, char => char.toUpperCase())
    .replace(/([A-Z])/g, ' $1')
    .replaceAll(' And ', ' and ')
    .trim()
}
