import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import moment from 'moment'
import { useGet } from '../hooks/useGet'
import { Avatar } from '@rmwc/avatar'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadCards } from '../common/RadCards'
import { RadHeader } from '../common/RadHeader'
import { RadLink } from '../common/RadLink'
import { RadPagination } from '../common/RadPagination'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTextFilter } from '../common/RadTextFilter'
import { formatDate, formatTime, fullName, getShortText } from '../common/utilities'

export function CriticalInteractionList () {
  moment.tz.setDefault('America/Chicago')
  const pageLength = 20
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentPageIndex, setCurrentPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const [filteringText, setFilteringText] = useState(searchParams.get('search') ?? '')
  const [searchText, setSearchText] = useState(searchParams.get('search') ?? '')
  const { data: criticalInteractions, count } = useGet(
    '/api/critical-interaction' +
    `?search=${searchText}` +
    `&limit=${pageLength}` +
    `&offset=${(currentPageIndex - 1) * pageLength}`
  )

  function escapeRegExp (string) {
    if (string == null) return null
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
  }

  const highlightMatch = (text) => {
    if (text == null || searchText == null) return null
    const escapedSearchText = escapeRegExp(searchText)
    const parts = text.split(new RegExp(`(${escapedSearchText})`, 'gi'))
    return (
      <span>{parts.map((part, i) =>
        part.toLowerCase() === searchText.toLowerCase()
          ? <span key={i} className='highlight'>{part}</span>
          : part
      )}
      </span>
    )
  }

  if (
    criticalInteractions != null
  ) {
    return (
      <RadAppLayout
        contentHeader={
          <RadHeader
            variant='h1'
            counter={'(' + Number(count).toLocaleString() + ')'}
          >
            Critical Interactions
          </RadHeader>
        }
        content={
          <RadCards
            ariaLabels={{
              itemSelectionLabel: (e, t) => `select ${t.name}`,
              selectionGroupLabel: 'Item selection'
            }}
            cardDefinition={{
              header: item => (
                <RadLink fontSize='heading-m' href={`/person/${item.person.id}`}>
                  <RadSpaceBetween size='xs'>
                    <Avatar
                      src={item.photoUrl}
                      size='large'
                      name={`${item.person.firstName} ${item.person.lastName}`}
                      referrerPolicy='no-referrer'
                    />
                    {highlightMatch(fullName(item.person))}
                  </RadSpaceBetween>
                </RadLink>
              ),
              sections: [
                {
                  id: 'info',
                  content: item =>
                    <RadSpaceBetween size='xs'>
                      <div>{formatDate(item.date)} {formatTime(item.time)}</div>
                      <div>{highlightMatch(getShortText(item.description))}</div>
                    </RadSpaceBetween>
                }
              ]
            }}
            cardsPerRow={[
              { cards: 1 },
              { minWidth: 640, cards: 2 },
              { minWidth: 960, cards: 3 },
              { minWidth: 1280, cards: 4 }
            ]}
            items={criticalInteractions}
            filter={
              <RadTextFilter
                filteringText={filteringText}
                filteringPlaceholder='Search'
                filteringAriaLabel='Filter critical interactions by person name, description'
                onChange={({ detail }) => setFilteringText(detail.filteringText)}
                onDelayedChange={({ detail }) => {
                  setSearchText(detail.filteringText ?? '')
                  if (detail.filteringText) {
                    searchParams.set('search', detail.filteringText)
                  } else {
                    searchParams.delete('search')
                  }
                  searchParams.delete('page')
                  setCurrentPageIndex(1)
                  setSearchParams(searchParams)
                }}
              />
            }
            pagination={
              <RadPagination
                currentPageIndex={currentPageIndex}
                pagesCount={Math.ceil(count / pageLength)}
                onChange={({ detail }) => {
                  console.log(detail)
                  searchParams.set('page', detail.currentPageIndex)
                  setSearchParams(searchParams)
                  setCurrentPageIndex(detail.currentPageIndex)
                }}
                ariaLabels={{
                  nextPageLabel: 'Next page',
                  previousPageLabel: 'Previous page',
                  pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                }}
              />
            }
            variant='full-page'
            empty={
              <RadBox textAlign='center' color='inherit'>
                No matches found
              </RadBox>
            }
          />
        }
      />
    )
  }
}
