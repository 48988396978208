import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadHeader } from '../common/RadHeader'
import { RadLink } from '../common/RadLink'
import { RadPagination } from '../common/RadPagination'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTable } from '../common/RadTable'
import { RadTextFilter } from '../common/RadTextFilter'

export function SchoolList () {
  const pageLength = 20
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentPageIndex, setCurrentPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const [filteringText, setFilteringText] = useState(searchParams.get('search') ?? '')
  const [searchText, setSearchText] = useState(searchParams.get('search') ?? '')
  const { data: schools, count } = useGet(`/api/school?search=${filteringText}&limit=${pageLength}&offset=${(currentPageIndex - 1) * pageLength}`)

  function escapeRegExp (string) {
    if (string == null) return null
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
  }

  const highlightMatch = (text) => {
    if (searchText == null || searchText === '') return text
    const escapedSearchText = escapeRegExp(searchText)
    const parts = text.split(new RegExp(`(${escapedSearchText})`, 'gi'))
    return (
      <span>{parts.map((part, i) =>
        part.toLowerCase() === searchText.toLowerCase()
          ? <span key={i} className='highlight'>{part}</span>
          : part
      )}
      </span>
    )
  }

  if (
    schools != null
  ) {
    return (
      <RadAppLayout
        contentHeader={
          <RadHeader
            variant='h1'
            counter={'(' + count + ')'}
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('/school/create')}>Create</RadButton>
              </RadSpaceBetween>
            }
          >
            Schools
          </RadHeader>
        }
        content={
          <RadTable
            columnDefinitions={[
              {
                id: 'name',
                header: 'Name',
                cell: item => <RadLink href={`/school/${item.id}`}>{highlightMatch(item.name)}</RadLink>
              },
              {
                id: 'county',
                header: 'County',
                cell: item => <div>{highlightMatch(item.county ?? '-')}</div>
              },
              {
                id: 'actions',
                cell: item => <RadButton disabled={item.status === 'accepted'} variant='inline-icon' iconName='edit' href={`/school/${item.id}/edit?redirectURL=${window.location.pathname}${encodeURIComponent(window.location.search)}`} />

              }
            ]}
            items={schools}
            variant='full-page'
            filter={
              <RadTextFilter
                filteringPlaceholder='Search'
                filteringAriaLabel='Search participants'
                filteringText={filteringText}
                onChange={({ detail }) => setFilteringText(detail.filteringText)}
                onDelayedChange={({ detail }) => {
                  setSearchText(detail.filteringText)
                  setCurrentPageIndex(1)
                  searchParams.delete('page')
                  if (detail.filteringText) {
                    searchParams.set('search', detail.filteringText)
                  } else {
                    searchParams.delete('search')
                  }
                  setSearchParams(searchParams)
                }}
              />
            }
            pagination={
              <RadPagination
                currentPageIndex={currentPageIndex}
                pagesCount={Math.ceil(count / pageLength)}
                onChange={({ detail }) => {
                  searchParams.set('page', detail.currentPageIndex)
                  setSearchParams(searchParams)
                  setCurrentPageIndex(detail.currentPageIndex)
                }}
                ariaLabels={{
                  nextPageLabel: 'Next page',
                  previousPageLabel: 'Previous page',
                  pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                }}
              />
            }
            empty={
              <RadBox textAlign='center' color='inherit'>
                No matches found.
              </RadBox>
            }
          />
        }
      />
    )
  }
}
