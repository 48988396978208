import React from 'react'
import Cards from '@cloudscape-design/components/cards'
import { RadBox } from './RadBox'

// https://cloudscape.design/components/cards
export function RadCards ({ empty, emptyText, ...rest }) {
  const defaultEmpty = (
    <RadBox textAlign='center' color='inherit' padding={{ top: 'xxl' }}>
      {emptyText ?? 'No matches found'}
    </RadBox>
  )

  return (
    <Cards
      empty={empty ?? defaultEmpty}
      {...rest}
    />
  )
}
