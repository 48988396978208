import React from 'react'
import moment from 'moment-timezone'
import { Avatar } from '@rmwc/avatar'
import { RadBox } from '../common/RadBox'
import { RadCards } from '../common/RadCards'
import { RadHeader } from '../common/RadHeader'
import { RadLink } from '../common/RadLink'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { AttendancePercentage } from '../shared/AttendancePercentage'
import { formatDate, fullName } from '../common/utilities'
import { CourseSchedule } from '../shared/CourseSchedule'

export function CourseEnrollmentCards ({ courseEnrollments }) {
  moment.tz.setDefault('America/Chicago')

  return (
    <RadSpaceBetween size='s'>
      <RadHeader
        variant='h2'
        counter={'(' + courseEnrollments.length + ')'}
      >
        Enrollments
      </RadHeader>
      {courseEnrollments.length === 0 && <RadBox color='text-body-secondary'>No enrollments added</RadBox>}
      {courseEnrollments.length > 0 &&
        <RadCards
          ariaLabels={{
            itemSelectionLabel: (e, t) => `select ${t.name}`,
            selectionGroupLabel: 'Item selection'
          }}
          cardDefinition={{
            header: item => {
              if (item.course != null) {
                return (
                  <RadLink fontSize='heading-m' href={`/course/${item.course.id}`}>
                    Course # {item.course.id}
                  </RadLink>
                )
              }
              if (item.person != null) {
                return (
                  <RadLink fontSize='heading-m' href={`/course/${item.courseId}/enrollment/${item.id}`}>
                    <RadSpaceBetween size='xs'>
                      <Avatar
                        src={item.person.photoUrl}
                        size='large'
                        name={`${item.person.firstName} ${item.person.lastName}`}
                        referrerPolicy='no-referrer'
                      />
                      <div>{fullName(item.person)}</div>
                    </RadSpaceBetween>
                  </RadLink>
                )
              }
              return <span>Course or Person not provided</span>
            },
            sections: [
              {
                id: 'info',
                content: item =>
                  <RadSpaceBetween size='xxs'>
                    {item.course != null &&
                      <>
                        {item.course.cohort != null &&
                          <RadSpaceBetween direction='horizontal' size='s'>
                            <RadBox variant='awsui-key-label'>cohort</RadBox>
                            <RadLink variant='primary' href={`/cohort/${item.course.cohort.id}`}>
                              {item.course.cohort.name}
                            </RadLink>
                          </RadSpaceBetween>}
                        {item.course.program != null &&
                          <RadSpaceBetween direction='horizontal' size='s'>
                            <RadBox variant='awsui-key-label'>Program</RadBox>
                            <RadLink variant='primary' href={`/program/${item.course.program.id}`}>
                              {item.course.program.name}
                            </RadLink>
                          </RadSpaceBetween>}
                        <RadSpaceBetween direction='horizontal' size='s'>
                          <RadBox variant='awsui-key-label'>Instructor(s)</RadBox>
                          {item.course.instructors.map(x =>
                            <RadLink key={'instructor-' + x.id} variant='primary' href={`/instructor/${x.instructor.id}`}>
                              {fullName(x.instructor)}
                            </RadLink>
                          )}
                        </RadSpaceBetween>
                        <RadSpaceBetween direction='horizontal' size='s'>
                          <RadBox variant='awsui-key-label'>Location</RadBox>
                          <div>{item.course.campus.name} {item.room != null ? ` - Room ${item.room}` : ''}</div>
                        </RadSpaceBetween>
                        <div>
                          <RadBox variant='awsui-key-label' padding={{ bottom: 'xxs' }}>Schedule</RadBox>
                          <div><CourseSchedule course={item.course} /></div>
                        </div>
                      </>}
                    <AttendancePercentage item={item} />
                    <RadBox variant='awsui-key-label'>
                      Certificates
                      {item.certificates.length === 0 && <RadBox display='inline' padding={{ left: 's' }}>-</RadBox>}
                    </RadBox>
                    <div>
                      {item.certificates.map(x =>
                        <div key={'certificate-' + x.id}>{x.certificate.name} | {formatDate(x.date)}</div>
                      )}
                    </div>
                    {item.exitReason != null &&
                      <RadSpaceBetween direction='horizontal' size='s'>
                        <RadBox variant='awsui-key-label'>Exit Reason</RadBox>
                        <div>
                          <div>{item.exitReason.name}</div>
                          <div>{formatDate(item.endDate)}</div>
                        </div>
                      </RadSpaceBetween>}
                  </RadSpaceBetween>
              }
            ]
          }}
          cardsPerRow={[
            { cards: 1 },
            { minWidth: 640, cards: 2 },
            { minWidth: 960, cards: 3 },
            { minWidth: 1280, cards: 4 }
          ]}
          items={courseEnrollments}
          variant='full-page'
        />}
    </RadSpaceBetween>
  )
}
