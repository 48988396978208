import React from 'react'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadContainer } from '../common/RadContainer'
import { RadHeader } from '../common/RadHeader'
import { RadLink } from '../common/RadLink'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { formatDate, fullName } from '../common/utilities'
import { AttendancePieChart } from '../charts/AttendancePieChart'
import { CourseCards } from '../cards/CourseCards'
import { EthnicitiesPieChart } from '../charts/EthnicitiesPieChart'
import { GenderPieChart } from '../charts/GenderPieChart'
import { RacePieChart } from '../charts/RacePieChart'
import { GradesPieChart } from '../charts/GradesPieChart'
import { FirstGenerationCollegeStudentPieChart } from '../charts/FirstGenerationCollegeStudentPieChart'
import { UsCitizenOrPermanentResidentPieChart } from '../charts/UsCitizenOrPermanentResidentPieChart'
import { SessionAttendanceBarChart } from '../charts/SessionAttendanceBarChart'
import { CompletionPieChart } from '../charts/CompletionPieChart'

export function Dashboard () {
  const [selectedYear, setSelectedYear] = React.useState(new Date().getFullYear())
  const { data: notifications } = useGet('/api/dashboard/notifications')
  const { data: settings } = useGet('/api/settings')
  const { data: year } = useGet(`/api/dashboard/year/${selectedYear}`)
  const { data: years } = useGet('/api/dashboard/years')

  if (
    notifications != null &&
    settings != null &&
    year != null &&
    years != null
  ) {
    return (
      <RadAppLayout
        noBreadcrumbs
        contentHeader={
          <RadHeader
            variant='h1'
          >
            Dashboard
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            {/* <Notifications notifications={notifications} settings={settings} /> */}
            <RadContainer
              header={
                <div style={{ display: 'flex' }}>
                  {years[0] !== selectedYear &&
                    <button className='transparent' onClick={() => setSelectedYear(selectedYear - 1)}>
                      <span className='material-symbols-outlined'>arrow_back_ios</span>
                    </button>}
                  <h1 style={{ flexGrow: 1, textAlign: 'center' }}>
                    {selectedYear}
                  </h1>
                  {years[years.length - 1] !== selectedYear &&
                    <button className='transparent' onClick={() => setSelectedYear(selectedYear + 1)}>
                      <span className='material-symbols-outlined'>arrow_forward_ios</span>
                    </button>}
                </div>
              }
            >
              <DemographicsAndAttendance entity={year} />
            </RadContainer>
            <CourseCards courses={year.courses} />
          </RadSpaceBetween>
        }
      />
    )
  }
}

function Notifications ({ notifications, settings }) {
  return (
    <RadContainer
      header={
        <RadHeader variant='h2'>
          Notifications
        </RadHeader>
    }
    >
      <RadColumnLayout columns={2} borders='vertical'>
        <div>
          <RadBox variant='h3'>
            Critical Interactions
          </RadBox>
          {notifications.criticalInteractions.length === 0 &&
            <RadBox color='text-body-secondary'>No critical interactions</RadBox>}
          {notifications.criticalInteractions.map(x =>
            <RadBox key={'ci-' + x.id} variant='p'>
              {formatDate(x.date)} |&nbsp;
              <RadLink href={`/person/${x.person.id}`}>
                {fullName(x.person)}
              </RadLink>
            </RadBox>)}
        </div>
        <div>
          <RadBox variant='h3'>
            Consecutive Absences (over {settings.consecutiveAbsenceThreshold} days)
          </RadBox>
          {notifications.consecutiveAbsences.length === 0 &&
            <RadBox color='text-body-secondary'>No consecutive absences</RadBox>}
          {notifications.consecutiveAbsences.map(x =>
            <RadBox key={'ci-' + x.id} variant='p'>
              {formatDate(x.date)} |&nbsp;
              <RadLink href={`/person/${x.person.id}`}>
                {fullName(x.person)}
              </RadLink>
            </RadBox>)}
        </div>
      </RadColumnLayout>
    </RadContainer>
  )
}

function DemographicsAndAttendance ({ entity }) {
  return (
    <RadSpaceBetween size='l'>
      <RadColumnLayout columns={4}>
        <GenderPieChart genders={entity.demographics.genders} />
        <RacePieChart races={entity.demographics.races} />
        <EthnicitiesPieChart ethnicities={entity.demographics.ethnicities} />
        <FirstGenerationCollegeStudentPieChart
          firstGenerationCollegePeople={entity.demographics.firstGenerationCollegePeople}
        />
        <UsCitizenOrPermanentResidentPieChart
          usCitizenOrPermanentResidents={entity.demographics.usCitizenOrPermanentResidents}
        />
        <GradesPieChart grades={entity.demographics.grades} />
        <CompletionPieChart completions={entity.demographics.completions} />
        <AttendancePieChart sessions={entity.sessions} />
      </RadColumnLayout>
      {/* <RadDivider /> */}
      <SessionAttendanceBarChart courses={entity.courses} sessions={entity.sessions} />
    </RadSpaceBetween>
  )
}
