import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadContainer } from '../common/RadContainer'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function SettingsDetail () {
  const navigate = useNavigate()
  const { data: settings } = useGet('/api/settings', true)

  if (settings != null) {
    return (
      <RadAppLayout
        name='settings'
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('edit')}>Edit</RadButton>
              </RadSpaceBetween>
            }
          >
            Settings
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadContainer>
              <RadColumnLayout columns={3} borders='vertical'>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Attendance Threshold
                  </RadBox>
                  <div>{settings.attendanceThreshold}%</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Consecutive Absence Threshold
                  </RadBox>
                  <div>{settings.consecutiveAbsenceThreshold}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Critical Interaction Notification Days
                  </RadBox>
                  <div>{settings.criticalInteractionNotificationDays}</div>
                </div>
              </RadColumnLayout>
            </RadContainer>
          </RadSpaceBetween>
        }
      />
    )
  }
}
