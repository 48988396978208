import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadContainer } from '../common/RadContainer'
import { RadHeader } from '../common/RadHeader'
import { RadLink } from '../common/RadLink'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { PhotoUrlHeader } from '../shared/PhotoUrlHeader'
import { fullName } from '../common/utilities'

export function InstructorDetail () {
  const navigate = useNavigate()
  const { instructorId } = useParams()
  const { data: instructor } = useGet(`/api/instructor/${instructorId}`, true)
  const confirmDelete = useConfirm(
    'Delete instructor?',
    'Delete instructor permanently? This action cannot be undone.', 'Delete',
    () => { remove() }
  )
  const remove = useDelete(
    `/api/instructor/${instructorId}`,
    () => { navigate('/instructor') }
  )

  if (instructor != null) {
    return (
      <RadAppLayout
        name={fullName(instructor)}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('edit')}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={instructor.isProtected}>Delete</RadButton>
              </RadSpaceBetween>
            }
          >
            {fullName(instructor)}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <PhotoUrlHeader photoUrl={instructor.photoUrl} />
            <Details instructor={instructor} />
          </RadSpaceBetween>
        }
      />
    )
  }
}

export function Details ({ instructor }) {
  return (
    <RadContainer
      header={
        <RadHeader variant='h2'>
          Details
        </RadHeader>
              }
    >
      <RadColumnLayout columns={4} borders='vertical'>
        <div>
          <RadBox variant='awsui-key-label'>
            Full Name
          </RadBox>
          <div>{fullName(instructor)}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Email
          </RadBox>
          <div>{instructor.email ? <RadLink href={`mailto:${instructor.email}`}>{instructor.email}</RadLink> : '-'}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Phone
          </RadBox>
          <div>{instructor.phone ?? '-'}</div>
        </div>
      </RadColumnLayout>
    </RadContainer>
  )
}
