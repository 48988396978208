import React from 'react'
import { useGet } from '../hooks/useGet'
import { RadBox } from '../common/RadBox'
import { RadBarChart } from '../common/RadBarChart'
import { formatDate } from '../common/utilities'

export
function SessionAttendanceBarChart ({ sessions }) {
  const { data: settings } = useGet('/api/settings')

  const courseIds = sessions.reduce((acc, x) => {
    const existing = acc.find(y => y === x.courseId)
    if (!existing) { acc.push(x.courseId) }
    return acc
  }, [])

  if (settings != null) {
    const dates = sessions.reduce((acc, x) => {
      const existing = acc.find(y => y === x.date)
      if (!existing) { acc.push(x.date) }
      return acc
    }, [])
      .sort()

    const series = courseIds.map(x => ({
      title: x,
      type: 'bar',
      data: dates.map(date => {
        const courseCount = sessions
          .filter(y => y.date === date)
          .length
        const attendance = sessions
          .filter(y => y.courseId === x && y.date === date)
          .reduce((acc, y) => {
            acc.total += y.total
            acc.present += y.present
            return acc
          }, { total: 0, present: 0 })
        return ({
          id: date,
          x: date,
          y: attendance.total > 0 ? attendance.present / attendance.total * 100 / courseCount : -1
        })
      })
    }))

    series.push({
      title: 'Attendance Threshold',
      type: 'threshold',
      color: 'red',
      y: settings.attendanceThreshold,
      valueFormatter: e =>
        e.toLocaleString('en-US', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        }) + ' %'
    })

    return (
      <RadBarChart
        empty={
          <RadBox textAlign='center' color='inherit'>
            <b>Attendance</b>
            <RadBox variant='p' color='inherit'>
              There is no data available
            </RadBox>
          </RadBox>
        }
        hideFilter
        hideLegend
        series={series}
        stackedBars
        // xDomain={cohort.sessions.map(x => formatDate(x.date))}
        yTitle='Attendance (%)'
        yDomain={[0, 100]}
        detailPopoverSeriesContent={({ series, x, y }) => {
          const courseCount = sessions
            .filter(z => z.date === x)
            .length
          if (y === -1) {
            return ({
              key: <div>Course # {series.title}</div>,
              value: '-'
            })
          }
          return ({
            key: <div>Course # {series.title}</div>,
            value: `${(y * courseCount).toFixed(0)} %`
          })
        }}
        detailPopoverFooter={xValue => {
          const courseIds = sessions
            .filter(x => x.date === xValue)
            .reduce((acc, x) => {
              if (!acc.find(y => y === x.courseId)) { acc.push(x.courseId) }
              return acc
            }, [])
          const attendance = sessions
            .filter(x => x.date === xValue)
            .reduce((acc, x) => {
              acc.total += x.total
              acc.present += x.present
              return acc
            }, { total: 0, present: 0 })

          if (courseIds.length === 1) {
            return null
          }

          return (
            <>
              <hr />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <span>Total</span>
                <span>{(attendance.present / attendance.total * 100).toFixed(0)} %</span>
              </div>
            </>
          )
        }}
        xTickFormatter={d => formatDate(d)}
      />
    )
  }
}
