import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppContext } from '../App'
import { RadBox } from './RadBox'
import { RadButton } from './RadButton'

// This utilizes other RAD components
// See each for links to their documentation
export function RadNotFound ({ home }) {
  const { setNotFound } = useContext(AppContext)
  const navigate = useNavigate()

  function goHome () {
    setNotFound(false)
    navigate(home)
  }

  return (
    <RadBox textAlign='center' padding={{ top: 'xxl' }}>
      <RadBox
        padding={{ top: 'xxl', bottom: 'xxl' }}
        fontSize='display-l'
        color='text-body-secondary'
        fontWeight='bold'
      >
        Not found.
      </RadBox>
      <RadBox
        padding={{ bottom: 'xl' }}
        color='text-body-secondary'
      >
        Click the button below to go back to the dashboard.
      </RadBox>
      <RadButton onClick={goHome}>Dashboard</RadButton>
    </RadBox>
  )
}
