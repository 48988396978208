import React from 'react'
import { RadBox } from '../common/RadBox'
import { RadPieChart } from '../common/RadPieChart'

export function GenderPieChart ({ genders }) {
  const total = genders.reduce((acc, x) => acc + x.count, 0)

  return (
    <RadPieChart
      ariaDescription='Donut chart showing genders'
      ariaLabel='Donut chart'
      data={genders.map(x => ({
        title: x.gender,
        value: x.count,
        percentage: (x.count / total * 100).toFixed(0) + '%'
      }))}
      empty={
        <RadBox textAlign='center' color='inherit'>
          <b>Genders</b>
          <RadBox variant='p' color='inherit'>
            There is no data available
          </RadBox>
        </RadBox>
    }
      innerMetricDescription='Genders'
      innerMetricValue={genders.length}
      size='medium'
      variant='donut'
      hideFilter
      hideLegend
      hideTitles
      hideDescriptions
    />
  )
}
