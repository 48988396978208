import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadButton } from '../common/RadButton'
import { RadCards } from '../common/RadCards'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function StudentRatingList () {
  const navigate = useNavigate()
  const { data: studentRatings, count } = useGet('/api/student-rating')
  const { data: studentRatingCategories } = useGet('/api/option/student_rating_category')

  if (
    studentRatings != null &&
    studentRatingCategories != null
  ) {
    return (
      <RadAppLayout
        contentHeader={
          <RadHeader
            variant='h1'
            counter={'(' + count + ')'}
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('edit')}>Edit</RadButton>
              </RadSpaceBetween>
            }
          >
            Student Ratings
          </RadHeader>
        }
        content={
          <RadCards
            ariaLabels={{
              itemSelectionLabel: (e, t) => `select ${t.name}`,
              selectionGroupLabel: 'Item selection'
            }}
            cardDefinition={{
              header: item => item.label + (item.label === 'Attendance' ? ' - not editable' : ''),
              sections: [
                {
                  id: 'info',
                  content: item =>
                    <RadSpaceBetween size='xxs'>
                      {studentRatings
                        .filter(x => x.studentRatingCategoryId.toString() === item.value)
                        .map(x =>
                          <div key={'student-rating' + x.id}>
                            {x.name} ({x.description})
                          </div>
                        )}
                    </RadSpaceBetween>
                }
              ]
            }}
            cardsPerRow={[
              { cards: 1 },
              { minWidth: 640, cards: 2 },
              { minWidth: 960, cards: 3 },
              { minWidth: 1280, cards: 4 }
            ]}
            items={studentRatingCategories}
            variant='full-page'
            empty=''
          />
        }
      />
    )
  }
}
