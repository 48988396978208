import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadButton } from '../common/RadButton'
import { RadCards } from '../common/RadCards'
import { RadLink } from '../common/RadLink'

export function RoleList ({ selected }) {
  const navigate = useNavigate()
  const { data: roles } = useGet('/api/role')

  if (roles) {
    return (
      <RadAppLayout
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('/role/create')}>Create</RadButton>
              </RadSpaceBetween>
            }
            counter={'(' + roles.length + ')'}
          >Roles
          </RadHeader>
        }
        content={
          <RadCards
            ariaLabels={{
              itemSelectionLabel: (e, t) => `select ${t.name}`,
              selectionGroupLabel: 'Item selection'
            }}
            cardDefinition={{
              header: item => (
                <RadLink fontSize='heading-m' href={`/role/${item.id}`}>{item.name}</RadLink>
              ),
              sections: [
                {
                  id: 'users',
                  header: 'Users',
                  content: item => item.users.map((x) => { return (<div key={x.id}><RadLink href={`/user/${x.id}`}>{x.name}</RadLink> ({x.email})</div>) })
                },
                {
                  id: 'permissions',
                  header: 'Permissions',
                  content: item => {
                    if (item.name === 'Admin') {
                      return (<div>All</div>)
                    }
                    const entities = item.permissions.reduce((acc, curr) => {
                      const split = curr.name.split(' ')
                      const permission = split[split.length - 1]
                      const entity = curr.name.replace(' ' + permission, '')
                      if (!acc[entity]) { acc[entity] = '' } else { acc[entity] += ', ' }
                      acc[entity] += permission
                      return acc
                    }, {})
                    return Object.keys(entities).map((x) => { return (<div key={x}>{`${x} | ${entities[x]}`}</div>) })
                  }
                }
              ]
            }}
            cardsPerRow={[
              { cards: 1 },
              { minWidth: 640, cards: 2 },
              { minWidth: 960, cards: 3 }
            ]}
            items={roles ?? []}
            variant='full-page'
          />
        }
      />
    )
  }
}
