import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import moment from 'moment-timezone'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { Avatar } from '@rmwc/avatar'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadContainer } from '../common/RadContainer'
import { RadCards } from '../common/RadCards'
import { RadHeader } from '../common/RadHeader'
import { RadLink } from '../common/RadLink'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { formatDate, formatTimeRange, fullName } from '../common/utilities'
import { CourseSchedule } from '../shared/CourseSchedule'

export function StudentReportDetail () {
  moment.tz.setDefault('America/Chicago')
  const navigate = useNavigate()
  const { studentReportId } = useParams()
  const { data: studentReport } = useGet(`/api/student-report/${studentReportId}`, true)
  const confirmDelete = useConfirm('Delete enrollment?', 'Delete enrollment permanently? This action cannot be undone.', 'Delete', () => { remove() })
  const remove = useDelete(`/api/student-report/${studentReportId}`, () => { navigate('/student-report') })

  if (studentReport != null) {
    return (
      <RadAppLayout
        name={'Student Report # ' + studentReport.id}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('edit')}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={studentReport.isProtected}>Delete</RadButton>
              </RadSpaceBetween>
            }
          >
            {'Student Report # ' + studentReport.id}
          </RadHeader>
        }
        content={

          <RadSpaceBetween size='l'>
            <Details studentReport={studentReport} />
            <Course studentReport={studentReport} />
            <People studentReport={studentReport} />
          </RadSpaceBetween>
        }
      />
    )
  }
}

function Details ({ studentReport }) {
  return (
    <RadContainer
      header={
        <RadHeader variant='h2'>
          Details
        </RadHeader>
      }
    >
      <RadSpaceBetween size='l'>
        <RadColumnLayout columns={2} borders='vertical'>
          <div>
            <RadBox variant='awsui-key-label'>Date</RadBox>
            {formatDate(studentReport.date)}
          </div>
          <div>
            <RadBox variant='awsui-key-label'>Created By</RadBox>
            {studentReport.creator.name}
          </div>
        </RadColumnLayout>
      </RadSpaceBetween>
    </RadContainer>
  )
}

function Course ({ studentReport }) {
  return (
    <RadContainer
      header={
        <RadHeader variant='h2'>
          Course
        </RadHeader>
      }
    >
      <RadSpaceBetween size='l'>
        <RadColumnLayout columns={4} borders='vertical'>
          <div>
            <RadBox variant='awsui-key-label' padding={{ bottom: 's' }}>Cohort</RadBox>
            <RadLink variant='primary' href={`/cohort/${studentReport.course.cohort.id}`}>{studentReport.course.cohort.name ?? '-'}</RadLink>
          </div>
          <div>
            <RadBox variant='awsui-key-label' padding={{ bottom: 'xxs' }}>Program</RadBox>
            <RadSpaceBetween direction='horizontal'>
              <Avatar
                src={studentReport.course.program.photoUrl ?? '/program_icon.webp'}
                size='large'
                referrerPolicy='no-referrer'
              />
              <RadBox padding={{ left: 's', top: 'xs' }}>
                <RadLink variant='primary' href={`/program/${studentReport.course.program.id}`}>{studentReport.course.program.name}</RadLink>
              </RadBox>
            </RadSpaceBetween>
          </div>
          <div>
            <RadBox variant='awsui-key-label' padding={{ bottom: 'xxs' }}>Instructor(s)</RadBox>
            <RadSpaceBetween size='xxs'>
              {studentReport.course.instructors.map(x =>
                <RadSpaceBetween key={'instructor-' + x.id} direction='horizontal'>
                  <Avatar
                    src={x.instructor.photoUrl}
                    size='large'
                    name={fullName(x.instructor)}
                    referrerPolicy='no-referrer'
                  />
                  <RadBox padding={{ left: 's', top: 'xs' }}>
                    <RadLink variant='primary' href={`/instructor/${x.instructor.id}`}>{fullName(x.instructor)}</RadLink>
                  </RadBox>
                </RadSpaceBetween>
              )}
            </RadSpaceBetween>
          </div>
          <div>
            <RadBox variant='awsui-key-label' padding={{ bottom: 's' }}>Schedule</RadBox>
            <CourseSchedule course={studentReport.course} />
          </div>
        </RadColumnLayout>
      </RadSpaceBetween>
    </RadContainer>
  )
}

function People ({ studentReport }) {
  const people = studentReport.people
  return (
    <RadSpaceBetween size='s'>
      <RadHeader
        variant='h2'
        counter={'(' + people.length + ')'}
      >
        Students
      </RadHeader>
      {people.length > 0 &&
        <RadCards
          ariaLabels={{
            itemSelectionLabel: (e, t) => `select ${t.name}`,
            selectionGroupLabel: 'Item selection'
          }}
          cardDefinition={{
            header: item =>
              <RadLink fontSize='heading-m' href={`/person/${item.person.id}`}>
                {fullName(item.person)}
              </RadLink>,
            sections: [
              {
                id: 'info',
                content: item =>
                  <RadSpaceBetween size='xxs'>
                    <RadSpaceBetween direction='horizontal' size='s'>
                      <RadBox variant='awsui-key-label'>Attendance</RadBox>
                      <div>{item.attendanceRating.name}</div>
                    </RadSpaceBetween>
                    <RadSpaceBetween direction='horizontal' size='s'>
                      <RadBox variant='awsui-key-label'>Behavior</RadBox>
                      <div>{item.behaviorRating.name}</div>
                    </RadSpaceBetween>
                    <RadSpaceBetween direction='horizontal' size='s'>
                      <RadBox variant='awsui-key-label'>performance</RadBox>
                      <div>{item.performanceRating.name}</div>
                    </RadSpaceBetween>
                    <RadSpaceBetween direction='horizontal' size='s'>
                      <RadBox variant='awsui-key-label'>Participation</RadBox>
                      <div>{item.participationRating.name}</div>
                    </RadSpaceBetween>
                    {item.comments == null &&
                      <RadSpaceBetween direction='horizontal' size='s'>
                        <RadBox padding={{ bottom: 'xxs' }} variant='awsui-key-label'>
                          Comments
                        </RadBox>
                        <div>-</div>
                      </RadSpaceBetween>}
                    {item.comments != null &&
                      <div>
                        <RadBox padding={{ bottom: 'xxs' }} variant='awsui-key-label'>
                          Comments
                        </RadBox>
                        <span>{item.comments}</span>
                      </div>}
                  </RadSpaceBetween>
              }
            ]
          }}
          cardsPerRow={[
            { cards: 1 },
            { minWidth: 640, cards: 2 },
            { minWidth: 960, cards: 3 },
            { minWidth: 1280, cards: 4 }
          ]}
          items={people}
          variant='full-page'
        />}
    </RadSpaceBetween>
  )
}
